<template>
  <div class="conversation-evaluate conversation">
    <div class="conversation-top">
      <div class="to-left">
        <div class="avater">
          <svg
            aria-hidden="true"
            class="icon"
            style="width: 100%; height: 100%"
          >
            <use href="#icon-headPortrait_outside_get"></use>
          </svg>
        </div>
        <div class="perInfo">
          <div>
            <span class="name">{{ contactName }}</span>
            <span>{{
              handleTime(evaluateInfo.lastmodifydate) +
              " " +
              $t("font-evaluate-module-v1-evaluation")
            }}</span>
          </div>
          <div v-show="evaluateInfo.source">
            <span>
              {{ $t("chatter.from") }}
              {{ evaluateInfo.evaluationemail || evaluateInfo.source }}</span
            >
          </div>
        </div>
      </div>
      <div class="point-box">
        <el-dropdown
          @command="handleCommand"
          @visible-change="active = !active"
        >
          <div class="el-dropdown-link point-icon">
            <svg
              aria-hidden="true"
              class="icon"
              style="width: 100%; height: 100%"
            >
              <use
                :href="!active ? '#icon-full_more_def' : '#icon-full_more_cli'"
              ></use>
            </svg>
          </div>
          <el-dropdown-menu slot="dropdown" class="point-li">
            <el-dropdown-item
              v-for="(item, index) in moreOperationData"
              :key="index"
              :command="item.type"
              @mouseover.native="enter(index)"
              @mouseout.native="outer(index)"
            >
              <span class="point-icon">
                <svg
                  aria-hidden="true"
                  class="icon"
                  style="width: 100%; height: 100%"
                >
                  <use :href="!item.isHov ? item.icon : item.hovicon"></use>
                </svg> </span
              ><span>{{ item.text }}</span>
            </el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
    </div>
    <div class="conversation-content warp">
      <div class="content-evaluate">
        <div class="evaluate-title">
          <span>{{ contactName }}</span>
          <!-- <span>来自于{{ evaluateInfo.name }}的评价响应</span> -->
          <span>{{
            $t("front-console-conversation-evaluate-desc", {
              evaluateInfoName: evaluateInfo.name,
            })
          }}</span>
        </div>
        <div class="evaluate-desc">
          <div class="avater">
            <svg
              v-show="
                evaluateInfo.aggregatescore === '10' ||
                evaluateInfo.evaluationtype === '好评'
              "
              aria-hidden="true"
              class="icon"
              style="width: 100%; height: 100%"
            >
              <use
                v-show="
                  evaluateInfo.emojitype !== '2' &&
                  evaluateInfo.emojitype !== '3'
                "
                href="#icon-assess_facce1_good"
              ></use>
              <use
                v-show="evaluateInfo.emojitype === '2'"
                href="#icon-assess_hand_good"
              ></use>
              <use
                v-show="evaluateInfo.emojitype === '3'"
                href="#icon-assess_facce2_good"
              ></use>
            </svg>
            <svg
              v-show="
                evaluateInfo.aggregatescore === '5' ||
                evaluateInfo.evaluationtype === '中评'
              "
              aria-hidden="true"
              class="icon"
              style="width: 100%; height: 100%"
            >
              <use
                v-show="
                  evaluateInfo.emojitype !== '2' &&
                  evaluateInfo.emojitype !== '3'
                "
                href="#icon-assess_face1_commonly"
              ></use>
              <use
                v-show="evaluateInfo.emojitype === '2'"
                href="#icon-assess_hand_commonly"
              ></use>
              <use
                v-show="evaluateInfo.emojitype === '3'"
                href="#icon-assess_facce2_commonly"
              ></use>
            </svg>
            <svg
              v-show="
                evaluateInfo.aggregatescore === '1' ||
                evaluateInfo.evaluationtype === '差评'
              "
              aria-hidden="true"
              class="icon"
              style="width: 100%; height: 100%"
            >
              <use
                v-show="
                  evaluateInfo.emojitype !== '2' &&
                  evaluateInfo.emojitype !== '3'
                "
                href="#icon-assess_face1_bad"
              ></use>
              <use
                v-show="evaluateInfo.emojitype === '2'"
                href="#icon-assess_hand_bad"
              ></use>
              <use
                v-show="evaluateInfo.emojitype === '3'"
                href="#icon-assess_facce2_bad"
              ></use>
            </svg>
          </div>
          <div class="text">
            <p v-if="evaluateInfo.evaluate">'{{ evaluateInfo.evaluate }}'</p>
            <p>'{{ evaluateInfo.evaluationtype }}'</p>
            <p>{{ evaluateInfo.evaluationreply }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as controlPanelApi from "../../api.js";
export default {
  props: {
    evaluateInfo: {
      type: Object,
    },
    EmailInfo: {
      type: Array,
      default: () => {
        return [];
      },
    },
    // Num: {
    //   type: Number,
    //   default: 0,
    // },
    contactName: {
      type: String,
      default: () => {
        return this.$i18n.t("front-console-conversation-title");
      },
    },
    contactEmail: {
      type: String,
      default: "",
    },
  },
  watch: {
    // evaluateInfo: {
    //   immediate: true,
    //   deep: true,
    //   handler: function (newVal, oldVal) {
    //     this.evaluateInfo.lastmodifydate = this.handleTime(
    //       this.evaluateInfo.lastmodifydate
    //     );
    //   },
    // },
  },
  data() {
    return {
      caseid: this.$route.query.caseId,
      moreOperationData: [
        {
          text: this.$i18n.t("front-console-conversation-btn2"),
          icon: "#icon-full_replyMail_hov",
          hovicon: "#icon-full_replyMail_cli",
          isHov: false,
          type: "replyMail",
          index: 0,
        },
        {
          text: this.$i18n.t("front-console-conversation-btn1"),
          icon: "#icon-full_callBack_hov",
          hovicon: "#icon-full_callBack_cli",
          isHov: false,
          type: "callBack",
          index: 1,
        },
      ], // 会话记录操作
      active: false, // 会话记录操作选中与否
    };
  },
  methods: {
    handleTime(target) {
      function addDateZero(num) {
        return num < 10 ? "0" + num : num;
      }
      function handleHour(time) {
        let t = new Date(time);
        let hour = t.getHours();
        let min = addDateZero(t.getMinutes());
        let sec = addDateZero(t.getSeconds());
        if (hour < 12) {
          hour = addDateZero(hour);
          return hour + ":" + min + ":" + sec + " " + "AM";
        } else {
          hour -= 12;
          hour = addDateZero(hour);
          return hour + ":" + min + ":" + sec + " " + "PM";
        }
      }
      let targetTime = new Date(target);
      let day = 24 * 60 * 60 * 1000;
      let diffTime = targetTime.getTime() - day;
      let newTarget = new Date(diffTime);
      let now = new Date();
      // 获取目标日期
      let tDate = addDateZero(targetTime.getDate());
      let tMonth = addDateZero(targetTime.getMonth() + 1);
      let tYear = targetTime.getFullYear();
      let date1 = tYear + "-" + tMonth + "-" + tDate;
      // 获取目标日期前一天
      let nDate = addDateZero(newTarget.getDate());
      let nMonth = addDateZero(newTarget.getMonth() + 1);
      let nYear = newTarget.getFullYear();
      let date2 = nYear + "-" + nMonth + "-" + nDate;
      // 获取当前日期
      let nowDate = addDateZero(now.getDate());
      let nowMonth = addDateZero(now.getMonth() + 1);
      let nowYear = now.getFullYear();
      let date3 = nowYear + "-" + nowMonth + "-" + nowDate;

      if (date1 === date3) {
        return this.$i18n.t("today") + " " + handleHour(target);
      } else if (date2 === date3) {
        return this.$i18n.t("tomorrow") + " " + handleHour(target);
      } else {
        return date1 + " " + handleHour(target);
      }
    },
    enter(index) {
      this.moreOperationData[index].isHov = true;
    },
    outer(index) {
      this.moreOperationData[index].isHov = false;
    },
    // 点击菜单项
    handleCommand(command) {
      // 电话回复
      let conf = {
        caseid: this.caseid,
      };
      if (command == "callBack") {
        controlPanelApi.getContactInfo(conf).then((res) => {
          let phone = res.data[0].shouji || res.data[0].dianhua;
          this.$bus.$emit("newCLick", phone);
        });
      } else if (command == "replyMail") {
        let obj = this.EmailInfo.length == 0 ? {} : this.EmailInfo[0];
        obj.fromaddress = this.contactEmail;
        this.$emit("toSend", true, obj, false);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.conversation {
  margin: 20px 0;
  .conversation-top {
    width: 100%;
    display: flex;
    justify-content: space-between;
    .to-left {
      display: flex;
      justify-content: space-between;

      .avater {
        width: 48px;
        height: 48px;
        margin-right: 20px;
      }
      .perInfo {
        color: #666;
        > div {
          height: 22px;
          line-height: 22px;
        }
        span {
          margin-right: 12px;
        }
        .name {
          font-size: 16px;
          color: #333;
          font-weight: bold;
        }
      }
    }
    .point-icon {
      cursor: pointer;
      width: 30px;
      height: 20px;
    }
  }
  .warp {
    width: 86%;
    margin-left: 67px;
    margin-top: 16px;
  }
  .conversation-content {
    .content-evaluate {
      padding: 16px;
      border-radius: 4px;
      border: 1px solid #dedcda;
      .evaluate-title {
        margin-bottom: 16px;
        color: #333;
        > span {
          margin-right: 10px;
        }
        > span:nth-child(2) {
          color: #666;
        }
      }
      .evaluate-desc {
        display: flex;
        justify-content: flex-start;
        .avater {
          width: 44px;
          height: 44px;
          margin-right: 12px;
        }
        .text {
          > p {
            height: 22px;
            line-height: 22px;
          }
          > p:nth-child(1) {
            font-size: 16px;
          }
          > p:nth-child(2) {
            font-size: 12px;
          }
          > p:nth-child(3) {
            font-size: 12px;
          }
        }
      }
    }
  }
}
// 搁置样式
.point-li {
  border: 0;
  .point-icon {
    display: inline-block;
    width: 30px;
    height: 20px;
    margin-right: 5px;
    position: relative;
    top: 4px;
  }
}
</style>