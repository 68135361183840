<template>
  <!-- 分离个案弹框 -->
  <div>
    <el-dialog
      :visible="separateCaseDialog"
      top="15%"
      :width="'420px'"
      :close-on-click-modal="false"
      :before-close="closeSeparateDialog"
    >
      <div slot="title" class="header-title">
        <div style="display: flex">
          <svg
            class="logoIcon"
            aria-hidden="true"
            slot="reference"
            style="width: 24px; height: 24px; margin-right: 8px"
          >
            <use href="#icon-full_separationCase_cli"></use>
          </svg>
          <div>{{ $t("front-console-conversation-btn4") }}</div>
        </div>
      </div>
      <div>{{ $t("front-kefu-moudle-confirm-fjcase") }}?</div>
      <span slot="footer" class="dialog-footer">
        <span>
          <el-button @click="closeSeparateDialog">
            <!-- 取消 -->
            {{ $t("label.cancel") }}
          </el-button>
          <el-button @click="confirmSeparateDialog" type="primary">
            {{ $t("setup.layout.button.ok") }}
          </el-button></span
        >
      </span>
    </el-dialog>
  </div>
</template>
<script>
import * as controlPanelApi from "../../../api.js";
export default {
  props: {
    separateCaseDialog: {
      type: Boolean,
      default: false,
    },
    recordId: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      caseid: this.$route.query.caseId,
    };
  },
  methods: {
    // 取消分离个案
    closeSeparateDialog() {
      this.$emit("closeSeparate");
    },

    // 确认分离个案
    confirmSeparateDialog() {
      let params = {
        caseId: this.caseid,
        recordId: this.recordId,
      };
      controlPanelApi.splitCase(params).then((res) => {
        if (res.result) {
          this.$message.success(this.$i18n.t("front-kefu-moudle-chf-success"));
          this.$emit("closeSeparate");
          this.$bus.$emit("openNewCase", res.data.id, res.data.name);
          this.$bus.$emit("fresh-conversation-related");
        }
      });
    },
  },
};
</script>
<style lang="scss">
</style>