 import i18n from './i18n'
import moment from "../utils/dayjs";
//此文件同changeTime.js的区别是加入了多时区
var getTimeStringAutoShort = function (timestamp, mustIncludeTime,timezone) {

    // 当前时间
    var currentDate = new Date();
    // 目标判断时间
    var srcDate = new Date(parseInt(timestamp));
    var currentYear = currentDate.getFullYear();
    var currentMonth = (currentDate.getMonth() + 1);
    var currentDateD = currentDate.getDate();

    var srcYear = srcDate.getFullYear();
    var srcMonth = (srcDate.getMonth() + 1);
    var srcDateD = srcDate.getDate();

    var ret = "";
    // 要额外显示的时间分钟                
    var timeExtraStr = (mustIncludeTime ? moment.tz(moment.unix(Number(timestamp / 1000)),timezone).format("hh:mm") : ""); 

    // 当年
    if (currentYear == srcYear) {
        var currentTimestamp = currentDate.getTime();
        var srcTimestamp = timestamp;
        // 相差时间（单位：毫秒）
        var deltaTime = (currentTimestamp - srcTimestamp);

        // 当天（月份和日期一致才是）
        if (currentMonth == srcMonth && currentDateD == srcDateD) {
            // 时间相差60秒以内
            if (deltaTime < 60 * 1000)
                ret = moment.tz(moment.unix(Number(timestamp / 1000)),timezone).format("hh:mm");
            // 否则当天其它时间段的，直接显示“时:分”的形式
            else
                ret = moment.tz(moment.unix(Number(timestamp / 1000)),timezone).format("hh:mm");
        }
        // 当年 && 当天之外的时间（即昨天及以前的时间）
        else {
            // 昨天（以“现在”的时候为基准-1天）
            var yesterdayDate = new Date();
            yesterdayDate.setDate(yesterdayDate.getDate() - 1);

            // 前天（以“现在”的时候为基准-2天）
            var beforeYesterdayDate = new Date();
            beforeYesterdayDate.setDate(beforeYesterdayDate.getDate() - 2);

            // 用目标日期的“月”和“天”跟上方计算出来的“昨天”进行比较，是最为准确的（如果用时间戳差值
            // 的形式，是不准确的，比如：现在时刻是2019年02月22日1:00、而srcDate是2019年02月21日23:00，
            // 这两者间只相差2小时，直接用“deltaTime/(3600 * 1000)” > 24小时来判断是否昨天，就完全是扯蛋的逻辑了）
            if (srcMonth == (yesterdayDate.getMonth() + 1) && srcDateD == yesterdayDate.getDate())
                ret = i18n.t('lable.account.Yesterday')+ timeExtraStr;// -1d
            // “前天”判断逻辑同上
            // else if (srcMonth == (beforeYesterdayDate.getMonth() + 1) && srcDateD == beforeYesterdayDate.getDate())
            //     ret = i18n.t('label.ccchat.day.before.yesterday') + timeExtraStr;// -2d
            else {
                // 跟当前时间相差的小时数
                var deltaHour = (deltaTime / (3600 * 1000));
                // 当前时间星期几
                let currentDay=currentDate.getDay()
                // 目标时间星期几
                let srcDay=srcDate.getDay()

                // 如果小于或等 7*24小时就显示星期几(当前星期数必须大于目标星期数)
                if ((currentDay>srcDay)&&deltaHour <= 7 * 24) {
                    var weekday = new Array(7);
                    weekday[0] = i18n.t('sunday');
                    weekday[1] = i18n.t('monday');
                    weekday[2] = i18n.t('tuesday');
                    weekday[3] = i18n.t('wednesday');
                    weekday[4] = i18n.t('thursday');
                    weekday[5] = i18n.t('friday');
                    weekday[6] = i18n.t('saturday');

                    // 取出当前是星期几
                    var weedayDesc = weekday[srcDate.getDay()];
                    ret = weedayDesc + timeExtraStr;
                }
                // 否则直接显示完整日期时间
                else
                    ret = moment.tz(moment.unix(Number(timestamp / 1000)),timezone).format("YYYY-MM-DD") + '\xa0' + timeExtraStr;
            }
        }
    }
    // 往年
    else {
        ret = moment.tz(moment.unix(Number(timestamp / 1000)),timezone).format("YYYY-MM-DD") + '\xa0' + timeExtraStr;
    }
    return ret;
};
export default getTimeStringAutoShort;