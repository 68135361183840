<template>
  <div>
    <div class="emojy-box">
      <span v-if="newMsg != ''" v-html="newMsg"></span>
      <span v-if="newMsg == ''">{{expressionData}}</span>
    </div>
  </div>
</template>


<script>
export default {
  data() {
    return {
      expressionList: [
        {
          name: "[):]",
          num: "1",
          src: require("../assets/huanxinbiaoqing/ee_1.png"),
        },
        {
          name: "[:D]",
          num: "2",
          src: require("../assets/huanxinbiaoqing/ee_2.png"),
        },
        {
          name: "[;)]",
          num: "3",
          src: require("../assets/huanxinbiaoqing/ee_3.png"),
        },
        {
          name: "[:-o]",
          num: "4",
          src: require("../assets/huanxinbiaoqing/ee_4.png"),
        },
        {
          name: "[:p]",
          num: "5",
          src: require("../assets/huanxinbiaoqing/ee_5.png"),
        },
        {
          name: "[(H)]",
          num: "6",
          src: require("../assets/huanxinbiaoqing/ee_6.png"),
        },
        {
          name: "[:@]",
          num: "7",
          src: require("../assets/huanxinbiaoqing/ee_7.png"),
        },
        {
          name: "[:s]",
          num: "8",
          src: require("../assets/huanxinbiaoqing/ee_8.png"),
        },
        {
          name: "[:$]",
          num: "9",
          src: require("../assets/huanxinbiaoqing/ee_9.png"),
        },
        {
          name: "[:(]",
          num: "10",
          src: require("../assets/huanxinbiaoqing/ee_10.png"),
        },
        {
          name: "[:'(]",
          num: "11",
          src: require("../assets/huanxinbiaoqing/ee_11.png"),
        },
        {
          name: "[:|]",
          num: "12",
          src: require("../assets/huanxinbiaoqing/ee_12.png"),
        },
        {
          name: "[(a)]",
          num: "13",
          src: require("../assets/huanxinbiaoqing/ee_13.png"),
        },
        {
          name: "[8o|]",
          num: "14",
          src: require("../assets/huanxinbiaoqing/ee_14.png"),
        },
        {
          name: "[8-|]",
          num: "15",
          src: require("../assets/huanxinbiaoqing/ee_15.png"),
        },
        {
          name: "[+o(]",
          num: "16",
          src: require("../assets/huanxinbiaoqing/ee_16.png"),
        },
        {
          name: "[<o)]",
          num: "17",
          src: require("../assets/huanxinbiaoqing/ee_17.png"),
        },
        {
          name: "[|-)]",
          num: "18",
          src: require("../assets/huanxinbiaoqing/ee_18.png"),
        },
        {
          name: "[*-)]",
          num: "19",
          src: require("../assets/huanxinbiaoqing/ee_19.png"),
        },
        {
          name: "[:-#]",
          num: "20",
          src: require("../assets/huanxinbiaoqing/ee_20.png"),
        },
        {
          name: "[:-*]",
          num: "21",
          src: require("../assets/huanxinbiaoqing/ee_21.png"),
        },
        {
          name: "[^o)]",
          num: "22",
          src: require("../assets/huanxinbiaoqing/ee_22.png"),
        },
        {
          name: "[8-)]",
          num: "23",
          src: require("../assets/huanxinbiaoqing/ee_23.png"),
        },
        {
          name: "[(|)]",
          num: "24",
          src: require("../assets/huanxinbiaoqing/ee_24.png"),
        },
        {
          name: "[(u)]",
          num: "25",
          src: require("../assets/huanxinbiaoqing/ee_25.png"),
        },
        {
          name: "[(S)]",
          num: "26",
          src: require("../assets/huanxinbiaoqing/ee_26.png"),
        },
        {
          name: "[(*)]",
          num: "27",
          src: require("../assets/huanxinbiaoqing/ee_27.png"),
        },
        {
          name: "[(#)]",
          num: "28",
          src: require("../assets/huanxinbiaoqing/ee_28.png"),
        },
        {
          name: "[(R)]",
          num: "29",
          src: require("../assets/huanxinbiaoqing/ee_29.png"),
        },
        {
          name: "[({)]",
          num: "30",
          src: require("../assets/huanxinbiaoqing/ee_30.png"),
        },
        {
          name: "[(})]",
          num: "31",
          src: require("../assets/huanxinbiaoqing/ee_31.png"),
        },
        {
          name: "[(k)]",
          num: "32",
          src: require("../assets/huanxinbiaoqing/ee_32.png"),
        },
        {
          name: "[(F)]",
          num: "33",
          src: require("../assets/huanxinbiaoqing/ee_33.png"),
        },
        {
          name: "[(W)]",
          num: "34",
          src: require("../assets/huanxinbiaoqing/ee_34.png"),
        },
        {
          name: "[(D)]",
          num: "35",
          src: require("../assets/huanxinbiaoqing/ee_35.png"),
        },
      ],
      newMsg: "",
    };
  },
  props: {
    expressionData: {
      type: String,
      default: "",
    },
  },
  mounted() {
    this.MatchPicture();
  },
  methods: {
    MatchPicture() {
      let str = this.expressionData;
      this.expressionList.forEach((item) => {
        if (str.indexOf(item.name) != -1) {
          str = str.replace(
            item.name,
            `<img src="${item.src}" alt="">`
          );
        }
      });
      this.newMsg = str;
    },
  },
};
</script>


<style lang="scss" scoped>
.emojy-box{
  span{
    display: block;
    max-width: 280px;
    word-wrap:break-word; 
  }
}
</style>