<template>
  <!-- 开发人员：张亚欣
  多语言替换：王雪茹
  -->
  <div class="relation-list-obj" v-loading="objectInfo.loading">
    <!-- 通用相关列表 -->
    <div class="tit">
      <div class="box_info">
        <div class="tit_s">
          <p>
            {{ obj.relationName }}
          </p>
        </div>
        <!-- 列表表头说明文字 -->
        <!-- ($refs.tablePanel && $refs.tablePanel.checkedList.length) || lengths -->
        <!-- lengths未定义 -->
        <statistics
          :total="objectInfo.totalCount"
          :isQueryCount="true"
          :time-interval="timeInterval"
          :show-checked-length="false"
          :checked-length="
            $refs.tablePanel && $refs.tablePanel.checkedList.length
          "
          @refresh="refresh"
        ></statistics>
      </div>
      <div class="tit_right">
        <div>
          <!-- 只有项目任务下有列表和看板图标 -->
          <el-button-group
            class="tubiao_box"
            v-if="objectInfo.relatedlistType === 'CloudccTask'"
          >
            <!-- 列表视图 -->
            <el-tooltip
              popper-class="my-tooltip"
              :content="$t('front-detail-module-v1-list-cardview')"
              placement="top"
              :open-delay="500"
            >
              <el-button
                size="mini"
                class="btn group-btn"
                @click="handleClick('tableView')"
              >
                <svg
                  v-if="hoverBtn === 'tableView' || clickBtn === 'tableView'"
                  class="icon icon-button"
                  aria-hidden="true"
                  @mouseover="mouseOverBtn('tableView')"
                  @mouseleave="mouseLeaveBtn('tableView')"
                >
                  <use href="#icon-list_hov"></use>
                </svg>
                <svg
                  v-else
                  class="icon icon-button"
                  aria-hidden="true"
                  @mouseover="mouseOverBtn('tableView')"
                  @mouseleave="mouseLeaveBtn('tableView')"
                >
                  <use href="#icon-list_nor"></use>
                </svg>
              </el-button>
            </el-tooltip>
            <!-- 看板视图 -->
            <el-tooltip
              popper-class="my-tooltip"
              class="item"
              effect="dark"
              :content="$t('vue_label_commonobjects_view_kanban_view')"
              placement="top-start"
              :open-delay="500"
            >
              <el-button
                size="mini"
                class="btn group-btn"
                @click="handleClick('boardView')"
              >
                <svg
                  v-if="hoverBtn === 'boardView' || clickBtn === 'boardView'"
                  class="icon icon-button"
                  aria-hidden="true"
                  @mouseover="mouseOverBtn('boardView')"
                  @mouseleave="mouseLeaveBtn('boardView')"
                >
                  <use href="#icon-task_hov"></use>
                </svg>
                <svg
                  v-else
                  class="icon icon-button"
                  aria-hidden="true"
                  @mouseover="mouseOverBtn('boardView')"
                  @mouseleave="mouseLeaveBtn('boardView')"
                >
                  <use href="#icon-task_nor"></use>
                </svg>
              </el-button>
            </el-tooltip>
          </el-button-group>
          <el-button-group
            v-if="objectInfo.showButton"
            style="position: relative"
            class="right_btn"
          >
            <el-button
              class="button"
              size="small"
              v-for="(item, i) in objectInfo.showButton"
              :key="i"
              :disabled="item.isFlag || item.isAddFlag"
              :style="{
                backgroundColor: item.isFlag || item.isAddFlag ? '#fff' : '',
                color: item.isFlag || item.isAddFlag ? '#C0C4CC' : '',
              }"
              :class="item.id === 'dropbox' ? 'dropbox' : ''"
              @click="buttonFun(item)"
              style="position: relative"
            >
              <span v-if="i < showBtnLength">
                <span v-if="item.id === 'dropbox' ? true : false">
                  <svg
                    class="icon"
                    aria-hidden="true"
                    style="
                      width: 18px;
                      height: 18px;
                      position: absolute;
                      left: 1px;
                      top: 5px;
                    "
                    @click="dropboxPicker"
                  >
                    <use href="#icon-dropbox"></use>
                  </svg>
                  <VueDropboxPicker
                    v-if="dropbox"
                    :api-key="dropboxApiKey"
                    link-type="preview"
                    :multiselect="true"
                    :folderselect="true"
                    :extensions="[]"
                    @cancel="onCancel"
                    @picked="onPicked"
                  >
                    <svg
                      class="icon"
                      aria-hidden="true"
                      style="
                        width: 18px;
                        height: 18px;
                        position: absolute;
                        left: 1px;
                        top: 5px;
                      "
                      @click="dropboxPicker"
                    >
                      <use href="#icon-dropbox"></use>
                    </svg>
                  </VueDropboxPicker>
                </span>
                <span v-else>{{ item.label }}</span>
              </span>
            </el-button>
            <el-popover
              placement="bottom-end"
              popper-class="pop_box"
              :visible-arrow="false"
              trigger="hover"
            >
              <el-button
                class="list_xiala_s"
                size="small"
                slot="reference"
                v-show="objectInfo.showButton.length > showBtnLength"
              >
                <svg class="icon" aria-hidden="true">
                  <use href="#icon-blue_sj"></use>
                </svg>
              </el-button>
              <ul class="moreButton">
                <li
                  v-for="(item, index) in objectInfo.showButton"
                  :key="index"
                  @click="buttonFun(item)"
                  style="cursor: pointer"
                >
                  <span v-if="index > showBtnLength - 1">{{ item.label }}</span>
                </li>
              </ul>
            </el-popover>
          </el-button-group>
        </div>
        <div v-if="!showlist && showKanban" class="fixed_btn">
          <!-- 只有看板视图显示 -->
          <el-button
            type="primary"
            size="mini"
            v-if="isShowMilepostBtn"
            @click="newMilestoneButton"
            >{{ $t("label.projectManagement.Newmilestone") }}</el-button
          >
          <el-select
            v-model="currentSelectChecked"
            :placeholder="$t('label.emailtocloudcc.select1')"
            @change="getKanBanData(currentSelectChecked)"
          >
            <el-option
              v-for="item in optionss"
              :key="item.value"
              :label="item.label"
              :value="item.value"
              style="font-weight: bold"
            >
            </el-option>
          </el-select>
        </div>
      </div>
    </div>

    <!-- 批准历史 -->
    <div v-if="objectInfo.relatedlistType == 'approvalHistory'">
      <ApprovalHistory
        ref="myChlidHistory"
        @showWhichBtn="showHistoryBtn"
        :historyID="id"
        :itemTitle="bigTitle"
      >
      </ApprovalHistory>
    </div>
    <div
      v-if="showlist && !showKanban"
      :style="{
        marginBottom:
          isRlation === 'List' && objectInfo.data && objectInfo.data.length > 0
            ? '50px'
            : '0',
      }"
    >
      <!--工作订单行项目-->
      <!-- <div
        v-if="
          objectInfo.objid === 'WorkOrderLineItemObj' &&
          objId === 'WorkOrderstdobj02021'
        "
      >
        <OrderLineProcess :orderID="id"></OrderLineProcess>
      </div> -->
      <div
        v-if="
          isRlation === 'List' &&
          detailLayoutMode === 'mode1' &&
          objectInfo.relatedlistType !== 'approvalHistory'
        "
      >
        <table-panel
          v-show="
            objectInfo.relatedlistType !== 'approvalHistory' &&
            obj.relationName !== $t('lable.opppdt.selPbk.pricebook') &&
            objectInfo.data &&
            objectInfo.data.length > 0 &&
            !objectInfo.data[0].norecord
          "
          class="text-center"
          ref="tablePanel"
          :border="true"
          :show-index="true"
          :showLock="false"
          :checked="objectInfo.objid === 'attachement' ? true : false"
          :customHeader="false"
          :prefix="objectInfo.prefix"
          :obj-id="objectInfo.objid"
          :objectApi="objectInfo.objName"
          :pageNum="pageNum"
          :table-attr="objectInfo.fieldList"
          :table-button="tableBtnList"
          :page-obj="pageObj"
          :noDataTip="noDataTip"
          :view-modify="ismodifyFalg"
          :itemObj="objectInfo"
          :page-size-list="pageSize"
          :current-page="skippageNum"
          @edit="everyEdit"
          @del="everyDelet"
          @onchange="onchange"
        >
        </table-panel>
      </div>
      <!-- 卡片 -->
      <template v-else>
        <!--相关列表下条目信息-->
        <div
          v-if="
            objectInfo.data &&
            objectInfo.data.length !== 0 &&
            objectInfo.data[0] &&
            !objectInfo.data[0].norecord
          "
          class="cont_table"
        >
          <div
            class="cont"
            v-for="(item1, index1) in objectInfo.data.slice(0, 10)"
            :key="index1"
          >
            <div class="cont_div">
              <!-- 文件的相关列表 -->
              <ul
                class="list_left"
                style="position: relative"
                v-if="objectInfo.objName === 'Attachement'"
              >
                <li>
                  <el-checkbox
                    v-model="item1.attachChecked"
                    class="work_check"
                  ></el-checkbox>
                  <!-- @change="attachBtn(item1)" -->
                  <dl style="display: flex; width: 90%">
                    <dt>
                      <svg
                        v-if="item1.attachtype === 'dropbox'"
                        class="icon"
                        aria-hidden="true"
                        style="width: 28px; height: 35px; margin: 0 10px 0 10px"
                      >
                        <use href="#icon-dropbox"></use>
                      </svg>
                      <svg
                        v-else
                        class="icon"
                        aria-hidden="true"
                        style="width: 28px; height: 35px; margin: 0 10px 0 10px"
                      >
                        <use
                          :href="
                            item1.suffix && allIcon[item1.suffix.split('.')[1]]
                              ? allIcon[item1.suffix.split('.')[1]]
                              : defaultIcon
                          "
                        ></use>
                      </svg>
                    </dt>
                    <dd
                      :style="{
                        marginLeft: 0,
                        width: detailLayoutMode === 'mode1' ? '80%' : '60%',
                      }"
                    >
                      <p
                        style="color: #006dcc; cursor: pointer; width: 100%"
                        @click="detailBtn(item1)"
                        @contextmenu.prevent.stop="
                          item1.attachtype === 'dropbox' ? detailBtn(item1) : ''
                        "
                        @mouseenter="handleCellMouseEnter($event)"
                        @mouseleave="handleCellMouseLeave($event)"
                      >
                        <span class="totip topicStyle"
                          >{{ item1.name }}{{ item1.suffix }}</span
                        >
                      </p>
                      <p>
                        {{ item1.createdate | datetimeFormat(countryCode) }}
                      </p>
                    </dd>
                  </dl>
                  <el-popover
                    placement="bottom-end"
                    :visible-arrow="false"
                    popper-class="pop_small"
                    trigger="hover"
                  >
                    <div
                      slot="reference"
                      class="xiala_s"
                      style="position: absolute; top: 0; right: 0"
                    >
                      <svg class="icon inline_block" aria-hidden="true">
                        <use href="#icon-sj"></use>
                      </svg>
                    </div>
                    <ul class="moreButton">
                      <!-- 编辑 -->
                      <!-- <li
                        style="cursor: pointer"
                        v-if="
                          objectInfo.objid !== 'attachement' &&
                            objectInfo.objName !== 'tp_sys_contactrole' &&
                            objectInfo.services.modify
                        "
                        @click="everyEdit(item1)"
                      >
                        {{ $t('pagecreator_page_button_edit') }}
                      </li> -->
                      <!-- 删除 -->
                      <li
                        style="cursor: pointer"
                        @click="everyDelet(objectInfo.objName, item1.id, item1)"
                      >
                        {{ $t("component_setup_tabs_label_delete") }}
                      </li>
                    </ul>
                  </el-popover>
                </li>
              </ul>
              <!-- 通用相关列表 -->
              <ul class="list_left" v-else>
                <li v-for="(item, i) in objectInfo.fieldList" :key="i">
                  <div v-if="item.name === 'name'">
                    <div class="title">
                      <div class="knowOwnpop">
                        <Popover-cpn
                          v-if="
                            objectInfo.objid === 'cloudcc_k_article' &&
                            item.name === 'name'
                          "
                          :recordObj="item1"
                          :content="item1.name"
                          :knowOwnImg="true"
                          @jumpDetail="jumpDetail(item1)"
                        />
                        <p v-else @click="detailBtn(item1)">{{ item1.name }}</p>
                      </div>
                      <el-popover
                        placement="bottom-end"
                        :visible-arrow="false"
                        popper-class="pop_small"
                        trigger="hover"
                        v-if="
                          (objectInfo.services.delete ||
                            objectInfo.services.modify) &&
                          typedisabled !== '草稿'
                        "
                      >
                        <div
                          slot="reference"
                          class="xiala_s"
                          v-if="
                            (objectInfo.services.delete ||
                              objectInfo.services.modify) &&
                            typedisabled !== '草稿'
                          "
                        >
                          <svg class="icon inline_block" aria-hidden="true">
                            <use href="#icon-sj"></use>
                          </svg>
                        </div>
                        <!-- v-show="objectInfo.objid!=='attachement'" -->
                        <!-- 文件和联系人角色不允许编辑 -->
                        <ul class="moreButton">
                          <li
                            style="cursor: pointer"
                            v-if="
                              objectInfo.objid !== 'attachement' &&
                              objectInfo.objName !== 'tp_sys_contactrole' &&
                              objectInfo.services.modify
                            "
                            @click="everyEdit(item1)"
                          >
                            {{ $t("pagecreator_page_button_edit") }}
                          </li>
                          <li
                            style="cursor: pointer"
                            v-if="objectInfo.services.delete"
                            @click="
                              everyDelet(objectInfo.objName, item1.id, item1)
                            "
                          >
                            {{ $t("component_setup_tabs_label_delete") }}
                          </li>
                        </ul>
                      </el-popover>
                    </div>
                  </div>
                </li>
                <div>
                  <li
                    v-for="(item, indexs) in objectInfo.fieldList"
                    :key="indexs"
                  >
                    <p
                      style="width: 30%"
                      v-if="item.name !== 'name'"
                      @mouseenter="handleCellMouseEnter($event)"
                      @mouseleave="handleCellMouseLeave($event)"
                    >
                      <span class="totip topicStyle">{{ item.label }}:</span>
                    </p>
                    <p
                      style="width: 70%"
                      v-if="
                        item.type === 'Y' ||
                        item.type === 'Q' ||
                        item.type === 'MR' ||
                        item.type === 'M' ||
                        (item.type === 'R' &&
                          objectInfo.objName !==
                            'influenceBusinessOpportunities')
                      "
                      @click="
                        item.type === 'Y' && item.name === 'contact'
                          ? detailBtn(item1)
                          : ''
                      "
                      :style="{
                        color:
                          item.type === 'Y' && item.name === 'contact'
                            ? '#006dcc'
                            : '',
                        fontWeight:
                          item.type === 'Y' && item.name === 'contact'
                            ? '700'
                            : '',
                        cursor:
                          item.type === 'Y' && item.name === 'contact'
                            ? 'pointer'
                            : '',
                      }"
                      @mouseenter="handleCellMouseEnter($event)"
                      @mouseleave="handleCellMouseLeave($event)"
                    >
                      <span class="totip topicStyle">{{
                        item1[item.name + "ccname"]
                          ? item1[item.name + "ccname"]
                          : item1[item.name]
                      }}</span>
                    </p>
                    <p v-else-if="item.type === 'B'">
                      <el-checkbox
                        :value="
                          item1[item.name] === 'true' || item1[item.name] === 1
                        "
                        disabled
                      ></el-checkbox>
                    </p>
                    <p v-else-if="item.type === 'D'">
                      <!-- 详情-实际工作清单-日期添加点击事件 -->
                      <span
                        v-if="objectInfo.objid === 'projectworklist'"
                        @click="detailBtn(item1)"
                        style="color: #006dcc; fontweight: 700; cursor: pointer"
                      >
                        {{ item1[item.name] | dateFormat(countryCode) }}
                      </span>
                      <span v-else>{{
                        item1[item.name] | dateFormat(countryCode)
                      }}</span>
                    </p>
                    <p v-else-if="item.type === 'F'">
                      {{ item1[item.name] | datetimeFormat(countryCode) }}
                    </p>
                    <p
                      v-else-if="
                        objectInfo.objName === 'influenceBusinessOpportunities'
                      "
                    >
                      {{ item1[item.name] }}
                    </p>
                    <p
                      style="width: 70%"
                      v-else-if="
                        item.name === 'operate' || item.name === 'clxx'
                      "
                      @mouseover="disBtn(index1)"
                      @mouseenter="handleCellMouseEnter($event)"
                      @mouseleave="handleCellMouseLeave($event)"
                    >
                      <span
                        class="totip dis_a topicStyle"
                        v-html="item1[item.name]"
                      ></span>
                    </p>
                    <!-- 图片-->
                    <p v-else-if="item.type === 'IMG'" class="cannotEdit">
                      <img
                        v-if="
                          item1[item.name] !== undefined &&
                          item1[item.name] !== '' &&
                          item1[item.name] !== null
                        "
                        :src="imgSrc(item1[item.name])"
                      />
                    </p>
                    <p
                      v-else-if="item.type === 'SCORE'"
                      class="cannotEdit stars"
                    >
                      <span
                        v-for="(star, idx) in Number(item.length) + 18"
                        v-show="idx !== 0"
                        :class="{
                          show2: idx <= Number(item1[item.name]),
                        }"
                        :key="idx"
                      >
                      </span>
                    </p>
                    <p
                      v-else
                      style="width: 70%"
                      @mouseenter="handleCellMouseEnter($event)"
                      @mouseleave="handleCellMouseLeave($event)"
                    >
                      <span class="totip topicStyle">{{
                        item.name === "Phone"
                          ? item1["phone"]
                          : item1[item.name]
                      }}</span>
                    </p>
                  </li>
                </div>
              </ul>
            </div>
          </div>
        </div>
        <el-tooltip ref="tooltip" effect="dark" :content="tooltipContent">
        </el-tooltip>
      </template>
      <!-- 分页-->
      <div
        :class="detailType === 'controlPanel' ? 'control-panel-block' : 'block'"
      >
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="skippageNum"
          :page-sizes="pageSizes"
          :page-size="pageSize"
          :layout="this.layout"
          :total="this.totalSize"
          :page-count="pageCount"
        >
        </el-pagination>
        <span style="margin-left: 10px" v-if="isTotal">
          {{ $t("label.sales.dashboard.number") }} {{ skippageNum }}
          {{ $t("label.tabpage.page") }}
        </span>
        <span class="go_homepage" v-if="isTotal" @click="goHomePage">{{
          $t("label.homepage")
        }}</span>
      </div>
    </div>
    <!-- 任务看板 -->
    <div v-if="!showlist && showKanban" class="kanbanBox">
      <projectTasks
        ref="changeImplement"
        :projectId="id"
        @childfunc="childfunc($event)"
      ></projectTasks>
    </div>
    <upload
      v-if="
        objectInfo.objName === 'Attachement' && objectInfo.data.length === 0
      "
      style="width: 100%"
      ref="upload"
      :fileType="fileType"
      :size="size"
      :limit="10"
      :action="action"
      :multiple="multiple"
      :autoUpload="true"
      :identification="identification"
      :drag="true"
      @fileChange="fileChange"
      @success="success"
      @error="error"
      @UploadSuccess="UploadSuccess"
    >
    </upload>
    <!-- 服务报告预览 -->
    <file-preview
      :show="showPreview"
      :showUrl="previewUrl"
      :showData="showPreviewData"
      @closePreview="closePreview"
      ref="previewFile"
    >
    </file-preview>
    <!-- 详情页复制/编辑相关列表记录 -->
    <create-edit-obj
      ref="createEditObj"
      :prefix="listPrefix"
      :id="dialogNewAttr.id"
      :objectApi="listObjectApi"
      :relation-field-id="relatedListObj.relationFieldId"
      :object-name="relatedListObj.objLabel"
      type="active"
      :relative-record-id="id"
      :realObjId="realObjId"
      @save="save"
      @update="toUpdate"
    ></create-edit-obj>
    <!-- 删除数据提示 -->
    <el-dialog
      :visible.sync="dialogVisible"
      :title="$t('component_setup_tabs_label_delete')"
      top="15%"
      width="25%"
      :close-on-click-modal="false"
      :before-close="handleClose"
    >
      <span style="font-size: 14px; color: #080707">
        <!-- 确认删除该条数据吗？ -->
        {{ $t("label.weixin.confirm.delete") }}
      </span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false" size="mini">
          <!-- 取消 -->
          {{ $t("label.ems.cancel") }}
        </el-button>
        <el-button @click="confirmDel" type="primary" size="mini">
          <!-- 删除 -->
          {{ $t("component_setup_tabs_label_delete") }}
        </el-button>
      </span>
    </el-dialog>
    <!-- 相关列表添加文件 -->
    <add-files
      ref="atoLib"
      :isFromFileCenter="false"
      :dialogAddVisible="dialogAddVisible"
      @addSubmit="addWorkSubmit"
      @close="addWorkClose"
    ></add-files>
    <!--批量新增-->
    <el-dialog
      :visible.sync="batchAddDialog"
      top="10%"
      :title="batchAddDialogTitle"
      width="90%"
      :before-close="closeBatchAddDialog"
    >
      <batchAdd
        ref="batchAdd"
        :prefix="listPrefix"
        :objectApi="listObjectApi"
        :recordId="relatedListObj.recordId"
        :relation-field-id="relatedListObj.relationFieldId"
        :relatedListFlag="relatedListObj.relatedListFlag"
        @closeBatchAddDialog="closeBatchAddDialog"
        :pageFlag="pageFlag"
      />
      <div slot="footer" class="dialog-footer" style="text-align: right">
        <el-button size="mini" @click="closeBatchAddDialog('cancel')">
          <!-- 取消 -->
          {{ $t("label.cancel") }}
        </el-button>
        <el-button size="mini" type="primary" @click="saveBatchAdd">
          <!-- 确定 -->
          {{ $t("label.confirm") }}
        </el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { Loading } from "element-ui";
import * as CommonObjApi from "./api";
import ApprovalHistory from "@/views/approval/components/approval-history.vue";
// import OrderLineProcess from "@/views/commonObjects/detailChild/OrderLineProcess/OrderLineProcess.vue";
import FILEICON from "@/views/ccFileCenter/config/fileIconLibrary";
import { Upload } from "@/components/index";
//三个点显示气泡
import { getStyle } from "@/utils/threePoints.js";
import debounce from "throttle-debounce/debounce";
import detailSendCard from "@/utils/robotMessage.js";
import VueDropboxPicker from "@/components/vue-dropbox-chooser/DropboxPicker.vue";
import { TablePanel } from "@/components/index";
import createButton from "@/utils/Button";
import * as Time from "@/utils/time";
import VueCookies from "vue-cookies";
import PopoverCpn from "@/views/homePage/components/PopoverCpn";
import filePreview from "@/components/FileView/filePreview";
import { Statistics, AddFiles } from "@/components/index";
import createEditObj from "@/views/commonObjects/components/create-edit-obj.vue";
import batchAdd from "@/components/ButtonWrapper/batchAdd";
import projectTasks from "@/views/projectTasks"; //看板视图

export default {
  name: "relation-list-obj",
  components: {
    ApprovalHistory,
    // OrderLineProcess,
    Upload,
    VueDropboxPicker,
    TablePanel,
    PopoverCpn,
    filePreview,
    Statistics,
    createEditObj,
    AddFiles,
    batchAdd,
    projectTasks,
  },
  props: {
    detailLayoutMode: {
      type: String,
      default: "mode1",
    },
    // 相关列表的对象信息
    obj: {
      type: Object,
      default: () => {},
    },
    // 主记录id
    id: {
      type: String,
      default: "",
    },
    detailGroupId: {
      type: String,
      default: "",
    },
    // 主记录名称
    bigTitle: {
      type: String,
      default: "",
    },
    // 主记录对象id
    objId: {
      type: String,
      default: "",
    },
    typedisabled: {
      type: String,
      default: "",
    },
    // 是否来自服务控制台
    detailType: {
      type: String,
      default: "project",
    },
  },
  data() {
    return {
      showlist: true,
      isTotal: false, //是否显示第几页和回到首页按钮
      pageFlag: "",
      objectInfo: {},
      defaultIcon: FILEICON.default, // 文件缺省图标
      allIcon: FILEICON, // 文件图标
      action: "/test",
      fileType: [],
      size: 1024 * 500,
      multiple: true,
      identification: "",
      tooltipContent: "",
      dropboxApiKey: "aj2eb0wetk4aocr",
      dateFormat: Time.dateFormat(this.$cookies.get("countryCode")),
      datetimeFormat: Time.datetimeFormat(this.$cookies.get("countryCode")),
      countryCode: this.$cookies.get("countryCode"),
      noDataTip: false,
      ismodifyFalg: "false",
      pageObj: {
        dataList: [],
      },
      tableBtnList: {
        // 操作
        title: this.$i18n.t("label_partner_all_operate"),
        buttonList: [
          createButton({
            label: this.$i18n.t("pagecreator_page_button_edit"), //"编辑",
            action: "edit",
          }),
          createButton({
            label: this.$i18n.t("component_setup_tabs_label_delete"), //"删除",
            action: "del",
          }),
        ],
      },
      showBtnLength: 5, // 相关列表按钮展示个数
      dropbox: false,
      // 文件预览参数
      showPreview: false,
      showPreviewData: {},
      previewUrl: "",
      token: this.$CCDK.CCToken.getToken(),
      isRlation: "Card",
      timer: "",
      timeInterval: "",
      clickBtn: "",
      hoverBtn: "",
      dialogNewAttr: {
        // 新建编辑
        title: "",
        type: "NEW",
        isShow: false,
        dialogWidth: "85%",
        id: "",
      },
      listObjectApi: "",
      relatedListObj: {}, //按点击钮每个菜单数据
      DeletName: "",
      recordType: "",
      listPrefix: "",
      dialogVisible: false,
      pageNum: 1, //页码默认为1
      skippageNum: 1, //页码
      pageSizes: [10, 25, 50, 100, 200], //每页显示个数
      pageSize: 10, // 每页展示数据条数
      totalSize: 0, // 总记录数
      layout: "total, sizes, prev, pager, next, jumper",

      showKanban: false, //看板视图
      dialogAddVisible: false,
      batchAddDialog: false,
      batchAddDialogTitle: "",
      approvalSubmitStatus: true, // 提交待审批状态
      isDropboxState: null, //Dropbox功能是否开启
      viewId: this.$route.query.viewId,
      currentSelectChecked: "ffe20201207task11",
      // 百分比：this.$i18n.t("label.percent")
      // 优先级this.$i18n.t("label.chatter.priority")
      // 里程碑this.$i18n.t("component.milestones.label.title")
      optionss: [
        {
          value: "ffe20201207task09",
          label: this.$i18n.t("label.percent"),
        },
        {
          value: "ffe20201207task10",
          label: this.$i18n.t("label.chatter.priority"),
        },
        {
          value: "ffe20201207task11",
          label: this.$i18n.t("component.milestones.label.title"),
        },
      ],
      // 新建里程碑按钮显示隐藏
      isShowMilepostBtn: true,
      realObjId: "",
    };
  },
  mounted() {
    this.showBtnLength = this.detailLayoutMode === "mode1" ? 5 : 0;
    // 计时器计算多久前更新
    this.timer = setInterval(() => {
      // 获取当前时间
      let currentTime = new Date();
      let interval = currentTime - this.lastRefresh;

      //计算出相差天数
      var days = Math.floor(interval / (24 * 3600 * 1000));
      //计算出小时数
      var leave1 = interval % (24 * 3600 * 1000); //计算天数后剩余的毫秒数
      var hours = Math.floor(leave1 / (3600 * 1000));
      //计算相差分钟数
      var leave2 = leave1 % (3600 * 1000); //计算小时数后剩余的毫秒数
      var minutes = Math.floor(leave2 / (60 * 1000));
      //计算相差秒数
      var leave3 = leave2 % (60 * 1000); //计算分钟数后剩余的毫秒数
      var seconds = Math.round(leave3 / 1000);
      hours = minutes > 30 ? hours + 1 : hours;
      if (days > 0) {
        this.timeInterval =
          hours > 0
            ? `${days} ${this.$i18n.t(
                "label.login.access.day"
              )}${hours} ${this.$i18n.t("label_tabpage_hoursagoz")}`
            : `${days} ${this.$i18n.t("label_tabpage_Sdfez")}`;
      } else if (hours > 0) {
        this.timeInterval = `${hours} ${this.$i18n.t(
          "label_tabpage_hoursagoz"
        )}`;
      } else if (minutes > 0) {
        this.timeInterval = `${minutes} ${this.$i18n.t(
          "label_tabpage_minutesagoz"
        )}`;
      } else {
        this.timeInterval =
          seconds > 43
            ? this.$i18n.t("vue_label_commonobjects_view_secondsbefore", {
                seconds: seconds,
              })
            : this.$i18n.t("label.notification.time1");
      }
    }, 1000);
    // 恢复提交待审批状态
    this.$Bus.$on("recovery-submit-status", this.recoverySubmitStatusFn);
    //提交待审批后局部刷新页面
    this.$Bus.$on("approvalrefresh", this.approvalrefreshFn);
    this.$bus.$on("refreshListFun", this.refreshListFun);
  },
  beforeDestroy() {
    // 恢复提交待审批状态
    this.$Bus.$off("recovery-submit-status", this.recoverySubmitStatusFn);
    //提交待审批后局部刷新页面
    this.$Bus.$off("approvalrefresh", this.approvalrefreshFn);
    this.$bus.$off("refreshListFun", this.refreshListFun);
  },
  created() {
    //三个点显示气泡
    this.activateTooltip = debounce(50, (tooltip) =>
      tooltip.handleShowPopper()
    );
    // eslint-disable-next-line vue/no-mutating-props
    this.detailLayoutMode = VueCookies.get("detailLayoutMode");
    this.getUserInterface();
  },
  computed: {
    pageCount() {
      let { totalSize, pageSize } = this;
      return Math.ceil(totalSize / pageSize);
    },
  },
  methods: {
    approvalrefreshFn() {
      this.$refs.myChlidHistory.getApprovalPreview();
    },
    recoverySubmitStatusFn() {
      this.approvalSubmitStatus = true;
    },
    // 该方法可能不需求
    onchange() {},
    //获取是卡片还是列表
    getUserInterface() {
      CommonObjApi.getUserInterface().then((res) => {
        this.isRlation = res.data.relatedListStyle;
        this.$forceUpdate();
      });
    },

    init() {
      this.skippageNum = 1;
      this.getDetailRelatedItems(this.obj, 1);
    },
    getDetailRelatedItems(everyItem, curPage) {
      this.objectInfo = {};
      let params = {
        recordId: this.id,
        layoutId: everyItem.layoutId ? everyItem.layoutId : "",
        relatedlistId: everyItem.relatedlistId ? everyItem.relatedlistId : "",
        curPage: curPage,
        perPage: this.pageSize,
        attachementType: everyItem.attachementType
          ? everyItem.attachementType
          : "",
      };
      CommonObjApi.GetDetailRelatedItems(params).then((res) => {
        if (res.data.objid === "attachement") {
          this.dropboxState(res.data.dropboxState);
        }
        // 手动添加totalCount属性
        if(res.data && res.data.data){
          if(res.data.data[0] && res.data.data[0].norecord){
            res.data.totalCount = 0
          }else{
            res.data.totalCount = res.data.data.length
          }
        }else{
          res.data.totalCount = 0
        }
        if (res.data !== null && res.data !== undefined) {
          this.getRelationListButton(res.data);
          this.$set(
            res.data,
            "titImg",
            res.data.tabStyle ? res.data.tabStyle : ""
          );
          this.objectInfo = res.data;
          this.objectInfo.loading = true;
          this.pageObj.dataList = this.objectInfo.data;
          if (this.isRlation === "List") {
            this.objectInfo.fieldList.forEach((item, idx) => {
              // 名称、查找、主详、查找多选均可跳转
              if (
                item.name === "name" ||
                item.type === "Y" ||
                item.type === "M" ||
                item.type === "MR" ||
                item.name === "subject" ||
                item.lookupObj === "user" ||
                item.name === "whoid" ||
                item.name === "relateid"
              ) {
                this.$set(this.objectInfo.fieldList[idx], "click", "detail");
              }
              // 项目管理系统-详情-实际工作清单点击日期跳转详情
              if (
                sessionStorage.getItem("activeTabObjId") === "projectworklist"
              ) {
                if (item.type === "D") {
                  this.$set(this.objectInfo.fieldList[idx], "click", "detail");
                }
              }
              if (this.objectInfo.type === "attachement") {
                this.tableBtnList = {
                  // 操作
                  title: this.$i18n.t("label_partner_all_operate"),
                  buttonList: [
                    createButton({
                      label: this.$i18n.t("component_setup_tabs_label_delete"), //"删除",
                      action: "del",
                    }),
                  ],
                };
              }

              this.$set(this.objectInfo.fieldList[idx], "sortByThis", false);
              this.$set(this.objectInfo.fieldList[idx], "sortDir", "asc");
              this.$set(this.objectInfo.fieldList[idx], "fixed", false);
              this.$set(this.objectInfo.fieldList[idx], "locked", false);
              item.schemefieldType = item.type;
              item.schemefieldName = item.name;
              item.schemefieldLength = item.length;
              item.apiname = item.name;
              item.nameLabel = item.label;
            });
            this.pageNum++;
          }
          this.objectInfo.loading = false;
        }
        this.totalSize = res.data.totalCount;
        // this.$emit('getTotalSize',this.totalSize)
      });
    },
    // 获取相关列表按钮
    getRelationListButton(item) {
      if (item.objName === "Attachement") {
        //文件
        if (this.isDropboxState === "1") {
          item.showButton = [
            {
              id: "dropbox",
              label: "", //添加文件
            },
            {
              id: "",
              label: this.$i18n.t("label.file.library.selectFile2Lib"), //添加文件
            },
            {
              id: "",
              label: this.$i18n.t("chatter.down"), //"下载"
            },
          ];
        } else {
          item.showButton = [
            {
              id: "",
              label: this.$i18n.t("label.file.library.selectFile2Lib"), //添加文件
            },
            {
              id: "",
              label: this.$i18n.t("chatter.down"), //"下载"
            },
          ];
        }
      } else {
        item.showButton = [];
        let addBtn = item.buttons.find((btn) => {
          return btn.name === "New";
        });
        let batchAddBtn = item.buttons.find((btn) => {
          return btn.name === "Batch Added";
        });
        addBtn &&
          item.showButton.push({
            id: "",
            label: this.$i18n.t("label.ems.create"), //"新建",
          });
        batchAddBtn &&
          item.showButton.push({
            id: "",
            label: this.$i18n.t("label.batchadd"), //"批量新增",
          });
      }

      // 自定义按钮
      item.buttons.forEach((btn) => {
        //0521先注掉客户下联系人架构图按钮
        if (
          btn.category === "CustomButton" &&
          btn.name !== "Architecture diagram"
        ) {
          item.showButton.push({
            id: btn.id,
            label: btn.label,
            category: btn.category,
            behavior: btn.behavior,
            event: btn.event,
            function_code: btn.function,
          });
        }
      });
    },
    // 相关列表按钮点击方法
    buttonFun(item) {
      // 自定义按钮
      if (
        item.category === "CustomButton" &&
        item.id !== "adc201300153342D9xq4"
      ) {
        // lightning版本自定义按钮 调用特定方法执行服务
        if (item.event === "lightning") {
          // 替换id
          if (item.function_code.indexOf("{!id}") !== -1) {
            item.function_code = item.function_code.replace("{!id}", this.id);
          }
          if (item.function_code.indexOf("{!binding}") !== -1) {
            item.function_code = item.function_code.replace(
              "{!binding}",
              this.$CCDK.CCToken.getToken()
            );
          }
          let param = JSON.parse(item.function_code);
          param.id = this.id;
          param.token = this.$CCDK.CCToken.getToken();
          let loadingInstance = Loading.service(this.loadingOption);
          CommonObjApi.openCall(param).then((res) => {
            loadingInstance.close();
            if (res.data && res.data.action) {
              // 刷新页面
              if (res.data.action === "refresh") {
                this.refreshAll();
              } else if (res.data.action === "redirect") {
                // 重定向 需判断当前窗口打开还是新窗口打开
                if (res.data.mode === "new") {
                  window.open(res.data.url);
                } else if (res.data.mode === "now") {
                  window.location.href = res.data.url;
                }
              } else if (res.data.action === "alert") {
                // 不同类型的消息提示   wj
                this.$message({
                  message: res.data.message,
                  type: res.data.messageType || "info",
                });
              }
              //先弹窗，在当前页面跳转
              else if (res.data.action === "alert_refresh") {
                // 不同类型的消息提示   wj
                this.$message({
                  message: res.data.message,
                  type: res.data.messageType || "info",
                });
                setTimeout(() => {
                  this.refreshAll();
                }, 2000);
              }
              //先弹窗,在新标签页跳转
              else if (res.data.action === "alert_redirect") {
                // 不同类型的消息提示   wj
                this.$message({
                  message: res.data.message,
                  type: res.data.messageType || "info",
                });
                setTimeout(() => {
                  // 重定向 需判断当前窗口打开还是新窗口打开
                  if (res.data.mode === "new") {
                    window.open(res.data.url);
                  } else if (res.data.mode === "now") {
                    window.location.href = res.data.url;
                  }
                }, 2000);
              }
            }
          });
        }
        // 自定义按钮lightning-script
        if(item.event === 'lightning-script' ){
          let funText = item.function_code ? item.function_code : item.functionCode
            let newFun = Function(funText)
            newFun()
          }
        if (item.event === "lightning-url") {
          // 替换id
          if (item.function_code.indexOf("{!id}") !== -1) {
            item.function_code = item.function_code.replace("{!id}", this.id);
          }
          // 当前窗口打开
          if (item.behavior === "self") {
            // window.location.href = item.function_code
            if (item.function_code.substring(0, 7) == "cloudcc") {
              let searchUrl = item.function_code.split("?")[1];
              let id = item.function_code
                .split("?")[1]
                .split("&")[0]
                .split("=")[1];
              let page = item.function_code
                .split("?")[1]
                .split("&")[1]
                .split("=")[1];
              let routeData = this.$router.resolve({
                path: "/injectionComponent1",
                query: { id: id, page: page },
              });
              if (item.function_code.indexOf("target") !== -1) {
                //在本页面打开一个新的窗口
                let paramsObj = this.$qs.parse(searchUrl); // 获取从？开始的url部分
                if (paramsObj.target.indexOf("dialog") !== -1) {
                  let obj = {
                    title: paramsObj.dialogTitle || "详情",
                    width: paramsObj.dialogWidth || "50%",
                    height: paramsObj.dialogHeight || "",
                    pageApi: page,
                  };
                  this.$bus.$emit("openDialog", obj);
                } else if (paramsObj.target.indexOf("window") !== -1) {
                  window.open(
                    routeData.href,
                    "newWindow",
                    "width=800,height=600,left=400,top=100,menubar=0,scrollbars=1, resizable=1,status=1,titlebar=0,toolbar=0,location=1"
                  );
                }
              } else {
                // 覆盖
                window.open(routeData.href, "_self");
              }
            } else {
              window.location.href = item.function_code;
            }
          } else {
            // 新窗口打开
            // window.open(item.function_code)
            // 新窗口打开
            if (item.function_code.substring(0, 7) == "cloudcc") {
              let id = item.function_code
                .split("?")[1]
                .split("&")[0]
                .split("=")[1];
              let page = item.function_code
                .split("?")[1]
                .split("&")[1]
                .split("=")[1];
              let routeData = this.$router.resolve({
                path: "/injectionComponent1",
                query: { id: id, page: page },
              });
              window.open(routeData.href, "_blank");
            } else {
              window.open(item.function_code);
            }
          }
        } else if (
          item.event === "JavaScript" ||
          (item.event === "URL" && item.id !== "adc201300153342D9xq4")
        ) {
          this.$message.warning(this.$i18n.t("lable.Please.switch"));
        }
      } else {
        this.standardListFun(item, this.objectInfo);
      }
    },
    //标准相关列表按钮

    standardListFun(n, objectInfo) {
      //相关列表每个对象的信息
      this.relatedListObj = objectInfo;
      this.DeletName = objectInfo.objName;
      if (this.obj !== undefined) {
        this.$set(
          this.relatedListObj,
          "relationFieldId",
          this.obj.relationFieldId
        );
        this.$set(this.relatedListObj, "relatedListFlag", true);
      }
      if (
        objectInfo.relatedlistType === "approvalHistory" &&
        n.label === this.$i18n.t("label.manageAll.sta4")
      ) {
        //调回批准请求
        this.openApproval(1, this.detailGroupId, this.id);
      } else if (
        objectInfo.relatedlistType === "approvalHistory" &&
        n.label === this.$i18n.t("label.submitforapproval")
      ) {
        //提交待审批
        this.openApproval(0, this.detailGroupId, this.id);
      } else if (
        objectInfo.relatedlistType === "approvalHistory" &&
        n.label === this.$i18n.t("label.approveorrefuse")
      ) {
        //进行审批
        this.openApproval(2, this.detailGroupId, this.id);
      } else if (
        objectInfo.relatedlistType === "approvalHistory" &&
        n.label === this.$i18n.t("label.redistribution")
      ) {
        //"重新分配"
        this.openApproval(3, this.detailGroupId, this.id);
      } else if (
        objectInfo.objName === "Attachement" &&
        n.label === this.$i18n.t("label.file.library.selectFile2Lib")
      ) {
        //添加文件
        this.$refs.atoLib.initPage();
        this.dialogAddVisible = true;
      } else if (
        objectInfo.objName === "Attachement" &&
        n.label === this.$i18n.t("chatter.down")
      ) {
        //"下载"
        this.attachBtn(objectInfo);
      } else if (n.label === this.$i18n.t("label.ems.create")) {
        //"新建"
        this.recordType = "";
        this.dialogNewAttr.id = "";
        this.listObjectApi = objectInfo.objName;
        this.listPrefix = objectInfo.prefix;
        this.$nextTick(() => {
          this.$refs.createEditObj.add();
        });
      } else if (n.label === this.$i18n.t("label.batchadd")) {
        //批量新增
        this.listPrefix = objectInfo.prefix;
        this.listObjectApi = objectInfo.objName;
        this.batchAddDialog = true;
        this.$nextTick(() => {
          this.$refs.batchAdd && this.$refs.batchAdd.init();
          this.$refs.batchAdd && this.$refs.batchAdd.getRecordType();
        });
        this.batchAddDialogTitle = this.$i18n.t("label.object.batchadd", {
          objName: objectInfo.objLabel,
        });
      }
    },
    // 审批操作项
    openApproval(i, detailGroupId, recordId) {
      if (i == 0) {
        if (this.approvalSubmitStatus) {
          this.approvalSubmitStatus = false;
          this.$refs.myChlidHistory.parentClickHistory(
            i,
            detailGroupId,
            recordId
          );
        } else {
          // this.$message.warning('等一下')
        }
      } else {
        this.$refs.myChlidHistory.parentClickHistory(
          i,
          detailGroupId,
          recordId
        );
      }
    },
    // 跳转对应的详情页
    detailBtn(param) {
      if (param.fileId) {
        this.openPreview(param.fileId);
      } else {
        if (param.attachtype === "dropbox") {
          let tempwindow = window.open("_blank");
          tempwindow.location = param.url;
        } else {
          if (this.objectInfo.objName === "Attachement") {
            let baseURL = this.$baseConfig.baseURL;
            let token = this.$CCDK.CCToken.getToken();
            var link = document.createElement("a");
            link.setAttribute("download", "");
            link.href = `${baseURL}/file/downloadAttachement?accessToken=${token}&id=${param.id}`;
            link.click();
          } else if (this.objectInfo.objName === "tp_sys_contactrole") {
            this.$router.push({
              path: `/commonObjects/detail/${param.contact}/DETAIL`,
              query: {
                viewId: this.viewId,
              },
            });
          } else {
            this.$router.push({
              path: `/commonObjects/detail/${param.id}/DETAIL`,
              query: {
                viewId: this.viewId,
              },
            });
          }
        }
      }
    },
    // 打开预览

    openPreview(item) {
      this.showPreview = true;
      this.showPreviewData = {
        name: "pdf",
        type: "pdf",
        id: item,
      };
      let arr = [];
      this.$refs.previewFile.handleBtn(arr);
    },
    // 关闭预览
    closePreview() {
      this.showPreview = false;
    },
    // 编辑
    everyEdit(param) {
      // this.$emit('everyEdit', this.objectInfo, param)
      this.relatedListObj.objLabel = this.objectInfo.objLabel;
      this.listObjectApi = this.objectInfo.objName;
      this.listPrefix = this.objectInfo.prefix;
      //name.action是列表式编辑
      if (param.action) {
        this.dialogNewAttr.id = param.data.id;
        this.$nextTick(() => {
          this.$refs.createEditObj.edit();
        });
      } else {
        CommonObjApi.getPermissionById({ id: param.id }).then((res) => {
          if (res.data.isEdit) {
            this.dialogNewAttr.id = param.id;
            this.$nextTick(() => {
              this.$refs.createEditObj.edit();
            });
          } else {
            this.$message({
              showClose: true,
              message: this.$i18n.t("report_label_norole"),
              type: "warning",
            });
          }
        });
      }
    },
    // 删除
    everyDelet(objName, id, item1) {
      //每个相关列表对象的信息
      this.relatedListObj = this.objectInfo;
      //name.action是列表式删除
      if (objName.action) {
        this.DeletName = this.objectInfo.objName;
        this.DeletObj = this.objectInfo;
        this.DeletId = objName.data.id;
        this.dialogVisible = true;
      } else {
        this.DeletObj = this.objectInfo;
        this.DeletName = objName;
        this.DeletId = id;
        this.DeletItem1 = item1;
        if (
          this.$store.state.userInfoObj.profileId === "aaa000001" ||
          objName == "Attachement"
        ) {
          this.dialogVisible = true;
        } else {
          CommonObjApi.getPermissionById({ id: this.DeletId }).then((res) => {
            if (res.data.isDelete && !res.data.isLocked) {
              this.dialogVisible = true;
            } else {
              this.$message(
                this.$i18n.t("vue_label_commonobjects_detail_the_administrator")
              );
            }
          });
        }
      }
    },
    // 确认删除数据
    confirmDel() {
      CommonObjApi.del({ id: this.DeletId, objectApi: this.DeletName }).then(
        () => {
          this.dialogVisible = false;
          this.$message({
            showClose: true,
            message: this.$i18n.t("label.ems.delete.success"),
            type: "success",
          }); //删除成功
          this.refreshListFun();
        }
      );
    },
    showHistoryBtn(data) {
      this.objectInfo.showButton = [];
      if (data.showSubmit) {
        this.objectInfo.showButton.push({
          id: "",
          label: this.$i18n.t("label.submitforapproval"),
          hisId: "0",
        }); //"提交待审批"
      }
      if (data.showApproval) {
        this.objectInfo.showButton.push({
          id: "",
          label: this.$i18n.t("label.approveorrefuse"),
          hisId: "2",
        }); //进行审批
      }
      if (data.showReassign) {
        this.objectInfo.showButton.push({
          id: "",
          label: this.$i18n.t("label.redistribution"),
          hisId: "3",
        }); //重新分配
      }
      if (data.showRecall) {
        this.objectInfo.showButton.push({
          id: "",
          label: this.$i18n.t("label.manageAll.sta4"),
          hisId: "1",
        }); //调回批准请求
      }
      this.$emit("historyBtn", data);
    },

    handleHistory(i, detailGroupId, recordId) {
      this.$refs.myChlidHistory.parentClickHistory(i, detailGroupId, recordId);
    },
    fileChange() {},
    // 文件上传成功回调函数
    success() {},
    // 文件上传失败回调函数
    error() {},
    //获取上传成功信息
    UploadSuccess(item) {
      let data = [];
      let dataItem = {};
      dataItem.recordid = this.id;
      dataItem.name = item.name;
      dataItem.type = item.type;
      dataItem.fileContentId = item.fileContentId;
      dataItem.fileinfoid = item.fileinfoid;
      dataItem.filesize = item.filesize;
      data.push(dataItem);
      CommonObjApi.Bind(JSON.stringify(data)).then((res) => {
        let self = this;
        if (res.result === true) {
          //群策卡片
          for (let i = 0; i < res.data.length; i++) {
            if (res.data[i].cardValue) {
              (function (i) {
                setTimeout(async () => {
                  let fieldsList = [];
                  res.data[i].cardValue.fieldsList.forEach((item) => {
                    if (
                      fieldsList.length < 5 &&
                      item.fieldValue != null &&
                      item.fieldValue != "true" &&
                      item.fieldValue != "false" &&
                      item.fieldValue != "0" &&
                      item.fieldValue != "1"
                    ) {
                      fieldsList.push(item);
                    }
                  });
                  await detailSendCard(res.data[i].cardValue.groupId, {
                    title: self.$i18n.t("vue_label_newfile"),
                    recordId: res.data[i].cardValue.id,
                    type: "NORMAL_CARD",
                    body: fieldsList,
                    changer: localStorage.getItem("username"),
                  });
                }, 500 * i);
              })(i);
            }
          }
          this.$message({
            showClose: true,
            type: "success",
            message: this.$i18n.t("vue_label_notice_add_success"),
          });
        } else {
          this.$message({
            showClose: true,
            type: "error",
            message: this.$i18n.t("vue_label_notice_add_fail"),
          });
        }
      });
      this.$parent.$parent.uploadRefresh();
    },
    //a链接禁止跳转
    disBtn(index) {
      let cityNode = document.getElementsByClassName("dis_a");
      let cityLiNodes = cityNode[index].getElementsByTagName("a")[0];
      if (cityLiNodes) {
        cityLiNodes.setAttribute("href", "javascript:;");
        cityLiNodes.style.disabled = "true";
        //悬浮a禁止
        let cityNodeTooltip = document
          .getElementsByClassName("dis_b")[0]
          .getElementsByTagName("a")[0];
        cityNodeTooltip && cityNodeTooltip.setAttribute("href", "javascript:;");
        cityNodeTooltip.style.disabled = "true";
      }
    },
    // 鼠标移入
    handleCellMouseEnter(event) {
      const target = event.target;
      // 判断是否text-overflow, 如果是就显示tooltip
      let child = target.querySelector(".topicStyle");
      // 如果区域宽度被限定，则通过高度判断
      let heightFlag = child.scrollHeight > child.offsetHeight;
      const range = document.createRange();
      range.setStart(child, 0);
      range.setEnd(child, child.childNodes.length);
      const rangeWidth = range.getBoundingClientRect().width; // 文本区域宽度
      const padding =
        (parseInt(getStyle(target, "paddingLeft"), 10) || 0) +
        (parseInt(getStyle(target, "paddingRight"), 10) || 0);
      if (
        rangeWidth + padding > target.offsetWidth ||
        child.scrollWidth > child.offsetWidth ||
        (heightFlag && this.$refs.tooltip)
      ) {
        const tooltip = this.$refs.tooltip;
        // TODO 会引起整个 Table 的重新渲染，需要优化
        this.tooltipContent = target.innerText || target.textContent;
        tooltip.referenceElm = target;
        tooltip.$refs.popper && (tooltip.$refs.popper.style.display = "none");
        tooltip.doDestroy();
        tooltip.setExpectedState(true);
        this.activateTooltip(tooltip);
      }
    },

    // 鼠标移出
    handleCellMouseLeave() {
      const tooltip = this.$refs.tooltip;
      if (tooltip) {
        tooltip.setExpectedState(false);
        tooltip.handleClosePopper();
      }
    },
    // 关闭弹窗
    handleClose() {
      this.dialogVisible = false;
    },
    onCancel() {},
    onPicked(files) {
      if (files.length > 1) {
        this.$message.info("最多只能选择一个");
      } else {
        let obj = {};
        files.forEach((file) => {
          let str1 = file.title.substring(0, file.title.indexOf("."));
          obj = {
            name: str1,
            recordId: this.id,
            url: file.link,
          };
          this.getSaveDropboxs(obj);
        });
      }
    },
    dropboxPicker() {
      this.dropbox = true;
    },

    async getSaveDropboxs(params) {
      let res = await CommonObjApi.getSaveDropbox(params);
      if (res.result) {
        this.getDetailRelatedItems(this.obj, 1);
      }
    },
    //跳转到记录详情
    jumpDetail(item) {
      this.itemHref = item;
      if (item.objid === "lightningreport") {
        // 属于报表
        this.$router.push({
          path: `/reportObject/editReport/run/${item.recordid}`,
          query: {},
        });
      } else if (item.objid === "dashboardlightning") {
        // 属于仪表板
        this.$router.push({
          path: `/dashboardObject/dashboardIndex/${item.recordid}`,
          query: {},
        });
      } else if (item.objid === "file") {
        //属于文件
        this.$router.push({
          path: "/ccFileCenter/fileDetail",
          query: {
            id: item.recordid,
          },
        });
      } else {
        // 属于通用对象
        this.$router.push({
          path: `/commonObjects/detail/${item.id}/DETAIL`,
        });
      }
    },
    imgSrc(id) {
      return `${this.$cookies.get(
        "domainName"
      )}/querysome.action?m=viewImg&fileid=${id}&binding=${this.token}`;
    },
    // 刷新
    refresh() {
      this.lastRefresh = new Date();
      // 重置列表信息
      this.pageObj.dataList = [];
      this.pageNum = 2;
      this.getDetailRelatedItems(this.obj, 1);
      // 重置列表选中统计数据
      this.$refs.tablePanel.checkedList = [];
    },
    // 处理按钮点击事件
    handleClick(action) {
      // 导出、打印按钮点击后不用显示蓝色
      this.clickBtn = action;
      //切换菜单看板视图隐藏
      this.$bus.$on("noneKanban", (kanban) => {
        if (kanban === false) {
          this.showKanban = false;
          this.showlist = true;
          this.hoverBtn = "";
          this.clickBtn = "";
        }
      });
      if (this.clickBtn === "boardView") {
        this.showKanban = true;
        this.showlist = false;
      } else if (this.clickBtn === "tableView") {
        this.showKanban = false;
        this.showlist = true;
      }
    },
    mouseLeaveBtn(btn) {
      this.hoverBtn = this.clickBtn === btn ? btn : "";
    },
    mouseOverBtn(btn) {
      this.hoverBtn = btn;
    },
    // 新增/修改保存
    save() {
      this.refreshListFun();
      this.getlist();
    },
    // 显示页数发生变化时
    handleSizeChange(val) {
      this.pageSize = val;
      // this.$store.commit('setPageSize', val) // 存储每页条数
      // this.backNumOne()
      this.getDetailRelatedItems(this.obj, 1);
    },
    // 当前页码发生变化时
    handleCurrentChange(val) {
      this.skippageNum = val;
      this.getDetailRelatedItems(this.obj, val);
      // this.getViewListData(val)
      // this.$refs.tablePanel.$refs.tablePanel.bodyWrapper.scrollTop = 0
    },
    //点击回到首页按钮
    goHomePage() {
      this.skippageNum = 1;
      this.getDetailRelatedItems(this.obj, 1);
    },
    addWorkSubmit(checkedFileList) {
      let data = [];
      checkedFileList.map((item) => {
        let dataItem = {};
        dataItem.recordid = this.id;
        dataItem.name = item.name;
        dataItem.type = item.fileType ? item.fileType : item.type;
        dataItem.fileContentId = item.fileContentId || item.id; //下载文件2021开头的是filecontentid
        dataItem.fileinfoid = item.fileInfoId || item.id; // fil开头的是fileinfoid
        dataItem.filesize = item.filesize;
        data.push(dataItem);
      });
      CommonObjApi.Bind(JSON.stringify(data)).then((res) => {
        let self = this;
        if (res.result === true) {
          //群策卡片
          for (let i = 0; i < res.data.length; i++) {
            if (res.data[i].cardValue) {
              (function (i) {
                setTimeout(async () => {
                  let fieldsList = [];
                  res.data[i].cardValue.fieldsList.forEach((item) => {
                    if (
                      fieldsList.length < 5 &&
                      item.fieldValue != null &&
                      item.fieldValue != "true" &&
                      item.fieldValue != "false" &&
                      item.fieldValue != "0" &&
                      item.fieldName != "id" &&
                      item.fieldValue != "1"
                    ) {
                      fieldsList.push(item);
                    }
                  });
                  await detailSendCard(res.data[i].cardValue.groupId, {
                    title: self.$i18n.t("vue_label_newfile"),
                    recordId: res.data[i].cardValue.id,
                    type: "NORMAL_CARD",
                    body: fieldsList,
                    changer: localStorage.getItem("username"),
                  });
                }, 500 * i);
              })(i);
            }
          }
          this.$message({
            showClose: true,
            type: "success",
            message: this.$i18n.t("vue_label_notice_add_success"),
          });
        } else {
          this.$message({
            showClose: true,
            type: "error",
            message: this.$i18n.t("vue_label_notice_add_fail"),
          });
        }
      });
      this.addWorkClose(true);
    },
    //添加文件
    addWorkClose(n) {
      if (n) {
        this.refreshListFun();
        this.dialogAddVisible = false;
      } else {
        this.dialogAddVisible = false;
      }
    },
    //下载文件
    attachBtn(arr) {
      if (arr.data.length > 0) {
        let excelList = [];
        arr.data.forEach((val) => {
          if (val.attachChecked && val.attachChecked === true) {
            let baseURL = this.$baseConfig.baseURL;
            let token = this.$CCDK.CCToken.getToken();
            excelList.push(
              `${baseURL}/file/downloadAttachement?accessToken=${token}&id=${val.id}`
            );
          }
        });
        //判断如果都没有选中
        let checked = arr.data.every((item) => {
          return item.attachChecked === undefined;
        });
        //都没有选中的时候提示
        if (checked === true) {
          this.$message({
            showClose: true,
            message: this.$i18n.t("label.unselectfile"),
            type: "warning",
          });
        }
        for (let i = 0; i < excelList.length; i++) {
          const iframe = document.createElement("iframe");
          iframe.style.display = "none"; // 防止影响页面
          iframe.style.height = 0; // 防止影响页面
          iframe.src = excelList[i];
          document.body.appendChild(iframe); // 这一行必须，iframe挂在到dom树上才会发请求
          // 5分钟之后删除
          setTimeout(() => {
            iframe.remove();
          }, 5 * 60 * 1000);
        }
        this.$nextTick(() => {
          this.getDetailRelatedItems(this.obj, 1);
        });
      } else {
        this.$message({
          showClose: true,
          message: this.$i18n.t("label.unselectfile"),
          type: "warning",
        });
        return false;
      }
    },
    //批量新增
    closeBatchAddDialog(isSave) {
      this.batchAddDialog = false;
      if (isSave === "save") {
        this.refreshListFun();
      }
    },
    //批量新增保存
    saveBatchAdd() {
      this.$refs.batchAdd.savedata();
    },
    //刷新相关列表菜单及列表
    refreshListFun() {
      this.$parent.$parent.$refs.listChild.init();
      this.getDetailRelatedItems(this.obj, this.skippageNum);
    },
    dropboxState(val) {
      this.isDropboxState = val;
    },
    // 看板任务下拉框切换请求
    getKanBanData(val) {
      if (val === "ffe20201207task09" || val === "ffe20201207task10") {
        this.isShowMilepostBtn = false;
        this.callSubcomponents(val, this.id, "login");
      } else {
        this.callSubcomponents("kanbanTaskPage", this.id, "ffe20201207task11");
        this.isShowMilepostBtn = true;
      }
      var a = val; //声明个变量存储下数据
      localStorage.setItem("key", a); //将变量imgs存储到name字段
    },
    // 看板子组件传参请求
    childfunc(val) {
      this.currentSelectChecked = val;
    },

    callSubcomponents(valid, id, parameter) {
      this.$refs.changeImplement.getKanBanView(valid, id, parameter);
    },
    //获取页面数据

    getlist() {
      let params = {
        parentId: this.id,
      };
      CommonObjApi.getPIdMilestone(params).then((res) => {
        for (let i in res.data.tasks.data) {
          res.data.tasks.data[i].Nextid = res.data.tasks.data[i].progress * 100;
          if (res.data.tasks.data[i].end_date) {
            res.data.tasks.data[i].Nextname = res.data.tasks.data[
              i
            ].end_date.substring(0, 10);
            res.data.tasks.data[i].end_date = this.getNextDate(
              res.data.tasks.data[i].end_date,
              1
            );
          } else {
            res.data.tasks.data[i].Nextname = "";
          }
        }
        this.tasks.data = res.data.tasks.data;
        this.tasks.links = res.data.tasks.links;
      });
    },
    // 看板任务新建任务按钮
    newMilestoneButton() {
      this.relativeRecordId = this.$route.query.proId
        ? this.$route.query.proId
        : sessionStorage.getItem("selectproject");
      //
      this.relatedListObj.relationFieldId = "ffe2020Milestone01";
      this.listObjectApi = "cloudccMilestone";
      this.realObjId = "projectworklist";
      this.tabName = this.$i18n.t("component.milestones.label.title"); // 里程碑this.$i18n.t("component.milestones.label.title")
      this.listPrefix = "p02";
      this.$nextTick(() => {
        this.$refs.createEditObj.add();
      });
    },
    toUpdate() {
      this.$nextTick(() => {
        this.$refs.changeImplement.getKanBanView("lichengbei", this.id);
      });
    },
  },
  watch: {
    detailLayoutMode(nval) {
      this.showBtnLength = nval === "mode1" ? 5 : 0;
    },
    isRlation(nval) {
      if (nval !== "Card") {
        this.$nextTick(() => {
          this.$refs.tablePanel &&
            this.$refs.tablePanel.changeTableAttr(this.objectInfo.fieldList);
        });
      }
    },
  },
};
</script>

<style scoped lang="scss">
.kanbanBox {
  height: 500px;
}
.totip {
  display: inline-block;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  width: 100%;
  font-size: 12px;
}

p {
  margin-bottom: 0 !important;
}

.tit {
  display: flex;
  width: 100%;
  justify-content: space-between;
  padding: 10px 10px;
  box-sizing: border-box;
  align-items: center;
  background: #f5f5f5;
  .box_info {
    .statistics {
      margin-top: 5px;
    }
    .upload-demo {
      width: 100%;
      padding: 10px;
      box-sizing: border-box;
      .el-upload .el-upload--text {
        width: 100%;
        .el-upload-dragger {
          width: 100%;
        }
      }
    }
    .tit_s {
      display: flex;
      p {
        margin: 0;
        //   float: right;
        height: 20px;
        font-size: 14px;
        color: #080707;
        line-height: 20px;
      }
      .hover_p:hover {
        border-bottom: 1px solid #006dcc;
        cursor: pointer;
        color: #006dcc;
      }
    }
  }
  .tit_right {
    display: flex;
    align-items: center;
    text-align: right;
    .fixed_btn {
      display: flex;
      align-items: center;
      margin-left: 10px;
      ::v-deep .el-select {
        width: 95px;
        margin-left: 10px;
      }
      ::v-deep .el-input--suffix .el-input__inner {
        border: 0px;
      }
      ::v-deep .el-select .el-input .el-select__caret::before {
        content: "";
        width: 0;
        height: 0;
        border: 6px solid #fff;
        border-top-color: transparent;
        border-left-color: transparent;
        border-right-color: transparent;
        position: relative;
        top: -12px;
      }
      ::v-deep .el-select .el-input__inner {
        font-size: 12px;
        color: #fff;
        height: 28px;
        background: #005fb2;
      }
      ::v-deep .el-input__icon {
        line-height: 32px;
      }
    }
  }
  .tubiao_box {
    margin-right: 10px;
    .el-button {
      width: 30px;
      padding: 4px;
      svg {
        width: 20px;
        height: 16px;
      }
    }
  }
  .xiala {
    width: 28px;
    height: 28px;
    float: right;
    text-align: center;
    position: relative;
    img {
      cursor: pointer;
    }
    li {
      cursor: pointer;
    }
  }
  .button {
    height: 30px;
    text-align: center;
    padding: 0 10px;
    color: #006dcc;
    border: 1px solid #dedcda;
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
    &:hover {
      background: #fff;
      border: 1px solid #dedcda;
    }
  }
}

.cont_table {
  padding: 10px 0 0 10px;
  box-sizing: border-box;
  .cont {
    width: 32.7%;
    margin-right: 0.6%;
    padding: 6px;
    box-sizing: border-box;
    margin-bottom: 10px;
    border-radius: 3px;
    border: 1px solid #dddbda;
    float: left;
    .title {
      display: flex;
      width: 100%;
      justify-content: space-between;
      // min-height: 30px;
      word-break: break-all;
      p {
        min-height: 20px;
        margin: 0;
        font-size: 12px;
        color: #006dcc;
        font-weight: bold;
        cursor: pointer;
      }
    }
    .cont_div {
      width: 50%;
      display: flex;
      width: 100%;
      .list_left {
        margin-bottom: 0;
        margin-top: 0;
        margin-left: 0;
        width: 100%;
        li {
          font-size: 12px;
          min-height: 16px;
          display: flex;
          .dis_a {
            ::v-deep a {
              color: #080707 !important;
              cursor: default;
            }
          }
          .font_li {
            padding-left: 20px;
            font-size: 14px;
            color: #080707;
          }
          .over_p .font_li {
            padding-left: 0;
          }
          span:first-child {
            margin-right: 30px;
          }
          .fieldName {
            margin-right: 10px;
            width: 120px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            margin: 0;
            font-size: 12px;
            color: #3e3e3c;
            line-height: 18px;
          }
          //   .over_p {
          //     max-width: 75%;
          //     overflow: hidden;
          //     text-overflow: ellipsis;
          //     white-space: nowrap;
          //     font-size: 12px;
          //     color: #080707;
          //     // padding-left: 20px;
          //   }
          ::v-deep .work_check {
            .el-checkbox__input {
              top: 0;
            }
          }
          .cannotEdit {
            width: 70%;
            text-align: center;
            img {
              width: 100%;
            }
            // 评分字段显示
            .stars {
              list-style: none;
              margin: 0;
              color: #ccc;
              word-break: break-all;
              width: 70%;
            }

            .stars span {
              font-size: 20px;
              margin-left: 3px;
            }

            .stars span:after {
              content: "☆";
            }

            .stars .show:after,
            .stars .show2:after {
              content: "★";
              color: rgb(247, 186, 42);
            }
          }
        }
      }
    }
  }
  .cont:last-child {
    margin-bottom: 50px;
  }
}
.control-panel-block {
  background: #fff;
  width: calc(100% - 465px);
  height: 50px;
  display: flex;
  align-items: center;
  padding-left: 20px;
  position: fixed;
  bottom: 10px;
  border-top: 1px solid #dedcda;
  z-index: 1;
}
.block {
  background: #fff;
  width: 100%;
  height: 50px;
  display: flex;
  align-items: center;
  padding-left: 20px;
  position: fixed;
  bottom: 10px;
  border-top: 1px solid #dedcda;
  z-index: 1;
}
//列表样式
.text-center {
  ::v-deep .el-table--border {
    border-top: 1px solid #dedcda !important;
    border-bottom: 0 !important;
    border-right: 0 !important;
    border-left: 0 !important;
  }
  ::v-deep .hover-row > td {
    background-color: #ffffff;
  }
  ::v-deep .el-popover__reference {
    width: 20px !important;
    border-radius: 3px !important;
  }
  // ::v-deep .el-table tr:last-child {
  //   td {
  //     border-bottom: 0 !important;
  //   }
  // }
  ::v-deep .el-table__header tr th {
    background: #fafaf9;
  }
  ::v-deep .el-table th.is-leaf {
    color: #666666;
  }
  ::v-deep .tableHeadField span,
  ::v-deep .cannotEdit,
  ::v-deep td a span,
  ::v-deep td a,
  ::v-deep th .cell {
    // font-size: 12px;
  }
}
.task_page {
  width: 100%;
}
.knowOwnpop {
  ::v-deep .el-popover__reference {
    margin-bottom: 0;
  }
}
.text-centerKnow {
  ::v-deep .el-table--border {
    border-top: 1px solid #dedcda !important;
    border-bottom: 0 !important;
    border-right: 0 !important;
    border-left: 0 !important;
  }
  ::v-deep .el-table__header-wrapper {
    // border-top: 1px solid #dedcda !important;
  }
  ::v-deep .hover-row > td {
    background-color: #ffffff;
  }

  ::v-deep .el-popover__reference {
    margin-bottom: 0;
    border-radius: 3px !important;
  }
  ::v-deep .el-table tr:last-child {
    td {
      border-bottom: 0 !important;
    }
  }
  ::v-deep .el-table__header tr th {
    background: #fafaf9;
  }
  ::v-deep .el-table th.is-leaf {
    color: #666666;
  }
  ::v-deep .tableHeadField span,
  ::v-deep .cannotEdit,
  ::v-deep td a span,
  ::v-deep td a,
  ::v-deep th .cell {
    // font-size: 12px;
  }
}
.pricebook_box {
  border: 0;
  tr {
    border-top: 1px solid #dddbda;
    th,
    td {
      padding: 11px 14px;
      font-size: 12px;
    }
    th {
      border-right: 1px solid #dedcda;
      background: #fafaf9;
      color: #666666;
    }
    .price_td {
      text-align: center;
      width: 80px;
      span {
        padding-right: 20px;
        display: inline-block;
      }
    }
  }
}

.filedname {
  margin-left: 9px;
  margin-bottom: 10px;
}
.filednametitle {
  display: inline-block;
  width: 140px;
  font-size: 12px;
  color: #3e3e3c;
}

.see_more {
  border-top: 1px solid #ccc;
  height: 44px;
  line-height: 44px;
  text-align: center;
  span {
    font-size: 12px;
    font-weight: 400;
    color: #006dcc;
    cursor: pointer;
  }
}
::v-deep .dropbox {
  width: 38px;
}
</style>
<style scoped>
.my-tooltip .dis_b ::v-deep a {
  color: #fff;
  cursor: default;
}
.xiala_s {
  width: 20px;
  height: 20px;
  border-radius: 3px;
  border: 1px solid #ccc;
  text-align: center;
  cursor: pointer;
}
.list_xiala_s {
  width: 30px;
  height: 30px;
  text-align: center;
  cursor: pointer;
  padding: 0;
}
.list_xiala_s svg {
  width: 15px;
}
.list_xiala_s:hover {
  background: #fff;
  border: 1px solid #dedcda;
}
.xiala_s svg {
  cursor: pointer;
  width: 12px;
  margin-top: -2px;
}

.pop_box .moreButton,
.pop_small .moreButton {
  margin: 0 -12px;
  min-height: 26px;
}

.pop_box .moreButton li,
.pop_small .moreButton li {
  min-height: 26px;
  padding: 0 10px !important;
  font-size: 12px;
  padding: 0;
  line-height: 26px;
}

.pop_box .moreButton li:hover,
.pop_small .moreButton li:hover {
  background: #f3f2f2;
  color: #006dcc;
}

.upload-demo {
  width: 100%;
  padding: 10px;
  box-sizing: border-box;
}
.upload-demo ::v-deep .el-upload {
  width: 100% !important;
  text-align: left !important;
}
.upload-demo ::v-deep .el-upload-dragger {
  width: 100% !important;
}
.el-button-group > .el-button:not(:first-child):not(:last-child) {
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
}
</style>
