<!--
 * @Author: 张亚欣
 * @Date: 2021-10-11 18:46:22
 * @LastEditTime: 2022-02-17 14:40:24
 * @LastEditors: Please set LastEditors
 * @Description: 项目360,包括详细信息、活动、动态
 * @FilePath: \lightning-web\src\views\commonObjects\project\project360.vue
-->
<template>
  <div class="main">
    <!-- 详细信息-->
    <transition name="detail">
      <div v-if="showDetail && showPart === 'detail'">
        <!-- 骨架屏 -->
        <div v-if="loadingDetail">
          <Wang-skeleton
            type="custom"
            :childrenOption="detailSkeletonOption"
            v-for="i in (0, 8)"
            :key="i"
          >
          </Wang-skeleton>
        </div>
        <!-- 显示时的表单信息 -->
        <info-form
          ref="infoForm"
          v-if="!loadingDetail && !editableCell && isBottom == true"
          :objId="objId"
          :operation="operation"
          :option-list="optionList"
          :inlineedit="inlineedit"
          :is-edit="isEdit"
          :can-edit-owner="canEditOwner"
          :form-attr="formAttr"
          :label-position="labelPosition"
          :label-width="labelWidth"
          :columnNum="
            position === 'left'
              ? detailLayoutMode === 'mode1'
                ? columnNum
                : 2
              : columnNum
          "
          :oneColumnWidth="
            position === 'left'
              ? detailLayoutMode === 'mode1'
                ? oneColumnWidth
                : '100%'
              : oneColumnWidth
          "
          :twoColumnWidth="
            position === 'left'
              ? detailLayoutMode === 'mode1'
                ? twoColumnWidth
                : '50%'
              : twoColumnWidth
          "
          :status-icon="statusIcon"
          :objectApi="objectApi"
          @changeSelect="changeSelect"
          @control="control"
          @lookupValue="lookupValue"
          @handleAction="handleAction"
          @saveEditableCell="saveEditableCell"
          @changeOwner="changeOwner"
          @editCloudcctag="editCloudcctag"
          @showEditableCell="showEditableCell"
          @changeRecordtype="changeRecordtype"
        ></info-form>
        <div v-if="!loadingDetail && editableCell" class="changeDetailBox">
          <base-form
            ref="infoForm"
            class="baseForm"
            :detail="true"
            :option-list="optionList"
            :form-attr="formAttr"
            :special-objectapi="objectApi"
            :columnNum="columnNum"
            :oneColumnWidth="oneColumnWidth"
            :twoColumnWidth="twoColumnWidth"
            :status-icon="statusIcon"
            :autoRefresh="autoRefresh"
            :objectApi="objectApi"
            :prefix="prefix"
            @addFieldOption="addFieldOption"
            @addFile="addFile"
            @remoteMethod="remoteMethod"
            @changeSelect="changeSelect"
            @control="control"
            @lookupValue="lookupValue"
            @handleAction="handleAction"
            @newSearchableField="newSearchableField"
          ></base-form>
          <div
            :class="
              detailLayoutMode === 'mode1'
                ? detailType === 'controlPanel'
                  ? 'changeDetailBtns-control'
                  : 'changeDetailBtns'
                : 'changeDetailBtns rightChangeDetailBtns'
            "
          >
            <el-button size="mini" @click="cancelChangeDetail">
              <!-- 取消 -->
              {{ $t("label.ems.cancel") }}
            </el-button>
            <el-button
              type="primary"
              size="mini"
              v-preventReClick
              @click="saveChange"
            >
              <!-- 保存 -->
              {{ $t("label.save") }}
            </el-button>
          </div>
        </div>
      </div>
    </transition>

    <!-- 活动 -->
    <activity
      ref="activitys"
      class="margintop-10"
      v-if="showPart === 'activity'"
      :objectApi="objectApi"
      :tabName="tabName"
      :bigTitle="bigTitle"
      :dataId="dataId"
      :prefix="prefix"
      :objId="objId"
    ></activity>

    <!-- 动态 -->
    <div v-if="showPart === 'dynamicPublic'">
      <dynamicPublic
        v-if="isShowdynamicPublics"
        class="margintop-10"
        ref="dynamicPublics"
        :recordName="bigTitle"
        :postShowType="postShowType"
        :tabheight="tabheight"
        :activeName="activeName"
        :isShowType="isShowType"
        :dataId="dataId"
      ></dynamicPublic>
      <div class="isEnableBox" v-else>
        <svg
          class="icon"
          style="width: 200px; margin-bottom: 20px; height: 110px"
          aria-hidden="true"
        >
          <use :href="'#icon-PostsNoEnable'"></use>
        </svg>
        <div>
          {{ $t("setup.chatter.enabled") }}
        </div>
      </div>
    </div>

    <!-- 图表 -->
    <div class="report-chart-box" v-if="showPart === 'chart'">
      <div v-for="(item, index) in chartsListData" :key="index">
        <ReportChart
          v-if="chartsListData.length > 0"
          ref="reportChartRef"
          :chartsData="item"
          :id="id"
        >
        </ReportChart>
      </div>
      <!-- 没有仪表板 无相关仪表板-->
      <div class="noData_2" v-if="chartsListData.length === 0">
        <temporarily-no-data :titleWord="$t('vue_label_SMS_nodata')" />
      </div>
    </div>

    <!-- 更改记录类型 -->
    <el-dialog
      :title="recordTypeDialogAttr.title"
      :width="recordTypeDialogAttr.dialogWidth"
      :visible.sync="recordTypeDialogAttr.isShow"
      :lock-scroll="true"
      :close-on-click-modal="false"
      :modal="true"
      :before-close="cancelRecordTypeDialog"
      top="15%"
    >
      <div v-if="recordOptions.length >= 1" style="margin-left: 15px">
        <!-- 选择记录类型 -->
        <span>{{ $t("label_tabpage_selrecordtypez") }}：</span>
        <!-- 请选择 -->
        <el-select
          v-model="recordType"
          filterable
          :placeholder="$t('label_tabpage_pleaseSelectz')"
        >
          <el-option
            v-for="item in recordOptions"
            :key="item.val"
            :label="item.key"
            :value="item.val"
          ></el-option>
        </el-select>
      </div>
      <div
        slot="footer"
        class="dialog-footer"
        style="text-align: right; margin-top: 10px"
      >
        <el-button size="mini" @click="cancelRecordTypeDialog">
          <!-- 取消 -->
          {{ $t("label.ems.cancel") }}
        </el-button>
        <el-button size="mini" type="primary" @click="next">
          <!-- 确定 -->
          {{ $t("label.confirm") }}
        </el-button>
      </div>
    </el-dialog>

    <!-- 更改所有人 -->
    <transfer-owner-dialog
      ref="transferClientOwner"
      :id="id"
      :objId="objId"
      :transfer-deault="transferDeault"
      :owner-type-list="ownerTypeList"
      :owner-raletion-list="ownerRaletionList"
      :transfer-client-owner-dialog="transferClientOwnerDialog"
      @closeTransferOwnerDialog="closeTransferOwnerDialog"
      @saveTransferOwner="saveTransferOwner"
    ></transfer-owner-dialog>

    <!-- 查找/查找多选 -->
    <el-dialog
      :title="$t('label.quickbooks.searchs')"
      custom-class="dialog-form-wrapper"
      width="60%"
      top="5%"
      :visible.sync="showSearchTable"
      :lock-scroll="true"
      :close-on-click-modal="false"
      :modal="true"
      append-to-body
    >
      <search-table
        ref="searchTable"
        :fieldId="fieldId"
        :checked="checked"
        :relevant-objid="relevantObjId"
        :relevant-prefix="relevantPrefix"
        @changeSelect="changeSelectOwner"
      />
    </el-dialog>

    <!-- 添加文件 -->
    <add-files
      ref="addFile"
      :isFromFileCenter="false"
      :dialogAddVisible="addFileDialogVisible"
      @addSubmit="addFileSubmit"
      @close="addFileClose"
    ></add-files>

    <!-- 更改记录类型后编辑记录 -->
    <create-edit-obj
      ref="editObj"
      :prefix="prefix"
      :id="id"
      :object-api="objectApi"
      :object-name="tabName"
      @save="save"
    ></create-edit-obj>
  </div>
</template>

<script>
import Vue from "vue";
import vueWangIview from "vue-wang-iview"; // 骨架屏
import "vue-wang-iview/dist/vue-wang-iview.css";
Vue.use(vueWangIview);
import Bus from "@/mixin/bus.js";
import {
  InfoForm,
  BaseForm,
  AddFiles,
  TransferOwnerDialog,
} from "@/components/index";
import TransferOwnerMixin from "@/mixin/transferOwner.js";
import * as CommonObjApi from "./api";
import INPUTTYPE from "@/config/enumCode/inputType";
import createEditObj from "../components/create-edit-obj.vue";
import VueCookies from "vue-cookies";
import dynamicPublic from "../../colleagueCircle/components/dynamic-public.vue";
import activity from "../activity.vue";
import ReportChart from "@/views/commonObjects/components/ReportChart.vue";
import TemporarilyNoData from "@/components/TemporarilyNoData/index.vue"; // 暂无数据

export default {
  name: "detail-info",
  mixins: [TransferOwnerMixin],
  components: {
    InfoForm,
    BaseForm,
    AddFiles,
    TransferOwnerDialog,
    createEditObj,
    dynamicPublic,
    activity,
    ReportChart,
    TemporarilyNoData,
  },
  props: {
    // 项目360所在页面位置
    position: {
      type: String,
      default: "left",
    },
    // 详细信息展示模式,mode1时详细信息在左边
    detailLayoutMode: {
      type: String,
      default: "mode1",
    },
    isShowType: {
      type: Boolean,
      default: false,
    },
    // 记录id
    dataId: {
      type: String,
      default: "",
    },
    // 展示部分：详细信息、活动、动态
    showPart: {
      type: String,
      default: "",
    },
    tabName: {
      type: String,
      default: "",
    },
    // 对象api
    objectApi: {
      type: String,
      default: "",
    },
    // 对象前缀
    prefix: {
      type: String,
      default: "",
    },
    // 详情页编辑权限
    isEditDetail: {
      type: Boolean,
      default: false,
    },
    // 详情页查询权限
    isQueryDetail: {
      type: Boolean,
      default: false,
    },
    // 记录是否锁定
    isLocked: {
      type: Boolean,
      default: false,
    },
    // 更改所有人权限
    canEditOwner: {
      type: Boolean,
      default: false,
    },
    bigTitle: {
      type: Array,
    },
    postShowType: {
      type: String,
      default: "",
    },
    // 详情页的收起按钮，高度为60px的时候就隐藏组件
    tabheight: {
      type: String,
      default: "",
    },
    // 详情页当前选中活动还是动态
    activeName: {
      type: String,
      default: "",
    },
    // 是否来自服务控制台
    detailType: {
      type: String,
      default: "project",
    },
  },
  data() {
    return {
      chartsListData: [], //报表图表数据
      detailMode: "",
      columnNum: 2,
      oneColumnWidth: "100%",
      twoColumnWidth: "50%",
      inlineedit: false, // 全局内联编辑权限
      detailSkeletonOption: [
        {
          type: "line",
          options: {
            active: true,
            row: 2,
            column: 2,
            width: "30vw",
            height: "20px",
            justifyContent: "flex-start",
          },
        },
      ],
      personsName: "",
      validRule: true,
      controlContainer: null, //依赖关系
      actionRequireds: [], // 动态布局必填字段
      actionShowFields: [], // 动态布局显示字段
      actionShowSections: [], // 动态布局显示部分
      addFileDialogVisible: false,
      id: this.$route.params.id || this.dataId, // 记录id
      objectApiProp: this.objectApi, // 对象api
      objId: "",
      prefixProp: this.prefix,
      labelPosition: "top",
      editableCell: false,
      formAttr: null,
      labelWidth: 180,
      statusIcon: true,
      autoRefresh: false,
      type: this.$route.params.type,
      recordTypeDialogAttr: {
        // "选择记录类型"
        title: this.$i18n.t("label.recordtypeselect"),
        isShow: false,
        dialogWidth: "30%",
      },
      recordType: "",
      recordOptions: [],
      loadingDetail: true, // 详细信息loading
      transferClientOwnerDialog: false,
      optionList: {}, // 详细信息下拉框选项
      ownerName: "", // 所有人
      ownerId: "", // 所有人id
      isSendMail: false, // 更改所有人是否发送邮件
      ownerRaletionList: this.optional,
      optional: [
        {
          label: "转移其他人所有未处理业务机会",
          value: "otherUntreatedOpportunity",
        },
        {
          label: "转移客户所有人的已结束业务机会",
          value: "processedOpportunity",
        },
        {
          label: "转移客户所有人的全部个案",
          value: "ffe201100005108mj4GB",
        },
        {
          label: "转移客户所有人的全部发票",
          value: "ffe20181108invoice04",
        },
        {
          label: "转移客户所有人的全部报价单",
          value: "ffe201100006202iYyrO",
        },
      ],
      showSearchTable: false, // 显示查找字段精准搜索弹窗
      fieldId: "", // 查找字段id
      checked: false, // 查找字段精准搜索是否可多选
      relevantObjId: "",
      relevantPrefix: "",
      showDetail: false,
      operation: "DETAIL",
      isEdit: null,
      isQuery: null,
      newObjLevel: 0, // 新建查找字段标识
      fieldProp: "",
      userUrl: "",
      token: this.$CCDK.CCToken.getToken(),
      systemManager: false, // 是否是管理员
      isSkeleton: true,
      multiScreen: false, //判断是否在分屏页面下
      attrIndex: -1, // 表单字段所在位置
      isBottom: true, //唯一标识
      isShowdynamicPublics: false, // 是否显示动态
    };
  },
  created() {
    if (this.$route.path && this.$route.path.split("/")[2] === "multi-screen") {
      this.multiScreen = true;
    } else {
      this.multiScreen = false;
    }
    this.userUrl = this.$cookies.get("domainName");
    this.getCurrentUser();
  },
  mounted() {
    // 监听窗口变化 详细信息在左侧时监听，在右侧时无需监听
    Bus.$on("windowResize", this.windowResizeFn);
  },
  destroyed() {
    this.$bus.$off("followersTo");
    Bus.$off("windowResize", this.windowResizeFn);
  },
  methods: {
    windowResizeFn() {
      if (this.position === "left") {
        if (
          this.detailLayoutMode === "mode1" &&
          this.detailMode !== "twoColumns"
        ) {
          this.calcDetailInfoWidth();
        } else {
          this.setColumnsMode("twoColumns");
        }
      }
    },
    // 详细信息内嵌lightning版本自定义页面
    getPageDetail(group) {
      if (!group.pageUrl) {
        return;
      }
      let parameter = {
        pageApi: group.pageUrl,
      };
      this.$devHttp
        .postFormat(
          `${window.Glod['ccex-dev']}/custom/pc/1.0/post/detailCustomPage`,
          parameter
        )
        .then((res) => {
          let idx = this.formAttr.findIndex(
            (item) => item.selectionId === group.selectionId
          );
          let obj = {
            width: "100%",
            colNum: 1,
            type: "customPage",
            attrIndex: this.attrIndex,
            selectionId: group.selectionId,
            groupShow: true,
            hidden: false,
            componentData: JSON.parse(res.data.pageContent),
            canvasStyleData: JSON.parse(res.data.canvasStyleData),
          };
          this.formAttr.splice(idx + 1, 0, obj);
        })
        .catch((err) => {
          this.message.error(err.returnInfo);
        });
    },
    // 二列布局和响应式布局切换
    
    changeColumns(columns) {
      if (this.position === "left") {
        if (columns === "twoColumns") {
          VueCookies.set(`${this.objectApi}DetailMode`, "twoColumns", { sameSite: 'Strict' });
          this.setColumnsMode("twoColumns");
        } else {
          VueCookies.set(`${this.objectApi}DetailMode`, "responsiveLayout", { sameSite: 'Strict' });
          this.calcDetailInfoWidth();
        }
        Bus.$emit("setColumnsMode", columns);
        this.detailMode = VueCookies.get(`${this.objectApi}DetailMode`);
      }
    },
    // 计算详细信息宽度
    calcDetailInfoWidth() {
      let width =
        document.getElementsByClassName("main")[0] &&
        document.getElementsByClassName("main")[0].clientWidth;
      if (width <= 960) {
        this.setColumnsMode("twoColumns");
      } else if (960 < width && width <= 1440) {
        this.setColumnsMode("fourColumns");
      } else if (width > 1440) {
        this.setColumnsMode("sixColumns");
      }
    },
    // 246列布局设置
    setColumnsMode(detailMode) {
      switch (detailMode) {
        case "twoColumns":
          this.columnNum = 2;
          this.oneColumnWidth = "100%";
          this.twoColumnWidth = "50%";
          break;
        case "fourColumns":
          this.columnNum = 4;
          this.oneColumnWidth = "50%";
          this.twoColumnWidth = "25%";
          break;
        case "sixColumns":
          this.columnNum = 6;
          this.oneColumnWidth = `${100 / 3}%`;
          this.twoColumnWidth = `${100 / 6}%`;
          break;
      }
    },
    // 获取当前登录用户
    getCurrentUser() {
      // 获取登录用户
      CommonObjApi.getUserInfo().then((res) => {
        // 管理员可编辑
        this.systemManager = res.data.profileId === "aaa000001" ? true : false;
        if (this.systemManager) {
          this.isEdit = true;
        }
      });
    },
    addFieldOption(prop, option) {
      if (this.optionList[prop]) {
        this.optionList[prop].push(option);
      } else {
        this.optionList[prop] = [option];
      }
    },
    // 编辑标签
    editCloudcctag() {
      this.$emit("customBtn");
    },
    // 编辑详细信息
    showEditableCell(val, type) {
      this.loadingDetail = true;
      this.operation = "EDIT";
      this.getFormAttr(val, type);
    },
    //获取cookies
    getCookie(name) {
      var prefix = name + "=";
      var start = document.cookie.indexOf(prefix);
      if (start == -1) {
        return null;
      }
      var end = document.cookie.indexOf(";", start + prefix.length);
      if (end == -1) {
        end = document.cookie.length;
      }
      var value = document.cookie.substring(start + prefix.length, end);
      return unescape(value);
    },
    // 远程搜索
    remoteMethod(params, item, formData) {
      //在项目管理系统下对负责人所属项目的判断
      if (
        item.apiname == "principal" ||
        item.apiname == "member" ||
        (window.location.href.substring(window.location.href.length - 3) ==
          "p04" &&
          (item.apiname == "milestone" ||
            item.apiname == "affected_milestones"))
      ) {
        if (
          formData.project_name == "" ||
          formData.their_project == "" ||
          formData.their_project_name == ""
        ) {
          this.$message.error("请选择项目");
          return;
        }
      }
      if (
        ((window.location.href.substring(window.location.href.length - 3) ==
          "p03" ||
          this.getCookie("parentObjectApi") == "cloudccTask") &&
          this.milestone == "their_milestone") ||
        (this.getCookie("parentObjectApi") == "cloudccTask" &&
          this.milestone == "their_task")
      ) {
        params.projectId = "";
      } else if (
        (window.location.href.substring(window.location.href.length - 3) ==
          "p05" ||
          this.getCookie("parentObjectApi") == "cloudccSubtask") &&
        this.milestone == "their_task"
      ) {
        params.projectId = "";
      } else {
        params.projectId =
          formData.their_project ||
          formData.project_name ||
          formData.their_project_name ||
          "";
      }
      // 查询是否有默认条件
      CommonObjApi.getLookupInfo({
        fieldId: params.fieldId,
        objId: item.objId,
        prefix: item.prefix,
      }).then((res) => {
        let values = [''];

        // 开启查找筛选
        if (res.data.isEnableFilter === "true") {
          for (let reltaion in res.data.filterFieldIds) {
            let attr = this.formAttr.find((attrItem) => {
              return (
                attrItem.fieldId === res.data.filterFieldIds[reltaion].fieldid
              );
            });
            if (
              attr !== undefined &&
              formData[attr.prop] !== null &&
              Array.isArray(formData[attr.prop])
            ) {
              values.push(formData[attr.prop].join(";"));
            } else if (attr !== undefined && formData[attr.prop] !== null) {
              values.push(formData[attr.prop]);
            }
          }
        }
        params.lkdp = JSON.stringify(values);

        CommonObjApi.queryMoreDataByName(params).then((res) => {
          let options = JSON.parse(res.data);
          options.forEach((option) => {
            option.value = option.id;
            option.label = option.queryname;
          });
          this.$set(this.optionList, item.prop, options);
        });
      });
    },
    // 新增查找、查找多选字段
    newSearchableField(item) {
      // 获取对象api后进行页面跳转
      CommonObjApi.getLookupInfo({
        fieldId: item.fieldId,
        objId: item.objId,
        prefix: item.prefix,
      }).then((res) => {
        let objectApi = res.data.objectApi;
        window.open(
          `#/add-relevant-obj/${item.prefix}/${objectApi}/${this.newObjLevel}?addType=option&prop=${item.prop}`
        );
      });
    },
    // 获取报表id接口
    async queryReprtRecordtypeid() {
      let obj = {
        recordtypeid: this.konwrecordType,
      };
      let data = await CommonObjApi.queryReprtRecordtypeid(obj);
      this.chartsListData = data.data;
      // {recordname}的业务机会总金额,记录名为神州云动,则展示为“神州云动的业务机会总金额;
      this.chartsListData.map((item) => {
        if (item.title.indexOf("{recordname}") > -1) {
          item.title = item.title.replace(
            "{recordname}",
            this.bigTitle[0].fieldValue
          );
        }
      });
    },
    // 打开添加文件
    addFile(field) {
      this.fieldProp = field.prop;
      this.$refs.addFile.initPage();
      this.addFileDialogVisible = true;
    },
    // 添加文件
    addFileSubmit(checkedFileList) {
      let ids = [];
      checkedFileList.forEach((file) => {
        ids.push(file.id);
      });
      this.$set(this.$refs.infoForm.formData, this.fieldProp, ids.toString());
      this.formAttr.map((attr) => {
        if (attr.prop === this.fieldProp) {
          attr.data = checkedFileList;
        }
      });
      this.addFileClose();
    },
    // 关闭添加文件
    addFileClose() {
      this.addFileDialogVisible = false;
    },
    getFormAttr(val, type) {
      this.loadingDetail = true;
      this.formAttr = [];
      this.optionList = {};
      this.attrIndex = -1;
      CommonObjApi.getDetail({
        id: this.id,
        objectApi: "",
        operation: this.operation,
        recordType: this.recordType,
      })
        .then((res) => {
          this.konwrecordType = res.data.recordType;
          if (this.konwrecordType) {
            //获取记录类型id，查询报表id接口
            this.queryReprtRecordtypeid();
          }
          this.objId = res.data.objId;
          this.objectApiProp = res.data.objectApi;
          this.prefixProp = res.data.prefix;
          this.$emit("dataListes", res.data);
          this.$emit("resetObjectInfo", res.data);
          this.editableCell = this.operation === "DETAIL" ? false : true;

          // 246列布局设置
          if (VueCookies.get(`${this.objectApi}DetailMode`) === null) {
            VueCookies.set(`${this.objectApi}DetailMode`, "twoColumns", { sameSite: 'Strict' });
          }
          this.detailMode = VueCookies.get(`${this.objectApi}DetailMode`);
          if (
            this.position === "left" &&
            this.detailLayoutMode === "mode1" &&
            this.detailMode !== "twoColumns"
          ) {
            this.calcDetailInfoWidth();
          }
          res.data.dataList.forEach((group) => {
            // 每部分下标都从0开始
            if (group.showWhenEdit) {
              this.attrIndex = -1;
            }
            if (group.selectionId === "adf201596551299p35Tq") {
              group.data.forEach((groupes) => {
                if (groupes.right.apiname === "isconvert") {
                  this.$emit("convert", groupes.right.value);
                }
              });
            }
            // 获取详情页用户对象跳转的用户名
            let gropDataOne = res.data.dataList[0].data;
            this.personsName =
              gropDataOne && gropDataOne[1] ? gropDataOne[1].left.value : "";
            // 将数据传给detail父
            if (!this.isShowType) {
              this.$emit("personsNameMethod", this.personsName);
            }

            // 获取详情页用户对象跳转的用户名
            let colNum = group.colNum;
            // 部分名称展示与否通过showWhenEdit控制
            if (group.showWhenEdit) {
              if (this.getSelection(group.selectionId, this.formAttr)) {
                this.formAttr.push({
                  width: "100%",
                  colNum: 1,
                  type: "groupTitle",
                  title: group.title,
                  selectionId: group.selectionId,
                  groupShow: true,
                  hidden: false,
                });
              }
            }

            // 非内嵌页面
            if (group.selectionType === "Field") {
              group.data &&
                group.data.length > 0 &&
                group.data.forEach((line) => {
                  if (
                    line.left && !(Array.isArray(line.left) && line.left.length === 0) &&
                    line.left.fieldType !== "blank"
                  ) {
                    let attr = this.formAttr.find((item) => {
                      return item.fieldId === line.left.fieldId;
                    });
                    if (attr === undefined) {
                      this.addFormAttr(
                        group.title,
                        group.selectionId,
                        colNum,
                        line.left,
                        undefined,
                        "left"
                      );
                    }
                  } else {
                    this.attrIndex += 1;
                    if (this.getSelection(group.selectionId, this.formAttr)) {
                      this.formAttr.push({
                        width: "50%",
                        colNum: 2,
                        attrIndex: this.attrIndex,
                        type: "noData",
                        hidden: false,
                        groupShow: true,
                        groupTitle: group.title,
                        selectionId: group.selectionId,
                        position: 'left'
                      });
                    }
                  }
                  if (Number(colNum) !== 1) {
                    if (
                      !(Array.isArray(line.right) && line.right.length === 0) &&
                      line.right.fieldType !== "blank"
                    ) {
                      let attr = this.formAttr.find((item) => {
                        return item.fieldId === line.right.fieldId;
                      });
                      if (attr === undefined) {
                        this.addFormAttr(
                          group.title,
                          group.selectionId,
                          colNum,
                          line.right,
                          undefined,
                          "right"
                        );
                      }
                    } else {
                      this.attrIndex += 1;
                      if (this.getSelection(group.selectionId, this.formAttr)) {
                        this.formAttr.push({
                          width: "50%",
                          colNum: 2,
                          attrIndex: this.attrIndex,
                          type: "noData",
                          hidden: false,
                          groupShow: true,
                          groupTitle: group.title,
                          selectionId: group.selectionId,
                        });
                      }
                    }
                  }
                });
            } else {
              this.attrIndex += 1;
              // 新版本内嵌页面
              this.getPageDetail(group);
            }
          });
          // 任务事件名称相关项的处理
          if (this.objectApi === "Event" || this.objectApi === "Task") {
            this.getRelatedItem();
          }
          this.loadingDetail = false;
          this.$nextTick(() => {
            // 强制刷新表单
            this.$refs.infoForm && this.$refs.infoForm.filterFormData();

            // 编辑的时候不走查找带值和字段依赖规则
            if (this.operation === "EDIT") {
              // 查找带值，绑定事件
              if (
                res.data.lookupValueExpression !== undefined &&
                res.data.lookupValueExpression !== "" &&
                res.data.lookupValueExpression !== null
              ) {
                this.setLookupValue(JSON.parse(res.data.lookupValueExpression));
              }
              // 依赖关系，绑定事件
              this.controlContainer = JSON.parse(res.data.controlContainer);
              this.setControlContainer();
            }

            // 动态布局规则，绑定事件
            this.getDynamicLayoutListDetail(res.data.layoutId);

            this.formAttr.forEach((res) => {
              if (res.apiname && res.apiname == "ownerid") {
                this.$Bus.$emit("deliver-group-strategy-ownerid", res.data[0]);
              }
              // 服务区域和服务区域成员 取操作时间id
              if (res.apiname === "operatinghoursid") {
                if (
                  this.objectApi === "ServiceTerritory" ||
                  this.objectApi === "ServiceTerritoryMember"
                ) {
                  this.$Bus.$emit("service-territory-data", res.data[0]);
                }
              }
            });
            if (val && type) {
              this.$nextTick(() => {
                if (type === "name") {
                  // 回填客户名
                  this.$refs.infoForm.setTianyanValue(val);
                } else if (type === "all") {
                  // 回填全部数据
                  this.$refs.infoForm.setTianyanValuees(val);
                }
                this.$message(this.$i18n.t("vue_label_visualize_data")); // 已回填天眼查数据，请保存。
              });
            }
          });
        })
        .catch(() => {
          // 如果是编辑时请求详细信息接口报错，返回 展示详细信息
          if (this.operation === "EDIT") {
            this.cancelChangeDetail();
          }
        });
    },
    // 判断部分名称是否已经存在
    getSelection(selectionId, formAttr) {
      let part = formAttr.find((item) => {
        return item.selectionId === selectionId;
      });
      // true 表示不存在该标题，可添加，false表示存在，不重复添加
      if (part !== undefined) {
        return false;
      } else {
        return true;
      }
    },
    // 查找带值字段
    setLookupValue(lookupValueExpression) {
      this.lookupValues = [];
      lookupValueExpression.forEach((item) => {
        this.formAttr.forEach((attr) => {
          if (attr.apiname === item.expression.split(",")[0]) {
            attr.changeEvent =
              attr.changeEvent === undefined
                ? "lookupValue"
                : `${attr.changeEvent},lookupValue`;
            // 初始化规则时走规则
            this.getLookupValue(item, attr.value);
          }
        });
        this.lookupValues.push(item);
      });
    },
    // 查找带值
    lookupValue(selectVal, selectItem, formData) {
      this.lookupValues.forEach((lookupValue) => {
        if (lookupValue.expression.split(",")[0] === selectItem.apiname) {
          this.getLookupValue(lookupValue, selectVal, formData);
        }
      });
    },
    // 查找带值
    getLookupValue(lookupRule, id, formData) {
      // id: 查找记录的id
      let fieldJson = [];
      let carryValue = "";

      // 查找字段值为空时不走带值规则
      if (id.toString() !== "") {
        fieldJson.push({
          fieldrelateid: lookupRule.id,
          id: id,
          expression: lookupRule.expression,
          iscover: lookupRule.iscover,
        });
        let param = {
          objId: this.prefix,
          fieldJson: JSON.stringify(fieldJson),
        };
        if (this.isZhucongfield) {
          // 从对象前缀
          param.objId = this.relatedObjPrefix;
        }
        CommonObjApi.getLookupRelatedFieldValue(param).then((res) => {
          carryValue =
            res.data && res.data.length > 0 ? res.data[0].value : null;
          // 主对象查找带值
          // 编辑时，如果要带值的字段值为空，带的值必须带过去，如果值不为空，iscover为‘true’时才带值，要带值的字段有可能是查找字段，需要处理
          this.formAttr.forEach((attr) => {
            if (attr.fieldId === lookupRule.id) {
              // 编辑时
              if (formData !== undefined) {
                // 如果要带值的字段值为空，带的值必须带过去   如果值不为空，iscover为‘true’时才带值
                if (
                  formData[attr.prop] === "" ||
                  formData[attr.prop] === null ||
                  (formData[attr.prop] instanceof Array &&
                    formData[attr.prop].length === 0) ||
                  lookupRule.iscover === "true"
                ) {
                  // 查找、查找多选字段特殊处理
                  if (
                    (attr.fieldType === "Y" ||
                      attr.fieldType === "MR" ||
                      attr.fieldType === "M") &&
                    res.data[0].value &&
                    res.data[0].lkid &&
                    res.data[0].value !== "" &&
                    res.data[0].lkid !== ""
                  ) {
                    this.optionList[attr.prop] = [
                      {
                        value: res.data[0].lkid,
                        label: res.data[0].value,
                      },
                    ];
                    if (res.data[0].lkid !== "") {
                      formData[attr.prop] =
                        attr.fieldType === "Y" || attr.fieldType === "M"
                          ? res.data[0].lkid
                          : [res.data[0].lkid];
                    }
                  } else {
                    // 处理数字类型返回null时展示为0的问题
                    if (
                      (attr.fieldType === "P" ||
                        attr.fieldType === "N" ||
                        attr.fieldType === "c") &&
                      (carryValue === "" || carryValue === null)
                    ) {
                      formData[attr.prop] = undefined;
                    } else {
                      formData[attr.prop] = carryValue;
                    }
                  }
                }
              } else {
                // 如果要带值的字段值为空，带的值必须带过去 如果值不为空，iscover为‘true’时才带值
                if (
                  attr.value === undefined ||
                  attr.value === "" ||
                  attr.value === null ||
                  lookupRule.iscover === "true"
                ) {
                  if (
                    (attr.fieldType === "Y" ||
                      attr.fieldType === "MR" ||
                      attr.fieldType === "M") &&
                    res.data[0].value &&
                    res.data[0].lkid &&
                    res.data[0].value !== "" &&
                    res.data[0].lkid !== ""
                  ) {
                    this.optionList[attr.prop] = [
                      {
                        value: res.data[0].lkid,
                        label: res.data[0].value,
                      },
                    ];
                    if (res.data[0].lkid !== "") {
                      attr.value =
                        attr.fieldType === "Y" || attr.fieldType === "M"
                          ? res.data[0].lkid
                          : [res.data[0].lkid];
                      this.$refs.infoForm &&
                        this.$set(
                          this.$refs.infoForm.formData,
                          attr.apiname,
                          attr.fieldType === "Y" || attr.fieldType === "M"
                            ? res.data[0].lkid
                            : [res.data[0].lkid]
                        );
                    }
                  } else {
                    attr.value = carryValue;
                    this.$refs.infoForm &&
                      this.$set(
                        this.$refs.infoForm.formData,
                        attr.apiname,
                        carryValue
                      );
                  }
                }
              }
            }
          });
        });
      }
    },
    // 改变查找/查找带值选项及值
    changeSelect(row, fieldId, formData) {
      let options = [];
      let fieldVal = [];
      if (row instanceof Array) {
        row.forEach((ele) => {
          options.push({
            label: ele.name,
            value: ele.id,
          });
        });
      } else {
        options.push({
          label: row.data.name,
          value: row.data.id,
        });
      }

      this.formAttr.forEach((attr) => {
        if (attr.fieldId === fieldId) {
          // 防止清空时清空选项，再次编辑查找字段时显示数据Id
          if (options.length > 0) {
            this.$set(this.optionList, attr.prop, options);
          }
          if (attr.fieldType === "MR") {
            options.forEach((option) => {
              fieldVal.push(option.value);
            });
            formData[attr.apiname] = fieldVal;
          } else {
            formData[attr.apiname] = row.data === undefined ? "" : row.data.id;
          }

          // 若有查找带值，将值带入
          this.lookupValues.forEach((lookupValue) => {
            if (attr.apiname === lookupValue.expression.split(",")[0]) {
              this.getLookupValue(
                lookupValue,
                formData[attr.apiname],
                formData
              );
            }
          });
        }
      });
    },
    // 依赖关系
    setControlContainer() {
      // 先将被控字段选项清空,循环后有对应选项再进行赋值
      this.controlContainer.forEach((control) => {
        control.controlVals.forEach((vals) => {
          vals.dependFields.forEach((dependField) => {
            this.formAttr.forEach((controlAttr) => {
              if (controlAttr.fieldId === dependField.dependfieldid) {
                this.optionList[controlAttr.prop] = [];
              }
            });
          });
        });
      });

      this.controlContainer.forEach((control) => {
        // 控制字段
        this.formAttr.forEach((attr) => {
          if (attr.fieldId === control.controlfield) {
            this.control(
              attr.value,
              attr.fieldId,
              this.$refs.infoForm.formData,
              true
            );
          }
        });
      });
    },
    // 依赖字段 ,initControl为true时是新建初始化，已经将选项值都清空，无需再次清理
    control(selectVal, fieldId, formData, initControl = false) {
      // 查找对应规则
      let control = this.controlContainer.find((control) => {
        return control.controlfield === fieldId;
      });

      if (control !== undefined) {
        // 先将被控字段选项清空,循环后有对应选项再进行赋值
        if (!initControl) {
          control.controlVals.forEach((vals) => {
            vals.dependFields.forEach((dependField) => {
              this.formAttr.forEach((controlAttr) => {
                if (controlAttr.fieldId === dependField.dependfieldid) {
                  // eslint-disable-next-line no-undef
                  controledFieldId.push(controlAttr.fieldId);
                  this.$set(this.optionList, controlAttr.prop, []);
                }
              });
            });
          });
        }

        // 查找当前值是否有控制规则
        let controlCondition = control.controlVals.find((item) => {
          return item.val === selectVal;
        });
        if (controlCondition === undefined) {
          // eslint-disable-next-line no-undef
          controledFieldId.forEach((fieldId) => {
            this.formAttr.forEach((controlAttr) => {
              if (controlAttr.fieldId === fieldId) {
                // 清空被控字段值并递归
                if (controlAttr.fieldType === "Q") {
                  this.$set(formData, controlAttr.apiname, []);
                } else {
                  this.$set(formData, controlAttr.apiname, "");
                }
                this.control(formData[controlAttr.apiname], fieldId, formData);
              }
            });
          });
        } else {
          // 控制选项列表和选项值
          controlCondition.dependFields.forEach((dependField) => {
            this.formAttr.forEach((controlAttr) => {
              if (controlAttr.fieldId === dependField.dependfieldid) {
                let options = [];
                dependField.vals.forEach((element) => {
                  options.push({
                    key: element.deflangval,
                    val: element.val,
                  });
                });
                this.optionList[controlAttr.prop] = options;

                // 若当前选中值不在选项列表中，则清空
                let option = options.find((item) => {
                  return item.val === formData[controlAttr.apiname];
                });
                if (option === undefined) {
                  // 清空被控字段值并递归
                  if (controlAttr.fieldType === "Q") {
                    this.$set(formData, controlAttr.apiname, []);
                  } else {
                    this.$set(formData, controlAttr.apiname, "");
                  }
                  // 如果被控制字段选项中包含字段默认值，将依赖字段的值赋为默认值
                  if (
                    controlAttr.defaultValue &&
                    controlAttr.defaultValue !== ""
                  ) {
                    let option = options.find((item) => {
                      return item.val === controlAttr.defaultValue;
                    });
                    if (option !== undefined) {
                      this.$set(
                        formData,
                        controlAttr.apiname,
                        controlAttr.fieldType === "Q"
                          ? [controlAttr.defaultValue]
                          : controlAttr.defaultValue
                      );
                    }
                  }
                  this.control(
                    formData[controlAttr.apiname],
                    dependField.dependfieldid,
                    formData
                  );
                }
              }
            });
          });
        }
      }
    },
    // 动态布局规则
    getDynamicLayoutListDetail(layoutId) {
      CommonObjApi.getDynamicLayoutListDetail({
        layoutId: layoutId,
      }).then((res) => {
        // 清空动态布局规则
        this.actionRequireds = [];
        this.actionShowFields = [];
        this.actionShowSections = [];
        this.actionConditions = res.data;

        res.data.forEach((action) => {
          // 重置规则控制字段
          action.mainConditions.forEach((mainCondition) => {
            // 主规则
            this.handleCondition(mainCondition, false);

            this.formAttr.forEach((attr, idx) => {
              // 地址和地理定位字段动态布局返回的是父字段id
              let fieldId = attr.fieldId;
              if (attr.parentType === "AD" || attr.parentType === "LT") {
                fieldId = attr.parentId;
              }
              if (fieldId === mainCondition.fieldId) {
                let changeEvent =
                  attr.changeEvent === undefined
                    ? "handleAction"
                    : `${attr.changeEvent},handleAction`;
                this.$set(this.formAttr[idx], "changeEvent", changeEvent);
              }
            });

            // 二级规则
            mainCondition.dynamicPageConditions &&
              mainCondition.dynamicPageConditions.forEach((dynamicPage) => {
                this.handleCondition(dynamicPage, false);

                dynamicPage.twoLevelConditions.forEach((twoLevelCondition) => {
                  this.formAttr.forEach((attr, idx) => {
                    // 地址和地理定位字段动态布局返回的是父字段id
                    let fieldIdTwo = attr.fieldId;
                    if (attr.parentType === "AD" || attr.parentType === "LT") {
                      fieldIdTwo = attr.parentId;
                    }
                    if (fieldIdTwo === twoLevelCondition.fieldId) {
                      let changeEvent =
                        attr.changeEvent === undefined
                          ? "handleAction"
                          : `${attr.changeEvent},handleAction`;
                      this.$set(this.formAttr[idx], "changeEvent", changeEvent);
                    }
                  });
                });
              });
          });
        });

        // 走规则
        this.handleAction();
      });
    },
    // 重置规则控制字段
    handleCondition(condition, flag) {
      // 设置字段/部分 是否显示/必填
      // 当flag为真时，规则中只要有一个条件满足，该字段显示/必填就为真
      // 每个控制字段变化时所有规则重新走一遍
      if (flag) {
        condition.action_requireds.forEach((requiredField) => {
          this.actionRequireds.forEach((item) => {
            if (item.fieldId === requiredField.field_id) {
              item.required = item.required || flag;
            }
          });
        });
        condition.action_showFields.forEach((requiredField) => {
          this.actionShowFields.forEach((item) => {
            if (item.fieldId === requiredField.field_id) {
              item.show = item.show || flag;
            }
          });
        });
        condition.action_showSections.forEach((requiredField) => {
          this.actionShowSections.forEach((item) => {
            if (item.sectionName === requiredField.sectionName) {
              item.showSection = item.showSection || flag;
            }
          });
        });
      } else {
        condition.action_requireds.forEach((requiredField) => {
          this.actionRequireds.push({
            fieldId: requiredField.field_id,
            required: flag,
          });
        });
        condition.action_showFields.forEach((requiredField) => {
          this.actionShowFields.push({
            fieldId: requiredField.field_id,
            show: flag,
          });
        });
        condition.action_showSections.forEach((requiredField) => {
          this.actionShowSections.push({
            sectionName: requiredField.sectionName,
            showSection: flag,
          });
        });
      }
    },
    /**
     * 判断高级条件是否成立
     * @param {Array} booleanList:条件成立结果组成的数组
     * @param {String} label:高级条件的label，表示高级条件的组合关系
     * 
     */
     isJudgment(booleanList,label){
      let isTrue = false
      if(label){
        // 去掉1 and 
        label = label.slice(5)
        // 替换and和or
        label = label.replace(/and/g,'&&')
        label = label.replace(/or/g,'||')
        // 替换每个条件的执行
        booleanList.forEach((item,index) => {
          let labelIndex = new RegExp((index+2).toString())
          label = label.replace(labelIndex,item)
        })
        isTrue = eval(label)
      }
      return isTrue
    },
    // 走规则
    handleAction() {
      // 清空动态布局规则
      this.actionRequireds = [];
      this.actionShowFields = [];
      this.actionShowSections = [];

      this.actionConditions.forEach((action) => {
        // 重置规则控制字段
        action.mainConditions.forEach((mainCondition) => {
          // 主规则
          this.handleCondition(mainCondition, false);

          // 二级规则
          mainCondition.dynamicPageConditions &&
            mainCondition.dynamicPageConditions.forEach((dynamicPage) => {
              this.handleCondition(dynamicPage, false);
            });
        });
      });

      this.actionConditions.forEach((action) => {
        // 循环规则控制字段
        action.mainConditions.forEach((mainCondition) => {
          let judgment = this.judgValue(
            mainCondition.fieldName,
            mainCondition.operator,
            mainCondition.value
          );
          if (judgment) {
            this.handleCondition(mainCondition, true);
            // 二级规则 - 符合一级规则时才走二级规则
            mainCondition.dynamicPageConditions &&
              mainCondition.dynamicPageConditions.forEach((dynamicPage) => {
                // 二级条件执行
                let judgment = [];
                dynamicPage.twoLevelConditions.forEach((twoLevelCondition) => {
                  let judfment2= this.judgValue(
                      twoLevelCondition.fieldName,
                      twoLevelCondition.operator,
                      twoLevelCondition.value,
                      twoLevelCondition.fieldType
                    );
                    
                  judgment.push(judfment2)
                });
                // 执行高级条件
                if (this.isJudgment(judgment,dynamicPage.label)) {
                  this.handleCondition(dynamicPage, true);
                }
              });
          }
        });
      });

      // 根据规则设置字段必填
      this.actionRequireds.forEach((actionRequire) => {
        this.formAttr.forEach((attr) => {
          if (attr.fieldId === actionRequire.fieldId) {
            if (actionRequire.required) {
              attr.rules = [
                {
                  required: true,
                  message: `${this.$i18n.t("label_tabpage_placeenterz")} ${
                    attr.fieldLabel
                  }`,
                  trigger: "blur",
                  // 请输入
                },
              ];
            } else {
              attr.rules = [];
            }
          }
        });
      });
      this.$refs.infoForm && this.$refs.infoForm.resetRules();

      // 根据规则设置字段显示/隐藏
      this.actionShowFields.forEach((actionShowField) => {
        this.formAttr.forEach((attr) => {
          let fieldId = attr.fieldId;
          if (attr.parentType == "AD" || attr.parentType == "LT") {
            // 地址和地理定位字段时需要对比attr上的父字段
            fieldId = attr.parentId;
          }
          if (fieldId === actionShowField.fieldId) {
            if (actionShowField.show) {
              attr.hidden = false;
            } else {
              attr.hidden = true;
            }
          }
        });
      });

      // 设置部分显示/隐藏
      // for (let actionShowSection in this.actionShowSections) {
      //   for (let attr in this.formAttr) {

      //   }
      // }
      this.actionShowSections.forEach((actionShowSection) => {
        if (actionShowSection.sectionName) {
          this.formAttr.forEach((attr) => {
            // 控制某部分标题显示/隐藏
            if (
              attr.title === actionShowSection.sectionName ||
              attr.groupTitle === actionShowSection.sectionName
            ) {
              if (actionShowSection.showSection) {
                attr.hidden = false;
                attr.groupShow = true;
              } else {
                // 部分下有必填字段时不隐藏该部分
                let requiredFields = this.formAttr.find((item) => {
                  return (
                    item.groupTitle === actionShowSection.sectionName &&
                    item.required
                  );
                });
                if (requiredFields !== undefined) {
                  attr.hidden = false;
                  attr.groupShow = true;
                } else {
                  attr.hidden = true;
                  attr.groupShow = false;
                }
              }
            }
          });
        }
      });
    },
    // 判断值
    
    judgValue(field, operator, value) {
      let fieldValue = this.$refs.infoForm.formData[field];
      // 如果是币种字段，且值包含币种，去除币种后再进行比较.例如将 AUD 20.000 转化为 20
      let attr = this.formAttr.find((attr) => {
        return attr.prop === field;
      });
      if (
        attr.fieldType === "c" &&
        this.$refs.infoForm.formData[field].toString().indexOf(" ") !== -1
      ) {
        fieldValue = Number(fieldValue.toString().split(" ")[1]);
      }
      if (this.$refs.infoForm.formData[field]) {
        switch (operator) {
          case "e":
            // 等于
            return fieldValue.toString() === value;
          case "n":
            // 不等于
            return fieldValue.toString() !== value;
          case "l":
            // 小于
            return Number(fieldValue) < Number(value);
          case "g":
            // 大于
            return Number(fieldValue) > Number(value);
          case "m":
            // 小于或等于
            return Number(fieldValue) <= Number(value);
          case "h":
            // 大于或等于
            return Number(fieldValue) >= Number(value);
          case "c":
            // 包含
            return fieldValue.includes(value);
          case "k":
            // 不包含
            return !fieldValue.includes(value);
          case "s":
            // 起始字符
            return fieldValue.indexOf(value) === 0;
        }
      } else {
        return false;
      }
    },
    // 表单添加属性
    addFormAttr(
      title,
      selectionId,
      colNum,
      attr,
      isChildren,
      position,
      parentAttr
    ) {
      if (position !== undefined) {
        this.$set(attr, "position", position);
      }
      this.$set(attr, "selectionId", selectionId);
      if (attr.required && attr.edit) {
        attr.rules = [
          {
            required: attr.required,
            message: `${this.$i18n.t("label_tabpage_placeenterz")} ${
              attr.fieldLabel || attr.label
            }`,
            trigger: "blur",
          },
        ];
      }

      attr.prop =
        isChildren && isChildren !== undefined ? attr.name : attr.apiname;
      if (
        parentAttr &&
        (parentAttr.fieldType == "AD" || parentAttr.fieldType == "LT")
      ) {
        // 如果父字段是地址和地理定位字段，存储父字段fieldId
        attr.parentId = parentAttr.fieldId;
        attr.parentType = parentAttr.fieldType;
      }
      if (attr.fieldType !== "AD" && attr.fieldType !== "LT") {
        this.attrIndex += colNum === 1 ? 0 : 1;
        this.$set(attr, "attrIndex", this.attrIndex);
        this.$set(
          attr,
          "label",
          attr.fieldLabel === undefined ? attr.label : attr.fieldLabel
        );
        this.$set(
          attr,
          "type",
          INPUTTYPE[attr.fieldType === undefined ? attr.type : attr.fieldType]
        );
        // 百分数字段字数长度限制
        if (attr.fieldType === "P") {
          attr.precision = Number(attr.decimalPlaces);
          let decimal =
            attr.decimalPlaces !== undefined && attr.decimalPlaces !== null
              ? 1 / Math.pow(10, Number(attr.decimalPlaces))
              : 0;
          if (attr.fieldLength !== undefined) {
            attr.valueInterval = {
              min:
                0 -
                Math.pow(10, attr.fieldLength - attr.decimalPlaces - 1) +
                decimal,
              max:
                Math.pow(10, attr.fieldLength - attr.decimalPlaces - 1) -
                decimal,
            };
          } else {
            attr.valueInterval = {
              min:
                0 -
                Math.pow(10, attr.length - attr.decimalPlaces - 1) +
                decimal,
              max: Math.pow(10, attr.length - attr.decimalPlaces - 1) - decimal,
            };
          }
        }
        // 图片显示
        if (
          attr.fieldType === "IMG" &&
          attr.expressionType !== "url" &&
          attr.value !== undefined &&
          this.operation === "EDIT"
        ) {
          if (attr.value !== "") {
            attr.data = [
              {
                name: "",
                url: `${this.userUrl}/querysome.action?m=viewImg&fileid=${attr.value}&binding=${this.token}`,
              },
            ];
          } else {
            attr.data = [];
          }
        }
        // 评分+1
        if (attr.type === "score") {
          attr.value =
            this.operation === "EDIT" ? Number(attr.value) : Number(attr.value);
        }
        if (attr.type === "select" || attr.type === "multi-select") {
          let options = [];
          attr.data &&
            attr.data.length > 0 &&
            attr.data.forEach((item) => {
              options.push({
                key: item.value,
                val: item.id,
              });
            });

          this.optionList[attr.prop] = options;
          // 记录类型选项特殊处理
          if (attr.prop === "recordtype") {
            this.recordType = attr.id;
            this.optionList[attr.prop] = [
              {
                key: attr.value,
                val: attr.id,
              },
            ];
          }

          if (attr.type === "multi-select") {
            // "--无--"
            attr.value =
              attr.value === this.$i18n.t("select.nullvalue") ||
              (!attr.value ? [] : attr.value.split(";"));
          }
        } else if (
          attr.type === "remote-select" ||
          attr.type === "remote-multi-select"
        ) {
          // 查找多选字段选项设置
          if (
            attr.fieldType === "MR" &&
            attr.value.length !== 0 &&
            attr.data &&
            attr.data.length > 0 &&
            attr.data[0].value !== ""
          ) {
            let multiOptions = [];
            let labels = attr.data[0].value.split(";");
            let values = attr.data[0].id.split(";");

            for (let index = 0; index < labels.length; index++) {
              multiOptions.push({
                label: labels[index],
                value: values[index],
              });
            }
            this.$set(this.optionList, attr.prop, multiOptions);
            // "--无--"
            attr.value =
              attr.value === this.$i18n.t("select.nullvalue") ||
              attr.data[0].value === ""
                ? []
                : attr.value.split(";");
          } else {
            // 查找字段选项设置
            let multiOptions = [];
            // 查找字段对应的记录有对应的value时才展示
            if (
              attr.value !== "" &&
              attr.data &&
              attr.data.length > 0 &&
              attr.data[0].value !== ""
            ) {
              multiOptions.push({
                label: attr.data[0].value,
                value: attr.data[0].id,
              });
            } else {
              attr.value = "";
            }
            // 创建人和最后修改人选项
            if (
              attr.apiname === "createbyid" ||
              attr.apiname === "lastmodifybyid"
            ) {
              multiOptions.push({
                value: attr.value,
                label: attr[`${attr.apiname}Name`],
              });
            }
            this.$set(this.optionList, attr.prop, multiOptions);
          }
          attr.readonly = true;
        } else if (attr.type === "number") {
          if (Number(attr.decimalPlaces) !== 0) {
            attr.precision = Number(attr.decimalPlaces);
          }
          let decimal =
            attr.decimalPlaces !== undefined && attr.decimalPlaces !== null
              ? 1 / Math.pow(10, Number(attr.decimalPlaces))
              : 0;
          if (attr.fieldLength !== undefined) {
            attr.valueInterval = {
              min:
                0 -
                Math.pow(10, attr.fieldLength - attr.decimalPlaces - 1) +
                decimal,
              max:
                Math.pow(10, attr.fieldLength - attr.decimalPlaces - 1) -
                decimal,
            };
          } else {
            attr.valueInterval = {
              min:
                0 -
                Math.pow(10, attr.length - attr.decimalPlaces - 1) +
                decimal,
              max: Math.pow(10, attr.length - attr.decimalPlaces - 1) - decimal,
            };
          }
        } else if (attr.type === "checkbox") {
          attr.value = attr.value === "true" ? true : false;
        }
        attr.width = colNum === 1 ? "100%" : "50%";
        attr.colNum = colNum;
        attr.groupTitle = title;
        attr.selectionId = selectionId;
        attr.groupShow = true;
        if (
          this.operation === "DETAIL" ||
          (this.operation === "EDIT" && attr.apiname !== "cloudcctag")
        ) {
          this.formAttr.push(attr);
        }
      } else if (this.operation === "DETAIL") {
        this.attrIndex += colNum === 1 ? 0 : 1;
        this.$set(attr, "attrIndex", this.attrIndex);
        this.$set(
          attr,
          "label",
          attr.fieldLabel === undefined ? attr.label : attr.fieldLabel
        );
        this.$set(
          attr,
          "type",
          INPUTTYPE[attr.fieldType === undefined ? attr.type : attr.fieldType]
        );
        attr.width = colNum === 1 ? "100%" : "50%";
        attr.groupTitle = title;
        attr.selectionId = selectionId;
        attr.groupShow = true;
        this.formAttr.push(attr);
      } else {
        // 地址和地理定位特殊处理
        attr.children.forEach((item) => {
          this.addFormAttr(
            title,
            selectionId,
            colNum,
            item,
            true,
            position,
            attr
          );
          // 判断如果有 国家或地区(以详细地址字段名+00结尾) 字段,获取国家或地区选项
          if (item["name"].slice(item["name"].length - 2) === "00") {
            this.getCountrySelectValue(item["name"]);
          }
        });

        this.attrIndex -= attr.children.length - 1;
      }
      if (attr.isContrField) {
        let changeEvent =
          attr.changeEvent === undefined
            ? "control"
            : `${attr.changeEvent},control`;
        this.$set(attr, "changeEvent", changeEvent);
      }
    },
    // 获取国家或地区选项列表值
    getCountrySelectValue(filedName) {
      CommonObjApi.getSelectValue({ fieldId: "country" }).then((res) => {
        if (res.result && res.returnCode === "1") {
          let options = [];
          res.data.forEach((item) => {
            options.push({
              val: item.codekey, // 对应的value
              key: item.codevalue, // 对应的label
            });
          });
          this.$set(this.optionList, filedName, options);
        } else {
          this.$message.error(res.returnInfo);
        }
      });
    },
    // 修改记录类型
    changeRecordtype() {
      this.getRecordType();
      this.recordTypeDialogAttr.isShow = true;
    },
    // 先弹窗编辑记录，后保存记录类型
    next() {
      this.cancelRecordTypeDialog();
      // 编辑
      CommonObjApi.getPermissionById({ id: this.id }).then((res) => {
        if (res.data.isEdit) {
          this.$nextTick(() => {
            this.$refs.editObj.editAfterChangeRecordType(this.recordType);
          });
        } else {
          // 抱歉，您无权编辑该条数据，请联系系统管理员。
          this.$message(this.$i18n.t("vue_label_notice_nopermission_view"));
        }
      });
    },
    // 关闭修改记录类型弹窗
    cancelRecordTypeDialog() {
      this.type = "";
      this.recordTypeDialogAttr.isShow = false;
    },
    // 取消编辑
    cancelChangeDetail() {
      this.loadingDetail = true;
      this.editableCell = false;
      this.operation = "DETAIL";
      this.getFormAttr();
    },
    // 保存编辑
    saveChange() {
      this.$refs.infoForm.$refs.elForm.validate((valid) => {
        if (valid) {
          let dataList = [];
          let data = Object.assign({}, this.$refs.infoForm.formData);
          // 数字字段特殊处理
          for (const key in data) {
            this.formAttr.forEach((attr) => {
              if (
                attr.prop === key &&
                data[key] === undefined &&
                (attr.type === "number" || attr.type === "percent-number")
              ) {
                data[key] = "";
              }
            });
          }
          delete data.undefined;

          // 使用有效的分配规则进行分配
          // "潜在客户"   "个案"
          if (
            this.tabName === this.$i18n.t("label.brief.lead") ||
            this.tabName === this.$i18n.t("label.case")
          ) {
            data.case_assiGNment = this.validRule ? "true" : "false";
          }
          for (const key in data) {
            // 修改查找多选和选项列表数据格式
            if (Array.isArray(data[key])) {
              data[key] = data[key].join(";");
              // "--无--"
            } else if (data[key] === this.$i18n.t("select.nullvalue")) {
              this.formAttr.forEach((attr) => {
                if (
                  attr.prop === key &&
                  (attr.type === "select" ||
                    attr.type === "multi-select" ||
                    attr.type === "remote-select" ||
                    attr.type === "remote-multi-select")
                ) {
                  data[key] = "";
                }
              });
            } else if (data[key] !== undefined) {
              // 所有数据均变为字符串保存
              if (data[key] !== undefined && data[key] !== null) {
                data[key] = data[key].toString();
              } else {
                data[key] = "";
              }
            } else if (data[key] === undefined) {
              this.formAttr.forEach((attr) => {
                if (attr.prop === key && attr.fieldType === "P") {
                  data[key] = "";
                }
              });
            }
          }
          data.id = this.id;
          dataList.push(data);
          let that = this;
          // 判断结束时间是否大于开始时间
          if (
            (dataList[0].begintime && dataList[0].endtime) ||
            (dataList[0].start_date && dataList[0].end_date)
          ) {
            // eslint-disable-next-line no-inner-declarations
            function tab(date1, date2) {
              let oDate1 = new Date(date1);
              let oDate2 = new Date(date2);
              if (oDate1.getTime() > oDate2.getTime()) {
                // 结束日期不得小于开始日期
                that.$message.warning(
                  that.$i18n.t("label.weixin.end.later.start")
                );
                return false;
              } else {
                return true;
              }
            }
            if (
              !tab(dataList[0].begintime, dataList[0].endtime) ||
              !tab(dataList[0].start_date, dataList[0].end_date)
            ) {
              return false;
            }
          }
          // 项目管理系统下，fieldType为c即金额类添加验证
          if (
            this.$store.state.home.homeApplicationId === "ace2020D253B3984Or6Q"
          ) {
            let moneyFlag = true,
              moneyName = "";
            this.formAttr.forEach((item) => {
              if (item.fieldType && item.fieldType === "c") {
                if (
                  dataList[0][item.apiname] &&
                  dataList[0][item.apiname].length > 10
                ) {
                  moneyName = item.fieldLabel;
                  moneyFlag = false;
                }
              }
            });
            if (!moneyFlag) {
              // 最多输入10位
              this.$message.warning(
                `${moneyName}` +
                  this.$i18n.t("label.projectManagement.enter.up.to.10.digits")
              );
              return;
            }
          }
          let params = {
            objectApi: this.objectApi,
            data: JSON.stringify(dataList),
          };
          CommonObjApi.save(params).then((res) => {
            if (
              res.data &&
              res.data[0] &&
              res.data[0].isSaveSuccess === "true"
            ) {
              this.loadingDetail = true;
              this.editableCell = false;
              this.operation = "DETAIL";
              this.$emit("refreshAll");
            } else {
              // 查找字段保存时，存在筛选条件不符，页面定位指定字段，并给出提示
              if(res?.data && res.data[0]?.errormessage.includes('illegal_field_apiname')){
                // 值不存在或与筛选条件不匹配
                this.$message.error(this.$i18n.t("c2420"));
              }else{
                this.$message.error(
                  res.data[0].errormessage || this.$i18n.t("chatter_save_f")
                );
              }
            }
          });
        } else {
          // 存在验证错误，请检查填写的内容
          this.$message.warning(
            this.$i18n.t("vue_label_commonobjects_detail_check_the_content")
          );
        }
      });
    },
    // 保存详细信息
    saveEditableCell(isTianyanDataSave) {
      this.$refs.infoForm.$refs.elForm.validate((valid) => {
        if (valid || isTianyanDataSave) {
          let dataList = [];
          let data = this.$refs.infoForm.formData;
          delete data.undefined;

          for (const key in data) {
            // 修改查找多选和选项列表数据格式
            if (Array.isArray(data[key])) {
              data[key] = data[key].join(";");
              // "--无--"
            } else if (data[key] === this.$i18n.t("select.nullvalue")) {
              this.formAttr.forEach((attr) => {
                if (
                  attr.prop === key &&
                  (attr.type === "select" ||
                    attr.type === "multi-select" ||
                    attr.type === "remote-select" ||
                    attr.type === "remote-multi-select")
                ) {
                  data[key] = "";
                }
              });
            } else if (data[key] !== undefined) {
              // 所有数据均变为字符串保存
              if (data[key] !== undefined) {
                if (data[key]) {
                  data[key] = data[key].toString();
                } else {
                  data[key] = "";
                }
              }
            } else if (data[key] === undefined) {
              this.formAttr.forEach((attr) => {
                if (attr.prop === key && attr.fieldType === "P") {
                  data[key] = "";
                }
              });
            }
          }
          data.id = this.id;
          dataList.push(data);
          let params = {
            objectApi: this.objectApi,
            data: JSON.stringify(dataList),
          };
          CommonObjApi.save(params).then((res) => {
            if (
              res.data &&
              res.data[0] &&
              res.data[0].isSaveSuccess === "true"
            ) {
              // "修改成功"
              this.$message.success(this.$i18n.t("message.modify.success"));
              this.getFormAttr();
              this.$parent.getBrief();
            } else {
              // 查找字段保存时，存在筛选条件不符，页面定位指定字段，并给出提示
              if(res?.data && res.data[0]?.errormessage.includes('illegal_field_apiname')){
                // 值不存在或与筛选条件不匹配
                this.$message.error(this.$i18n.t("c2420"));
              }else{
                this.$message.error(
                  res.data[0].errormessage || this.$i18n.t("chatter_save_f")
                );
              }
            }
          });
        }
      });
    },
    // 更改所有人
    changeOwner() {
      this.ownerName = "";
      this.ownerId = "";
      this.isSendMail = false;
      this.initTransferOwner(this.objId, this.id);
      this.transferClientOwnerDialog = true;
    },
    // 查找所有人
    
    remoteSearchOwner() {
      // 查找所有人数据写死
      this.fieldId = "ffe100000001265aWyi8";
      this.relevantObjId = "user";
      this.relevantPrefix = "005";
      this.showSearchTable = true;
      this.$nextTick(() => {
        this.$refs.searchTable.page = 1;
        this.$refs.searchTable.pageObj.dataList = [];
        this.$refs.searchTable.searchConditions = "";
        this.$refs.searchTable.init();
      });
    },
    changeSelectOwner(row) {
      this.ownerName = row.data.name;
      this.ownerId = row.data.id;
      this.showSearchTable = false;
    },
    // 确认更改所有人
    
    saveTransferOwnerSuccess() {
      this.validRule = true;
      this.operation = "DETAIL";
      // 更改成功
      this.$message.success(this.$i18n.t("vue_label_notice_change_success")); //"修改记录类型成功"
      this.closeTransferOwnerDialog();
      // 更改所有人后重新查询记录权限
      CommonObjApi.getPermissionById({ id: this.id }).then((res) => {
        if (res.returnCode === "1" && res.result) {
          if (res.data.isQuery) {
            this.$emit("refreshAll");
          } else {
            this.$parent.isQuery = false;
            this.$parent.fullscreenLoading = false;
          }
        } else {
          this.$message.error(res.returnInfo);
        }
      });
    },
    closeTransferOwnerDialog() {
      this.transferClientOwnerDialog = false;
    },
    
    init(id) {
      // 判断为控制台，处理为单列布局
      if (this.detailType === "controlPanel") {
        this.id = id;
        this.columnNum = 1;
        this.oneColumnWidth = "100%";
        this.twoColumnWidth = "100%";
        this.detailSkeletonOption = [
          {
            type: "line",
            options: {
              active: true,
              row: 1,
              column: 1,
              width: "300px",
              height: "20px",
              justifyContent: "flex-start",
            },
          },
        ];
      }
      this.validRule = true;
      this.showDetail = false;
      this.isSkeleton = true;

      // 先判断记录有没有编辑权限
      this.$nextTick(() => {
        // 非锁定且可编辑状态才可编辑
        this.isEdit = this.systemManager || this.isEditDetail;
        this.isQuery = this.isQueryDetail || false;

        // 管理员或非锁定有更改所有人权限时显示 更改所有人按钮
        // eslint-disable-next-line vue/no-mutating-props
        this.canEditOwner =
          this.systemManager || (!this.isLocked && this.canEditOwner) || false;
        if (this.isQuery) {
          this.showDetail = true;
        }
        this.changePart(this.showPart);
      });
      this.ownerRaletionList = this.optional;
      this.inlineedit =
        localStorage.getItem("inlineedit") === "true" ? true : false;
      this.$parent.inlineedit = this.inlineedit;
    },
    // 切换页签展示详细信息、活动、动态
    
    changePart(partName) {
      if (partName === "detail") {
        this.operation = "DETAIL";
        this.getFormAttr();
      } else if (partName === "dynamicPublic") {
        this.dynamicPublics();
      } else if (partName === "chart") {
        this.getFormAttr();
      }
    },
    // 获取CCChat后台设置信息 控制是否展示微贴
    
    async dynamicPublics() {
      let res = await CommonObjApi.getSetupInfo();
      this.isShowdynamicPublics = res.data.isEnable;
      this.$nextTick(() => {
        if (res.data.isEnable == "true") {
          this.$refs.dynamicPublics && this.$refs.dynamicPublics.second();
        }
      });
    },
    // 获取记录类型
    
    getRecordType() {
      this.recordType = this.$refs.infoForm.formData["recordtype"];
      this.recordOptions = [];
      CommonObjApi.getRecordType({ prefix: this.prefix }).then((res) => {
        res.data &&
          res.data.recordTypeList &&
          res.data.recordTypeList !== null &&
          res.data.recordTypeList.forEach((record) => {
            this.recordOptions.push({
              val: record.id,
              key: record.name,
            });
          });
      });
    },
    // 事件类型的相关项处理
    getRelatedItem() {
      // 获取相关项
      CommonObjApi.getDialigItem({ type: this.objId }).then((res) => {
        if (res.result && res.returnCode === "1") {
          let relatedItem = {
            reItem: res.data.relateobjList,
            naItem: res.data.whoobjInfoList,
          };
          this.formAttr.map((item) => {
            if (item.prop === "relateid") {
              item.type = "eventTypeB";
              item.optionItem = relatedItem.reItem;
              item.width = "100%";
            }
            if (item.prop === "whoid") {
              item.type = "eventTypeA";
              item.optionItem = relatedItem.naItem;
              item.width = "100%";
            }
          });
        } else {
          this.$message.error(res.returnInfo);
        }
      });
      //
    },
  },
};
</script>

<style scoped lang="scss">
.detail-enter,
.detail-enter-to {
  height: 0;
}

.detail-enter-active,
.detail-leave-active {
  transition: all 0.4s ease;
}

::v-deep .el-tabs__item {
  font-size: 16px;
}

::v-deep .el-card__body {
  padding: 0px;
}

::v-deep .el-input__icon {
  line-height: 30px;
}

.el-dropdown-link {
  cursor: pointer;
  color: #409eff;
}
.el-icon-arrow-down {
  font-size: 12px;
}

.baseForm {
  // overflow: auto;
}

.changeDetailBox {
  position: relative;
  overflow: auto;
  margin-top: 10px;
  padding-bottom: 40px;
}

.changeDetailBtns {
  width: calc(66.7% - 35px);
  text-align: center;
  position: fixed;
  bottom: 0;
  padding: 10px 0;
  background: rgba(255, 255, 255, 0.7);
  z-index: 100;
}
// 控制台
.changeDetailBtns-control {
  width: 308px;
  text-align: center;
  position: fixed;
  bottom: 0;
  padding: 10px 0;
  background: rgba(255, 255, 255, 0.7);
  z-index: 100;
}

.rightChangeDetailBtns {
  width: calc(33.3% - 35px);
}

.main {
  .margintop-10 {
    margin-top: 10px;
  }
  .report-chart-box {
    min-height: 400px;
    .noData_2 {
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      font-size: 10px;
      color: #666666;
      margin-top: 100px;
      .div_temporarily_no_data {
        width: revert;
        height: revert;
        .img {
          width: 132px;
          height: 77px;
        }
      }
    }
  }

  .isEnableBox {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    height: 400px;
  }
}

::v-deep .el-checkbox__input.is-disabled.is-checked .el-checkbox__inner {
  background-color: #006dcc !important;
  border-color: #006dcc !important;
}

.standardprice::v-deep .el-input__suffix {
  position: absolute;
  top: -7px;
}
</style>
<style>
.suspension_popover {
  width: 99% !important;
}
</style>
