<template>
  <div class="relationbox">
    <!-- <div class="relationpage"
         v-loading="relationpageloading"> -->
    <div class="relationpage">
      <div class="personemail">
        <h2>
          <!-- 关联您的个人邮箱 -->
          {{ $t("label.emailobject.wizard.title") }}
        </h2>
        <p>
          <!-- 离开系统去您的邮箱与联系人进行邮件往来已成为过去，在CloudCC上，关联您的个人邮箱，可以直接从CloudCC发送邮件，同时自动关联您的潜在客户、联系人和业务机会，沟通<br />历史按时间线一目了然。 -->
          {{ $t("label.emailobject.wizard.desc") }}
        </p>
      </div>
      <div class="relationstep">
        <ul>
          <li>
            <i class="el-icon-check"></i>
            <span>
              <!-- 关联您的个人邮箱，在此发送邮件并自动同步到记录 -->
              {{ $t("label.emailobject.wizard.desc1") }}
            </span>
          </li>
          <li>
            <i class="el-icon-check"></i>
            <span>
              <!-- 跟踪电子邮件是否被打开及打开次数 -->
              {{ $t("label.emailobject.wizard.desc2") }}
            </span>
          </li>
          <li>
            <i class="el-icon-check"></i>
            <span>
              <!-- 使用电子邮件模版，节省更多时间 -->
              {{ $t("label.emailobject.wizard.desc3") }}
            </span>
          </li>
          <li>
            <i class="el-icon-check"></i>
            <span>
              <!-- 定制您的签名，让邮件看起来更美观 -->
              {{ $t("label.emailobject.wizard.desc5") }}
            </span>
          </li>
        </ul>
      </div>
      <div class="relationimg">
        <svg class="icon noDrafts" aria-hidden="true">
          <use href="#icon-nonews"></use>
        </svg>
      </div>
      <div class="relationbotton">
        <el-button type="primary" @click="Associated">
          <!-- 关联个人邮箱 -->
          {{ $t("label.emailobject.wizard.btn1") }}
        </el-button>
        <el-button @click="gosendemail">
          <!-- 已关联，去发邮件 -->
          {{ $t("label.emailobject.wizard.btn2") }}
        </el-button>
      </div>
    </div>
  </div>
</template>


<script>
import * as Emailobject from "../api.js";
import * as CommonObjApi from "../api.js";

export default {
  data() {
    return {
      // isemail: false, // 是否显示
      isrelationval: false,
      // relationpageloading: false,
      personalemailmsg: {},
      userUrl: "",
    };
  },
  mounted() {
    this.getuserinfomsg();
  },
  created() {},
  methods: {
    //关联个人邮箱
    Associated() {
      let userInfo = this.$store.state.userInfoObj;
      let path = `personalEmailBox?userid=${userInfo.userId}&groupid=${userInfo.orgId}&lange=${userInfo.language}&countryCode=${userInfo.countryCode}&timezone=${userInfo.timeZone}`;
      this.$router.push(`/profileSetting/${path}`);
    },
    //获取当前用户信息
    getuserinfomsg() {
      CommonObjApi.getUserInfo()
        .then((res) => {
          this.personalemailmsg = res.data;
        })
        .catch(() => {});
    },
    //当前用户已关联邮箱
    gosendemail() {
      Emailobject.getEmailPageInfo({})
        .then((res) => {
          this.isrelationval = res.data.relatedActiveMailSetting;
          this.userUrl = res.data.relatedActiveMailUrl;
          // this.relationpageloading = false;
          if (this.isrelationval == true) {
            this.$emit("AssociatedMailbox");
          } else {
            //您还未启用邮箱
            this.$message({
              showClose: true,
              message: this.$i18n.t(
                "vue_label_email_You_have_not_activated_the_mailbox"
              ),
              type: "warning",
            });
          }
        })
        .catch(() => {});
    },
  },
};
</script>


<style lang="scss" scoped>
.relationbox {
  width: 100%;
  height: 100%;
  padding-bottom: 10px;
  box-sizing: border-box;
}
.noDrafts {
  width: 218px;
  height: 133px;
}
.relationpage {
  width: 100%;
  height: 100%;
  background-color: #fff;
  border: 1px solid #dedcda;
  padding: 10px 30px 30px;
  .personemail {
    margin-bottom: 30px;
    h2 {
      font-weight: bold;
    }
    p {
      font-size: 14px;
      color: #333333;
    }
  }
  .relationstep {
    ul {
      li {
        margin-bottom: 20px;
        i {
          margin-right: 10px;
          color: #006dcc;
        }
        span {
          color: #080707;
          font-size: 14px;
          font-weight: bold;
        }
      }
    }
  }
  .relationimg {
    width: 173px;
    height: 104px;
    margin-top: 120px;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .relationbotton {
    margin-top: 100px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    ::v-deep .el-button--primary {
      height: 34px;
      // background-color: #006dcc;
      display: flex;
      align-items: center;
    }
    ::v-deep .el-button--default {
      display: flex;
      align-items: center;
      height: 34px;
    }
  }
}
</style>