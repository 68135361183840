<template>
  <div class="conversation-chatonline conversation">
    <div class="conversation-top">
      <div class="to-left">
        <div class="avater">
          <svg
            aria-hidden="true"
            class="icon"
            style="width: 100%; height: 100%"
          >
            <use href="#icon-headPortrait_outside_get"></use>
          </svg>
        </div>
        <div class="perInfo">
          <div>
            <span class="name">{{ webName }}</span>
            <span>{{ createdTime }}</span>
            <!-- 在线聊天 front-kefu-moudle-v1-online-at-->
            <span>{{ $t("front-kefu-moudle-v1-online-at") }}</span>
          </div>
        </div>
      </div>
      <div class="point-box">
        <el-dropdown
          @command="handleCommand"
          @visible-change="active = !active"
        >
          <div class="el-dropdown-link point-icon">
            <svg
              aria-hidden="true"
              class="icon"
              style="width: 100%; height: 100%"
            >
              <use
                :href="!active ? '#icon-full_more_def' : '#icon-full_more_cli'"
              ></use>
            </svg>
          </div>
          <el-dropdown-menu slot="dropdown" class="point-li">
            <el-dropdown-item
              v-for="(item, index) in moreOperationData"
              :command="item.type"
              :key="index"
              @mouseover.native="enter(index)"
              @mouseout.native="outer(index)"
            >
              <span class="point-icon">
                <svg
                  aria-hidden="true"
                  class="icon"
                  style="width: 100%; height: 100%"
                >
                  <use :href="!item.isHov ? item.icon : item.hovicon"></use>
                </svg> </span
              ><span> {{ item.text }}</span>
            </el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
    </div>
    <div class="conversation-content warp">
      <div class="content-chat">
        <div class="message chat-br">
          <ul>
            <li v-for="(item, index) in messageDate" :key="index">
              <span>{{ item.title }}</span>
              <span>{{ item.desc }}</span>
            </li>
          </ul>
        </div>
        <div class="chat-detail chat-br">
          <div
            v-show="isShowChatdetail"
            @click="isShowChatdetail = !isShowChatdetail"
          >
            <!-- 收起聊天详情 front-kefu-moudle-v1-Put-away-the-chat-->
            <span>{{ $t("front-kefu-moudle-v1-Put-away-the-chat") }}</span>
            <span class="icon">
              <svg
                aria-hidden="true"
                class="icon"
                style="width: 100%; height: 100%"
              >
                <use href="#icon-console_chat_putAway"></use>
              </svg>
            </span>
          </div>
          <div
            v-show="!isShowChatdetail"
            @click="isShowChatdetail = !isShowChatdetail"
          >
            <!-- 展开聊天详情 front-kefu-moudle-v1-Expand-chat-->
            <span>{{ $t("front-kefu-moudle-v1-Expand-chat") }}</span>
            <span class="icon">
              <svg
                aria-hidden="true"
                class="icon"
                style="width: 100%; height: 100%"
              >
                <use href="#icon-console_chat_open"></use>
              </svg>
            </span>
          </div>
        </div>
        <div
          class="question chat-br"
          v-show="isShowChatdetail"
          v-loading="showList"
        >
          <ul v-infinite-scroll="RollingLoad" v-if="ChatData.length != 0">
            <li
              v-for="(msg, index) in ChatData"
              :key="index"
              :style="{
                backgroundColor: msg.userType != 'Visitor' ? '#dde9fd' : '#fff',
              }"
            >
              <div class="chatName">
                {{ msg.name }}
              </div>
              <div class="chatMsg">
                <span v-if="msg.type == 'txt'">
                  <div>
                    <huanxinExpression
                      :expressionData="msg.msg"
                    ></huanxinExpression>
                  </div>
                </span>
                <!-- 转接给{{ TransferName }} -->
                <span v-if="msg.type == 'Transfer'">
                  {{
                    $t("front-kefu-moudle-v1-Expand-chat", {
                      TransferName: msg.TransferName,
                    })
                  }}
                </span>
                <span></span>
                <a
                  :href="msg.fileUrl"
                  v-if="msg.type == 'file' || msg.type == 'img'"
                  style="color: #444444"
                >
                  <svg aria-hidden="true" class="icon">
                    <use href="#icon-addassemblyblue"></use>
                  </svg>
                  {{ msg.fileName }}</a
                >
                <span>{{ msg.time }}</span>
              </div>
            </li>
          </ul>
          <!-- 暂无数据 vue_label_SMS_nodata-->
          <span
            v-if="ChatData.length == 0 && UnassociatedPrompt == 'success!'"
            class="nothing"
            >{{ $t("vue_label_SMS_nodata") }}</span
          >
          <!-- 获取消息记录失败，请联系管理员帮助 customer.message.fail.chat-->
          <span
            v-if="ChatData.length == 0 && UnassociatedPrompt != 'success!'"
            class="nothing"
            >{{ $t("customer.message.fail.chat") }}</span
          >
        </div>
      </div>
    </div>
    <separateCase
      :separateCaseDialog="separateCaseDialog"
      :recordId="recordId"
      @closeSeparate="closeSeparate"
    />
  </div>
</template>
<script>
import getTimeStringAutoShort from "@/utils/changeTimeMultitemporal.js";
import * as controlPanelApi from "../../api.js";
import separateCase from "@/views/controlPanel/components/conversion-components/components/separateCase.vue";
import huanxinExpression from "@/views/controlPanel/components/huanxinExpression.vue";

export default {
  components: {
    separateCase,
    huanxinExpression,
  },
  data() {
    return {
      separateCaseDialog: false,
      showList: false,
      recordId: "",
      caseid: this.$route.query.caseId,
      isShowChatdetail: false, // 是否显示聊天详情
      //创建时间
      createdTime: this.handleTime(this.conversationList.createdate),
      messageDate: [
        {
          //会话开始时间 front-kefu-moudle-v1-Session-start-time
          title: this.$i18n.t("front-kefu-moudle-v1-Session-start-time"),
          desc: this.handleTime(this.conversationList.conversationstarttime),
        },
        {
          //会话时长 front-kefu-moudle-v1-Session-duration
          title: this.$i18n.t("front-kefu-moudle-v1-Session-duration"),
          desc: this.conversationList.conversationduration,
        },
        {
          //等待时长 front-kefu-moudle-v1-Waiting-time
          title: this.$i18n.t("front-kefu-moudle-v1-Waiting-time"),
          desc: this.conversationList.waitingtime,
        },
        {
          //IP
          title: "IP: ",
          desc: this.conversationList.ipaddress,
        },
        {
          //地址 label.address
          title: this.$i18n.t("front-kefu-moudle-v1-Waiting-time") + ":",
          desc: this.conversationList.address,
        },
        {
          //发起页 front-kefu-moudle-v1-Launch-page
          title: this.$i18n.t("front-kefu-moudle-v1-Launch-page"),
          desc: this.conversationList.sourcepage,
        },
        {
          //系统版本 front-kefu-moudle-v1-System-version
          title: this.$i18n.t("front-kefu-moudle-v1-System-version"),
          desc: this.conversationList.systemversion,
        },
      ],
      ChatData: [],
      moreOperationData: [
        {
          //邮件回复 front-console-conversation-btn2
          text: this.$i18n.t("front-console-conversation-btn2"),
          icon: "#icon-full_replyMail_hov",
          hovicon: "#icon-full_replyMail_cli",
          isHov: false,
          type: "replyMail",
          index: 0,
        },
        {
          //电话回复 front-console-conversation-btn1
          text: this.$i18n.t("front-console-conversation-btn1"),
          icon: "#icon-full_callBack_hov",
          hovicon: "#icon-full_callBack_cli",
          isHov: false,
          type: "callBack",
          index: 1,
        },
        {
          //分解为新个案 front-console-conversation-btn4
          text: this.$i18n.t("front-console-conversation-btn4"),
          icon: "#icon-full_separationCase_hov",
          hovicon: "#icon-full_separationCase_cli",
          isHov: false,
          type: "separationCase",
          index: 2,
        },
      ], // 会话记录操作
      active: false, // 会话记录操作选中与否
      pageNum: 0,
      allPage: 0,
      isFirstRequest: "true",
      UnassociatedPrompt: "",
    };
  },
  computed: {
    webName() {
      return (
        this.conversationList.contactname ||
        this.conversationList.vvsitorname ||
        this.conversationList.visitornumber
      );
    },
  },
  props: {
    conversationList: {
      type: Object,
      default: () => {
        return {};
      },
    },
    EmailInfo: {
      type: Array,
      default: () => {
        return [];
      },
    },
    contactEmail: {
      type: String,
      default: "",
    },
    Num: {
      type: Number,
      default: 0,
    },
  },
  watch: {
    Num: {
      immediate: true,
      handler: function (newVal) {
        let that = this;
        if (newVal > 1) {
          that.moreOperationData = [
            {
              text: this.$i18n.t("front-console-conversation-btn2"),
              icon: "#icon-full_replyMail_hov",
              hovicon: "#icon-full_replyMail_cli",
              isHov: false,
              type: "replyMail",
              index: 0,
            },
            {
              text: this.$i18n.t("front-console-conversation-btn1"),
              icon: "#icon-full_callBack_hov",
              hovicon: "#icon-full_callBack_cli",
              isHov: false,
              type: "callBack",
              index: 1,
            },
            {
              text: this.$i18n.t("front-console-conversation-btn4"),
              icon: "#icon-full_separationCase_hov",
              hovicon: "#icon-full_separationCase_cli",
              isHov: false,
              type: "separationCase",
              index: 2,
            },
          ];
        } else {
          that.moreOperationData = [
            {
              text: this.$i18n.t("front-console-conversation-btn2"),
              icon: "#icon-full_replyMail_hov",
              hovicon: "#icon-full_replyMail_cli",
              isHov: false,
              type: "replyMail",
              index: 0,
            },
            {
              text: this.$i18n.t("front-console-conversation-btn1"),
              icon: "#icon-full_callBack_hov",
              hovicon: "#icon-full_callBack_cli",
              isHov: false,
              type: "callBack",
              index: 1,
            },
          ];
        }
      },
    },
    isShowChatdetail(status) {
      if (status) {
        this.ChatData = [];
        this.pageNum = 0;
        this.isFirstRequest = "true";
        this.showList = true;
        this.getCaseConversationDetail();
      }
    },
  },
  mounted() {
    this.$Bus.$on("is-show-detail", this.isShowDetailFn);
  },
  beforeDestroy() {
    this.$Bus.$off("is-show-detail", this.isShowDetailFn);
  },
  methods: {
    isShowDetailFn() {
      this.isShowChatdetail = false;
    },
    //转换时间戳
    handleTime(target) {
      function addDateZero(num) {
        return num < 10 ? "0" + num : num;
      }
      function handleHour(time) {
        let t = new Date(time);
        let hour = t.getHours();
        let min = addDateZero(t.getMinutes());
        let sec = addDateZero(t.getSeconds());
        if (hour < 12) {
          hour = addDateZero(hour);
          return hour + ":" + min + ":" + sec + " " + "AM";
        } else {
          hour -= 12;
          hour = addDateZero(hour);
          return hour + ":" + min + ":" + sec + " " + "PM";
        }
      }
      let targetTime = new Date(target);
      let day = 24 * 60 * 60 * 1000;
      let diffTime = targetTime.getTime() - day;
      let newTarget = new Date(diffTime);
      let now = new Date();
      // 获取目标日期
      let tDate = addDateZero(targetTime.getDate());
      let tMonth = addDateZero(targetTime.getMonth() + 1);
      let tYear = targetTime.getFullYear();
      let date1 = tYear + "-" + tMonth + "-" + tDate;
      // 获取目标日期前一天
      let nDate = addDateZero(newTarget.getDate());
      let nMonth = addDateZero(newTarget.getMonth() + 1);
      let nYear = newTarget.getFullYear();
      let date2 = nYear + "-" + nMonth + "-" + nDate;
      // 获取当前日期
      let nowDate = addDateZero(now.getDate());
      let nowMonth = addDateZero(now.getMonth() + 1);
      let nowYear = now.getFullYear();
      let date3 = nowYear + "-" + nowMonth + "-" + nowDate;

      if (date1 === date3) {
        return this.$i18n.t("today") + " " + handleHour(target);
      } else if (date2 === date3) {
        return this.$i18n.t("tomorrow") + " " + handleHour(target);
      } else {
        return date1 + " " + handleHour(target);
      }
    },
    RollingLoad() {
      if (this.allPage > 0) {
        this.allPage = this.allPage - 1;
        this.pageNum = this.allPage;
        this.getCaseConversationDetail();
      } else {
        return;
      }
    },
    //获取聊天记录
    async getCaseConversationDetail() {
      let obj = {};
      obj.conversationId = this.conversationList.huanxinchatid;
      obj.pageNum = this.pageNum;
      obj.pageSize = 10;
      if (this.isFirstRequest == "true") {
        obj.isFirstRequest = "true";
      }
      let resultData = await controlPanelApi.getChatRecord(obj);
      if (resultData.result) {
        if (this.isFirstRequest == "true") {
          this.allPage = resultData.data.totalPages - 1;
        }
        this.isFirstRequest = "false";
        this.showList = false;
        this.UnassociatedPrompt = resultData.returnInfo;
        if (resultData.data.entities.length != 0) {
          resultData.data.entities.forEach((item) => {
            //cmd为事件类型消息---当前需求过滤掉此类型
            if (
              item.body.bodies[0].type != "cmd" &&
              item.body.bodies[0].msg != ""
            ) {
              let obj = {};
              obj.name = item.fromUser.nicename;
              obj.userType = item.fromUser.userType;
              obj.msg = item.body.bodies[0].msg;
              obj.type = item.body.bodies[0].type;
              if (obj.type == "file") {
                obj.fileName = item.body.bodies[0].filename;
                obj.fileUrl = item.body.bodies[0].new_url;
              }
              if (obj.type == "img") {
                obj.fileName = item.body.bodies[0].filename;
                obj.fileUrl = item.body.bodies[0].new_url;
              }
              obj.time = getTimeStringAutoShort(
                item.body.timestamp,
                true,
                this.$store.state.userInfoObj.timeZone
              );
              this.ChatData.push(obj);
            }
            //转接类型的
            if (
              item.body.bodies[0].type == "cmd" &&
              item.body.bodies[0].action == "ServiceSessionTransferedEvent"
            ) {
              let obj = {};
              obj.type = "Transfer";
              obj.TransferName = `{${item.body.event.eventObj.cloudccUserName}}`;
              obj.time = getTimeStringAutoShort(
                item.body.timestamp,
                true,
                this.$store.state.userInfoObj.timeZone
              );
              this.ChatData.push(obj);
            }
          });
        }
      }
    },
    enter(index) {
      this.moreOperationData[index].isHov = true;
    },
    outer(index) {
      this.moreOperationData[index].isHov = false;
    },

    // 点击菜单项
    handleCommand(command) {
      // 电话回复
      if (command == "callBack") {
        let conf = {
          caseid: this.caseid,
        };
        controlPanelApi.getContactInfo(conf).then((res) => {
          let phone = res.data[0].shouji || res.data[0].dianhua;
          this.$bus.$emit("newCLick", phone);
        });
      }
      // 拆分新个案
      else if (command == "separationCase") {
        controlPanelApi.getPermissionById({ id: this.caseid }).then((res) => {
          if (res.data.isEdit) {
            this.separateCaseDialog = true;
            this.recordId = this.conversationList.id;
          } else {
            this.$message.warning(
              this.$i18n.t("front-kefu-moudle-v1-Operation-without0-permission")
            );
          }
        });
      } else if (command == "replyMail") {
        let obj = this.EmailInfo.length == 0 ? {} : this.EmailInfo[0];
        obj.fromaddress = this.contactEmail;
        this.$emit("toSend", true, obj, false);
      }
    },

    closeSeparate() {
      this.separateCaseDialog = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.point-box {
  display: flex;
  align-items: center;
}
.conversation {
  margin: 20px 0;
  .conversation-top {
    width: 100%;
    display: flex;
    justify-content: space-between;
    .to-left {
      display: flex;
      justify-content: space-between;

      .avater {
        width: 48px;
        height: 48px;
        margin-right: 20px;
      }
      .perInfo {
        display: flex;
        align-items: center;
        color: #666;
        > div {
          height: 22px;
          line-height: 22px;
        }
        span {
          margin-right: 12px;
        }
        .name {
          font-size: 16px;
          color: #333;
        }
      }
    }
    .point-icon {
      cursor: pointer;
      width: 30px;
      height: 20px;
    }
  }
  .warp {
    width: 86%;
    margin-left: 67px;
    margin-top: 16px;
  }
  .conversation-content {
    .content-chat {
      .chat-br {
        border-radius: 4px;
        border: 1px solid #dedcda;
        margin: 4px 0;
      }
      .message {
        padding: 16px;
        > ul {
          display: inline-block;
          width: 100%;
          > li {
            height: 24px;
            line-height: 24px;
            font-size: 12px;
            overflow: hidden; //超出隐藏
            text-overflow: ellipsis; //显示省略号
            white-space: nowrap; //强制不换行
          }
          > li:nth-child(odd) {
            background: #fafaf9;
          }
        }
      }
      .chat-detail {
        cursor: pointer;
        font-size: 12px;
        color: #006dcc;
        text-align: center;
        .icon {
          display: inline-block;
          width: 16px;
          height: 16px;
          padding: 0 5px;
          margin-top: 1.5px;
        }
      }
      .question {
        ul {
          width: 100%;
          min-height: 200px;
          max-height: 350px;
          overflow-y: scroll;
          li {
            display: flex;
            align-items: center;
            border-top: 1px solid #dedcda;
            .chatName {
              width: 30%;
              padding: 10px 10px 10px 0;
              border-right: 1px solid #dedcda;
              text-align: right;
              word-wrap: break-word;
            }
            .chatMsg {
              width: 70%;
              padding: 10px;
              display: flex;
              align-items: center;
              justify-content: space-between;
            }
          }
        }
        .nothing {
          width: 100%;
          height: 200px;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
    }
  }
}
// 搁置样式
.point-li {
  border: 0;
  .point-icon {
    display: inline-block;
    width: 30px;
    height: 20px;
    margin-right: 5px;
    position: relative;
    top: 4px;
  }
}
</style>