<!--
 * @Author: 张亚欣
 * @Date: 2021-09-09 13:56:46
 * @LastEditTime: 2022-02-17 14:34:22
 * @LastEditors: Please set LastEditors
 * @Description: 项目详情页布局调整
 * @FilePath     : \lightning-web\src\views\commonObjects\project-detail\index.vue
-->
<template>
  <div class="top" v-loading="fullscreenLoading">
    <!-- 骨架屏 -->
    <div class="home" v-show="!isQuery && !fullscreenLoading">
      <div class="noPermissionBox">
        <svg class="icon" aria-hidden="true">
          <use href="#icon-file404"></use>
        </svg>
        <p class="noPermissionTip">
          <!-- 抱歉，您无权限查看该条数据，请联系系统管理员。 -->
          {{ $t("vue_label_notice_nopermission_view") }}
        </p>
        <p style="text-align: center">
          <el-button type="primary" size="mini" @click="goBack">
            <!-- 返回上一级 -->
            {{ $t("label.goback") }}
          </el-button>
        </p>
      </div>
    </div>
    <div class="home" v-show="isQuery" id="contentMainBox" ref="home">
      <!-- 用户对象新增的头部 -->
      <detailsPersonal
        v-if="!isShowType"
        :id="id"
        :follow="follow"
        :fieldValue="userName"
      ></detailsPersonal>

      <div :class="routerName === 'DETAIL' ? 'contentBox' : 'contentBoxs'">
        <div
          :class="['box_top', { isBgColor: detailType === 'controlPanel' }]"
          id="box_top"
          v-show="isShowType"
        >
          <div class="mainContantBox">
            <i
              v-if="detailType !== 'controlPanel'"
              class="iconfont iconjiantou-zuo left"
              @click="goBack"
            ></i>
            <span
              v-if="detailType === 'controlPanel'"
              style="padding-left: 32px"
            ></span>
            <!-- 基本信息 -->
            <div class="box_header">
              <svg class="icon" aria-hidden="true">
                <use
                  :href="tabStyle ? '#icon-' + tabStyle : '#icon-hometab'"
                ></use>
              </svg>
            </div>
            <div class="title">
              <p class="title_main">
                <!-- 项目名称和选中标签 -->
                <span
                  class="projectNameAndTag"
                  id="projectNameAndTag"
                  ref="projectNameAndTagWidht"
                >
                  <!--项目名称 -->
                  <span
                    class="projectName"
                    id="projectName"
                    ref="projectName"
                    >{{ bigTitle[0] ? bigTitle[0].fieldValue : "" }}</span
                  >
                  <!-- 层级结构 -->
                  <el-tooltip
                    popper-class="my-tooltip"
                    class="item"
                    effect="dark"
                    :content="content"
                    placement="top"
                    v-if="isCustomer"
                  >
                    <i
                      style="
                        margin-left: 0 0 -2px 10px;
                        cursor: pointer;
                        position: absolute;
                        top: 27px;
                      "
                      @contextmenu.prevent.stop="routers"
                      @click.prevent.stop="routers"
                    >
                      <svg
                        class="icon"
                        aria-hidden="true"
                        style="width: 18px; height: 18px"
                      >
                        <use href="#icon-cengji"></use>
                      </svg>
                    </i>
                  </el-tooltip>
                  <!-- 标签 -->
                  <span
                    class="projectTag"
                    ref="projectTag"
                    @click="showTagMethod"
                  >
                    <svg
                      v-if="isTag === 'true'"
                      class="icon iconTag"
                      aria-hidden="true"
                    >
                      <use href="#icon-tag"></use>
                    </svg>
                    <span>
                      <el-tag
                        v-for="item in labelArr"
                        :key="item.id"
                        :type="item.type"
                        effect="plain"
                        style="cursor: pointer"
                        :style="{
                          background:
                            item.color === 'hong'
                              ? hong
                              : item.color === 'huang'
                              ? huang
                              : item.color === 'lan'
                              ? lan
                              : item.color === 'hui'
                              ? hui
                              : item.color === 'lv'
                              ? lv
                              : '',
                          color: color,
                        }"
                        >{{ item.name }}</el-tag
                      >
                    </span>
                  </span>
                </span>
                <!-- 隐藏标签 -->
                <span
                  class="tagClosed"
                  @click="showTagMethod"
                  v-show="showEllipsis"
                  >...</span
                >
                <!-- 编辑标签按钮 -->
                <el-link
                  v-if="isTag === 'true'"
                  class="link"
                  @click="customBtn"
                >
                  <!-- 添加标签 -->
                  {{
                    labelArr.length > 0
                      ? $t("label.tag.edittags")
                      : $t("label.tag.addtags")
                  }}
                </el-link>
              </p>
            </div>
          </div>
          <div class="header_right">
            <!-- 付款按钮(只有在伙伴账户订单对象 且订单未支付时显示) -->
            <el-button
              size="mini"
              class="button edit_product"
              style="margin: 16px 10px 0 0; height: 28px"
              @click="goToPay"
              v-if="showPayButton"
              type="primary"
            >
              <span>
                <!-- 付款 -->
                {{ $t("label.quickbooks.payments") }}
              </span>
            </el-button>
            <!-- 编辑报价行(只有在报价单对象下才会有) -->
            <el-button
              size="mini"
              class="button edit_product"
              style="margin: 16px 10px 0 0; height: 28px"
              @click="editProduct"
              v-if="editQuoteline"
            >
              <span>
                <!-- 编辑报价行 -->
                {{ editQuoteline }}
              </span>
            </el-button>
            <!-- 编辑阶梯折扣(只有在折扣计划对象下才会有) -->
            <el-button
              size="mini"
              class="button edit_product"
              style="margin: 16px 10px 0 0; height: 28px"
              @click="editDiscount"
              v-if="editDiscountTiers"
            >
              <span>
                <!-- 编辑阶梯折扣 -->
                {{ editDiscountTiers }}
              </span>
            </el-button>
            <!-- <div style="margin-right: 10px;width:100px"></div> -->
            <el-button-group
              style="margin-right: 10px; width: 96px"
              v-if="isShowGanttButton"
            >
              <!-- <el-button
                size="mini"
                class="button"
                @click="toGannt('isGantt')"
                >{{ $t("label.projectManagement.gantt") }}aaaaaa</el-button
              >
              <el-popover
                placement="bottom-end"
                popper-class="pop_box"
                trigger="hover"
              >
                <div class="xiala" slot="reference">
                  <svg class="icon" aria-hidden="true">
                    <use href="#icon-blue_sj"></use>
                  </svg>
                </div>
                <ul class="moreButton" style="width: 100px">
                  <li
                    style="cursor: pointer"
                    @click="toGannt('kanbanTaskPage')"
                  > -->
              <!-- 任务看板 -->
              <!-- {{ $t("label.projectManagement.kanban") }}
                  </li>
                  <li style="cursor: pointer" @click="toGannt('isGantt2')"> -->
              <!-- 资源使用视图 -->
              <!-- {{ $t("label.projectManagement.Resource.usage.view") }}
                  </li>
                </ul>
              </el-popover>-->
            </el-button-group>
            <el-button-group style="margin-right: 10px">
              <!-- <el-button
                  size="mini"
                  class="button"
                  v-if="isShowGanttButton"
                  @click="toGannt()"
                >
                  甘特图
                </el-button>-->
              <el-button size="mini" class="button" @click="setFollow(follow)">
                <svg
                  class="icon"
                  style="width: 12px; margin-top: -1px; margin-right: 3px"
                  v-show="
                    this.follow === $t('label.chatter.follow') ||
                    this.follow === $t('label.chatter.followed')
                  "
                  aria-hidden="true"
                >
                  <use
                    :href="
                      this.follow === $t('label.chatter.follow')
                        ? '#icon-gz'
                        : '#icon-yes'
                    "
                  ></use>
                </svg>
                <span @mouseover="mouseover" @mouseout="mouseout">
                  {{ follow }}
                </span>
              </el-button>
              <!-- 群策 勿删 -->
              <el-button
                size="mini"
                class="button"
                @click="judgeIsHas"
                v-if="
                  isShowChat &&
                  (this.objId === 'account' || this.objId == 'opportunity') &&
                  //是否为伙伴云用户
                  !this.Partnercloud
                "
              >
                <svg
                  class="icon qcImg"
                  aria-hidden="true"
                  style="font-size: 14px; margin-top: -3px; margin-right: 3px"
                >
                  <use :href="qcImg"></use>
                </svg>
                <span>
                  {{ $t("label_mobile_buttom_team") }}
                </span>
              </el-button>
            </el-button-group>
            <el-button-group>
              <el-button
                class="button"
                size="mini"
                v-for="(item, index) in showButton"
                :key="index"
                @click="recordOperationBtn(item)"
                >{{ item.label }}</el-button
              >
              <el-popover
                placement="bottom-end"
                popper-class="pop_box"
                trigger="click"
                :visible-arrow="false"
                v-if="moreButton.length > 0"
              >
                <div class="xiala" slot="reference">
                  <svg class="icon" aria-hidden="true">
                    <use href="#icon-blue_sj"></use>
                  </svg>
                </div>
                <ul class="moreButton">
                  <li
                    class="li"
                    v-for="(item, index) in moreButton"
                    :key="index"
                    @click="recordOperationBtn(item)"
                    style="cursor: pointer"
                  >
                    <span
                      v-if="item.name === 'manageExternalUsers' ? false : true"
                      >{{ item.label }}</span
                    >
                    <span v-else>
                      <el-popover
                        placement="left-start"
                        width="150"
                        trigger="hover"
                        popper-class="external"
                      >
                        <ul class="moreButtons" style="font-size: 12px">
                          <li
                            v-for="(item, index) in partner"
                            :key="index"
                            @click="external(item)"
                            style="cursor: pointer"
                          >
                            {{ item.label }}
                          </li>
                        </ul>
                        <span slot="reference" tyle="cursor:pointer">
                          {{ item.label }}
                        </span>
                      </el-popover>
                    </span>
                  </li>
                </ul>
              </el-popover>
            </el-button-group>
            <!--审批-->
            <el-button-group>
              <el-button
                class="button"
                style="margin-left: 10px"
                size="mini"
                v-for="(item, index) in showHistoryBtn"
                :key="index"
                @click="historyDeal(item)"
                >{{ item.label }}</el-button
              >
              <el-popover
                placement="bottom-end"
                popper-class="pop_box"
                trigger="hover"
                v-if="hideHistoryBtn.length > 0"
              >
                <div class="xiala" slot="reference">
                  <svg class="icon" aria-hidden="true">
                    <use href="#icon-blue_sj"></use>
                  </svg>
                </div>
                <ul class="moreButton" style="width: 100px">
                  <li
                    v-for="(item, index) in hideHistoryBtn"
                    :key="index"
                    @click="historyDeal(item)"
                    style="cursor: pointer"
                  >
                    {{ item.label }}
                  </li>
                </ul>
              </el-popover>
            </el-button-group>

            <div class="dayin">
              <svg class="icon" aria-hidden="true" @click="printDetail">
                <use href="#icon-dayin_d"></use>
              </svg>
            </div>
          </div>
        </div>
      </div>
      <!-- 展示所有标签的弹框 -->
      <el-dialog
        title="查看标签"
        :visible.sync="showAllTags"
        top="15%"
        width="373px"
        :before-close="handleCloseAllTags"
      >
        <div class="allTagDialog">
          <el-tag
            v-for="item in labelArr"
            :key="item.id"
            :type="item.type"
            effect="plain"
            style="cursor: pointer"
            :style="{
              background:
                item.color === 'hong'
                  ? hong
                  : item.color === 'huang'
                  ? huang
                  : item.color === 'lan'
                  ? lan
                  : item.color === 'hui'
                  ? hui
                  : item.color === 'lv'
                  ? lv
                  : '',
              color: color,
            }"
            >{{ item.name }}</el-tag
          >
        </div>
      </el-dialog>
      <!-- 添加标签 -->
      <add-label
        ref="addlabel"
        :ids="id"
        :detail="detail"
        :labelArr="labelArr"
        @updateDelLabelArr="getDelLabelArr"
      >
      </add-label>
      <div
        :class="routerName === 'DETAIL' ? 'clears' : ''"
        v-if="isShowType"
      ></div>
      <!-- 关键信息 -->
      <el-card style="border-top: none" class="box-card" v-show="isShowType">
        <Wang-skeleton
          v-if="isSkeleton"
          type="custom"
          :childrenOption="childrenOptions"
          style="background: #fff"
        />
        <!-- 简介 -->
        <div class="introduce" v-else>
          <div
            class="introduce_content"
            v-for="(item, index) in heightLight"
            :key="index"
          >
            <div v-if="item.isShowMenus === true">
              <span class="introduce_title">
                {{ item.fieldLabel }}
                <el-tooltip
                  popper-class="my-tooltip"
                  class="item"
                  effect="dark"
                  :content="content"
                  placement="top"
                  v-if="isCustomer"
                >
                  <svg
                    class="icon"
                    aria-hidden="true"
                    v-if="
                      item.fieldName === 'parent' || item.fieldName === 'zsss'
                        ? 'true'
                        : false
                    "
                    @contextmenu.prevent.stop="routers"
                    @click.prevent.stop="routers"
                  >
                    <use href="#icon-cengji"></use>
                  </svg>
                </el-tooltip>
              </span>
              <br />
              <!-- 不是005开头的就是队列 ,队列不跳转-->
              <span
                :class="[
                  'introduce_cet',
                  item.fieldType === 'Y' ||
                  item.fieldType === 'M' ||
                  item.fieldType === 'MR'
                    ? 'briefDetail_s'
                    : '',
                ]"
                @click="
                  item.fieldType === 'Y' ||
                  item.fieldType === 'M' ||
                  item.fieldType === 'MR'
                    ? briefDetail(item)
                    : ''
                "
              >
                <em
                  style="font-style: normal"
                  v-if="item.fieldType === 'F' && item.fieldValue"
                  >{{ item.fieldValue | datetimeFormat(countryCode) }}</em
                >
                <em
                  style="font-style: normal"
                  v-else-if="item.fieldType === 'D' && item.fieldValue"
                  >{{ item.fieldValue | dateFormat(countryCode) }}</em
                >
                <em
                  style="font-style: normal"
                  v-else-if="item.fieldType === 'B'"
                >
                  <el-checkbox
                    :value="item.fieldValue === 'true' ? true : false"
                    disabled
                  >
                  </el-checkbox>
                </em>
                <em
                  style="font-style: normal"
                  v-else-if="item.fieldName === 'facebook'"
                >
                  <svg
                    class="icon"
                    aria-hidden="true"
                    v-if="item.fieldValue.indexOf('facebook.com') != -1"
                    style="font-size: 18px; height: 18px; cursor: pointer"
                    @click="jumpNew(item.fieldValue)"
                    @contextmenu.prevent="jumpNew(item.fieldValue)"
                  >
                    <use :href="facebookImgBlue"></use>
                  </svg>
                  <svg
                    class="icon"
                    aria-hidden="true"
                    v-else
                    style="font-size: 18px; height: 18px; cursor: pointer"
                  >
                    <use :href="facebookImg"></use>
                  </svg>
                </em>
                <em
                  style="font-style: normal"
                  v-else-if="item.fieldName === 'linkedin'"
                >
                  <svg
                    class="icon"
                    aria-hidden="true"
                    v-if="item.fieldValue.indexOf('linkedin.com') != -1"
                    style="font-size: 18px; height: 18px; cursor: pointer"
                    @click="jumpNew(item.fieldValue)"
                    @contextmenu.prevent="jumpNew(item.fieldValue)"
                  >
                    <use :href="linkedinImgBlue"></use>
                  </svg>
                  <svg
                    class="icon"
                    aria-hidden="true"
                    v-else
                    style="font-size: 18px; height: 18px; cursor: pointer"
                  >
                    <use :href="linkedinImg"></use>
                  </svg>
                </em>
                <em
                  style="font-style: normal"
                  v-else-if="item.fieldName === 'twitter'"
                >
                  <svg
                    class="icon"
                    aria-hidden="true"
                    v-if="item.fieldValue.indexOf('twitter.com') != -1"
                    style="font-size: 18px; height: 18px; cursor: pointer"
                    @click="jumpNew(item.fieldValue)"
                    @contextmenu.prevent="jumpNew(item.fieldValue)"
                  >
                    <use :href="twitterImgBlue"></use>
                  </svg>
                  <svg
                    class="icon"
                    aria-hidden="true"
                    v-else
                    style="font-size: 18px; height: 18px; cursor: pointer"
                  >
                    <use :href="twitterImg"></use>
                  </svg>
                </em>
                <!-- 潜在客户打分 -->
                <em
                  v-else-if="item.fieldName === 'leadscore'"
                  style="display: flex; padding-right: 7px"
                  class="cannotEdit"
                >
                  <span
                    style="
                      width: 25px;
                      font-style: normal;
                      display: inline-block;
                    "
                    >{{ item.fieldValue }}</span
                  >
                  <el-progress
                    :percentage="
                      item.fieldValue !== undefined && item.fieldValue !== null
                        ? Number(item.fieldValue)
                        : 0
                    "
                    :color="customColors"
                    :format="formatProgress"
                  >
                  </el-progress>
                </em>
                <!-- url -->
                <em
                  v-else-if="item.fieldType === 'U'"
                  @click="jumpTo(item.fieldValue)"
                  style="font-style: normal; color: #006dcc; cursor: pointer"
                  >{{ item.fieldValue }}
                </em>
                <em style="font-style: normal" v-else>{{ item.fieldValue }}</em>
              </span>

              <!-- if(item.fieldType === 'F'){
                item.fieldValue = item.fieldValue|this.datetimeFormat(this.countryCode)
              }-->
            </div>
          </div>
        </div>
      </el-card>
      <!-- ROI卡片 -->
      <roiCard v-show="objectApi === 'campaign'" ref="roiCard"></roiCard>

      <!-- 进度条 -->
      <Progress
        v-if="isShowType"
        ref="Progress"
        :isconvert="isconvert"
        :viewId="viewId"
        :data-id="id"
        :isEdit="isEdit"
        :inlineedit="inlineedit"
        :canEditOwner="isCanEditOwner"
        :bigTitle="bigTitle"
        :prefixs="prefix"
        :objId="objId"
        :objectApi="objectApi"
      ></Progress>

      <!-- <div style="height: 200px;"></div> -->

      <!-- 相关列表 -->
      <relation-list
        ref="listChild"
        id="listChild"
        :detailType="detailType"
        :detailGroupId="detailGroupId"
        :isShowType="isShowType"
        :tabName="tabName"
        :dataId="id"
        :fieldValue="fieldValue"
        :bigTitle="bigTitle[0] ? bigTitle[0].fieldValue : ''"
        :isTag="isTag"
        :detailLayoutMode="detailLayoutMode"
        :objectApi="objectApi"
        :prefix="prefix"
        :isEditDetail="isEdit"
        :isQueryDetail="isQuery"
        :locked="isLocked"
        :canEditOwner="isCanEditOwner"
        :screenWidth="screenWidth"
        :routerName="routerName"
        :conversationNum="conversationNum"
        @refreshAll="refreshAll"
        @refreshUpload="refreshUpload"
        @topHistoryBtn="getHistoryBtn"
        @topHistorySubmit="getHistorySubmit"
        @resetObjectInfo="resetObjectInfo"
        @convert="convert"
        @getPricebook="getPricebook"
        @customBtn="customBtn"
        @dataListes="dataListes"
        @personsNameMethod="personsNameMethod"
        :typedisabled="typedisabled"
        @getstate="getstate"
        @objectSwitch="objectSwitch"
      >
      </relation-list>
      <!-- 项目360 -->
      <div
        class="project360"
        :style="{
          flexDirection: detailLayoutMode === 'mode1' ? 'row' : 'row-reverse',
        }"
        v-if="
          everyObj.relatedlistId === 'project360' && detailType === 'project'
        "
      >
        <!-- 项目360-左侧 -->
        <el-card
          shadow="never"
          :style="{
            height: 'auto',
            width:
              detailLayoutMode === 'mode1' ? 'calc(66.7% - 10px)' : '33.3%',
            margin: '10px 10px 0 0',
            border: '1px solid #dddbda',
          }"
          style="padding: 0 15px 0 10px"
        >
          <div>
            <div class="projectBox">
              <!-- 详细信息 -->
              <div class="draggaBox">
                <draggable
                  v-model="draggArr"
                  group="site"
                  animation="1000"
                  class="draggaContent"
                  :sort="false"
                  :move="(e, originalEvent) => onMove(e, originalEvent, 'left')"
                  @add="(e) => addDragTab(e, 'left')"
                >
                  <div
                    class="draggTabs"
                    v-for="item in draggArr"
                    :key="item.id"
                    :class="{ draggTabsActive: project360Left === item.id }"
                    :style="{
                      cursor: draggArr.length < 2 ? 'pointer' : 'move',
                    }"
                    @click="changePart(item, 'left')"
                  >
                    {{ item.name }}
                  </div>
                </draggable>
              </div>

              <!-- 二列和多列自适应布局切换 -->
              <el-tooltip
                popper-class="my-tooltip"
                effect="dark"
                :content="$t('label_switch_two_column_or_responsive_layout')"
                placement="top-start"
              >
                <ul
                  class="changeColumnBtnGroup"
                  v-if="
                    project360Left === 'detail' && detailLayoutMode === 'mode1'
                  "
                >
                  <li
                    @click="changeColumns('twoColumns')"
                    style="border-right: 1px solid #dedcda"
                  >
                  <svg
                  class="icon setColumnsIcon"
                  aria-hidden="true"
                >
                  <use :fill="detailMode === 'twoColumns'?'#006dcc':'#f5f5f5'" href="#icon-baocunbuju"></use>
                </svg>
                  </li>
                  <li @click="changeColumns('responsiveLayout')">
                    <svg
                      v-if="detailMode === 'responsiveLayout'"
                      class="icon setColumnsIcon autoColumnsIcon"
                      aria-hidden="true"
                    >
                      <use href="#icon-autoColumns-active"></use>
                    </svg>
                    <svg
                      v-else
                      class="icon setColumnsIcon autoColumnsIcon"
                      aria-hidden="true"
                    >
                      <use href="#icon-autoColumns"></use>
                    </svg>
                  </li>
                </ul>
              </el-tooltip>
            </div>
          </div>

          <project360
            ref="project360-left"
            id="listChild"
            position="left"
            :isShowType="isShowType"
            :tabName="tabName"
            :showPart="project360Left"
            :dataId="id"
            :detailLayoutMode="detailLayoutMode"
            :objectApi="objectApi"
            :prefix="prefix"
            :isEditDetail="isEdit"
            :isQueryDetail="isQuery"
            :locked="isLocked"
            :canEditOwner="isCanEditOwner"
            :bigTitle="bigTitle"
            :postShowType="postShowType"
            :tabheight="height"
            :activeName="activeName"
            @refreshAll="refreshAll"
            @refreshUpload="refreshUpload"
            @topHistoryBtn="getHistoryBtn"
            @topHistorySubmit="getHistorySubmit"
            @resetObjectInfo="resetObjectInfo"
            @convert="convert"
            @getPricebook="getPricebook"
            @customBtn="customBtn"
            @dataListes="dataListes"
            @personsNameMethod="personsNameMethod"
            @getstate="getstate"
          ></project360>
        </el-card>

        <!-- 布局切换按钮 -->
        <div class="layoutSwitchBox" @click="switchLayout">
          <svg aria-hidden="true" slot="reference">
            <use href="#icon-layoutSwitchImg2"></use>
          </svg>
        </div>

        <!-- 项目360-右侧 -->
        <el-card
          :style="{
            height: 'auto',
            width:
              detailLayoutMode === 'mode1' ? '33.3%' : 'calc(66.7% - 10px)',
            margin: '10px 10px 0 0',
          }"
          style="padding: 0 15px 0 10px"
        >
          <div class="activeDinamic">
            <draggable
              v-model="activeDinamicArr"
              group="site"
              animation="1000"
              class="activeDinamicContent"
              :sort="false"
              :move="(e, originalEvent) => onMove(e, originalEvent, 'right')"
              @add="(e) => addDragTab(e, 'right')"
            >
              <div
                class="activeDinamicTabs"
                v-for="item in activeDinamicArr"
                :key="item.id"
                :class="{ draggTabsActive: project360Right === item.id }"
                :style="{
                  cursor: activeDinamicArr.length < 2 ? 'pointer' : 'move',
                }"
                @click="changePart(item, 'right')"
              >
                {{ item.name }}
              </div>
            </draggable>
            <project360
              ref="project360-right"
              id="listChild"
              position="right"
              :isShowType="isShowType"
              :tabName="tabName"
              :showPart="project360Right"
              :dataId="id"
              :detailLayoutMode="detailLayoutMode"
              :objectApi="objectApi"
              :prefix="prefix"
              :isEditDetail="isEdit"
              :isQueryDetail="isQuery"
              :locked="isLocked"
              :canEditOwner="isCanEditOwner"
              :bigTitle="bigTitle"
              :postShowType="postShowType"
              :tabheight="height"
              :activeName="activeName"
              @refreshAll="refreshAll"
              @refreshUpload="refreshUpload"
              @topHistoryBtn="getHistoryBtn"
              @topHistorySubmit="getHistorySubmit"
              @resetObjectInfo="resetObjectInfo"
              @convert="convert"
              @getPricebook="getPricebook"
              @customBtn="customBtn"
              @dataListes="dataListes"
              @personsNameMethod="personsNameMethod"
              @getstate="getstate"
            ></project360>
          </div>
        </el-card>
      </div>
      <!-- 会话 -->
      <div
        class="conversation"
        v-if="
          everyObj.relatedlistId === 'conversation' &&
          detailType === 'controlPanel'
        "
      >
        <conversation-related
          ref="conversationRelated"
          @updateConverNum="updateConverNum"
        ></conversation-related>
      </div>
      <!-- 活动 -->
      <div
        class="conversation conversationMargin"
        v-if="
          everyObj.relatedlistId === 'projectActivity' &&
          detailType === 'controlPanel'
        "
      >
        <activity
          ref="activitys"
          :objectApi="objectApi"
          :tabName="tabName"
          :bigTitle="bigTitle"
          :dataId="id"
          :prefix="prefix"
          :objId="objId"
          :theme="theme"
          :activeList="activeList"
        ></activity>
      </div>
      <!-- 动态 -->
      <div
        class="conversation conversationMargin"
        v-if="
          everyObj.relatedlistId === 'projectCccat' &&
          detailType === 'controlPanel'
        "
      >
        <dynamicPublic
          v-show="isShowdynamicPublics == 'true'"
          ref="dynamicPublics"
          :recordName="bigTitle"
          :postShowType="postShowType"
          :tabheight="height"
          :activeName="activeName"
          :fieldValue="userName"
          :isShowType="isShowType"
          :dataId="id"
        ></dynamicPublic>
      </div>

      <!-- 相关列表 -->
      <!-- 除项目360、实际工作清单 -->
      <el-card
        class="box-main_main"
        shadow="never"
        v-if="
          everyObj.relatedlistId !== 'project360' &&
          everyObj.relatedlistId !== 'conversation' &&
          everyObj.relatedlistId !== 'projectActivity' &&
          everyObj.relatedlistId !== 'projectCccat' &&
          everyObj.relatedlistId !== 'aee2020029648D89WP76' &&
          everyObj.relatedlistId !== 'aee2021B4717F5B0VhWd' &&
          everyObj.relatedlistId !== 'aee202142389BA3ZTat1'&&
          everyObj.relatedlistType!=='CloudccProjectActualWorkList'&&
          everyObj.relatedlistType !=='cloudccCalendar'&&
          everyObj.relatedlistType !== 'cloudccGanttChart'
        "
      >
        <related-object
          ref="relatedObject"
          :id="id"
          :obj="everyObj"
          :detailType="detailType"
          :bigTitle="bigTitle[0].fieldValue"
          :tabName="tabName"
          :objId="objId"
          :detailLayoutMode="detailLayoutMode"
          :typedisabled="typedisabled"
        ></related-object>
      </el-card>
      <el-card
        class="worklist"
        shadow="never"
        v-if="everyObj.relatedlistType=='CloudccProjectActualWorkList'"
      >
        <!-- 实际工作清单 -->
        <actualworklist
          :id="id"
          @setRelationList="setRelationList"
          :time="checkTime"
        ></actualworklist>
      </el-card>
      <!-- 资源使用 -->
      <el-card
        shadow="never"
        class="box-main_main box-main_main1 resource"
        v-if="everyObj.relatedlistType=='cloudccCalendar'"
      >
        <resourceManage
          ref="resourceManage"
          :projectId="selectproject"
          @refreshRelatedList="refreshRelatedList"
        />
      </el-card>
      <!-- 甘特图 -->
      <el-card
        shadow="never"
        class="box-main_main box-main_main2"
        v-if="everyObj.relatedlistType== 'cloudccGanttChart'"
      >
        <CanttComponent
          ref="ganttChild"
          class="left-container"
          :proId="selectproject"
          @refreshRelatedList="refreshRelatedList"
        ></CanttComponent>
      </el-card>
    </div>
    <!-- 共享弹窗 -->
    <Share ref="isShare" :shares="shares"></Share>

    <!-- pdf预览 -->
    <pdf-viewer
      :is-show="pdfViewerAttr.isShow"
      :top="3"
      :title="pdfViewerAttr.title"
      :pdfUrl="pdfViewerAttr.pdfUrl"
      :fullScreen="false"
      @cancel="closeViewerDialog"
      @saveToAppendix="saveToAppendix"
    >
    </pdf-viewer>

    <!-- 共享弹窗 -->
    <synchro ref="synchro"></synchro>
    <!-- 详情页写死 type = active
    修改： 王钰祥 -->
    <!-- 详情页复制/编辑记录 -->
    <create-edit-obj
      ref="createEditObj"
      :prefix="prefix"
      :id="id"
      :object-api="objectApi"
      :object-name="tabName"
      :dataListPartner="dataList"
      @Refreshes="Refreshes"
      @save="save"
      type="active"
    ></create-edit-obj>
    <el-dialog
      :title="
        objId === 'account'
          ? $t('vue_label_commonobjects_detail_group_strategy')
          : $t('vue_label_commonobjects_detail_group_opportunitystrategy')
      "
      :visible.sync="isShowTag"
      width="40%"
      :append-to-body="true"
      custom-class="qunce-dialog"
    >
      <div>
        <!-- 加入后，该群策将显示在您的群策列表，群策动态会实时推送给您。 -->
        {{ $t("vue_label_commonobjects_detail_real_time") }}
      </div>
      <el-button @click="cancelDia" class="cancel">
        <!-- 取消 -->
        {{ $t("button_partner_cancel") }}
      </el-button>
      <el-button type="primary" @click="joinGroup">
        <!-- 加入 -->
        {{ $t("chatter.group.join") }}
      </el-button>
    </el-dialog>

    <!-- 删除数据和解除锁定 -->
    <el-dialog
      :visible.sync="dialogVisible"
      :title="dialogAttr.title"
      top="15%"
      width="27%"
      :close-on-click-modal="false"
      :before-close="handleClose"
    >
      <span style="font-size: 16px; font-weight: bold">{{
        dialogAttr.content
      }}</span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false" size="mini">
          <!-- 取消 -->
          {{ $t("button_partner_cancel") }}
        </el-button>
        <el-button @click="confirm" type="primary" size="mini">
          <!-- 确认 -->
          {{ $t("label_chatter_ok") }}
        </el-button>
      </span>
    </el-dialog>

    <!-- 伙伴云 -->
    <el-dialog
      custom-class="partnercloud"
      :title="$t('label_partner')"
      :visible.sync="isPartnerCloud"
      width="69.3%"
      :before-close="handleCloses"
    >
      <el-table :data="tableData" border style="width: 100%">
        <!-- 名称 -->
        <el-table-column
          prop="name"
          :label="$t('label_chatter_name')"
          width="180"
        ></el-table-column>
        <!-- 描述 -->
        <el-table-column
          prop="description"
          :label="$t('label.file.des')"
          width="180"
        ></el-table-column>
        <el-table-column label="URL">
          <template slot-scope="scope">
            <el-link type="primary" :href="scope.row.url" target="_blank">
              {{ scope.row.url }}
            </el-link>
          </template>
        </el-table-column>
      </el-table>
      <span slot="footer" class="dialog-footer">
        <el-button @click="isPartnerCloud = false">
          <!-- 取 消 -->
          {{ $t("button_partner_cancel") }}
        </el-button>
      </span>
    </el-dialog>
    <!-- 置顶按钮 -->
    <svg class="icon backtop" aria-hidden="true" @click="backtop">
      <use href="#icon-detail-top1"></use>
    </svg>
    <div style="height: 0; overflow: hidden">
      <ApprovalHistory
        v-show="true"
        ref="uponDetailHistory"
        :historyID="id"
        :itemTitle="fieldValue"
      ></ApprovalHistory>
    </div>
    <!-- 回退公海池弹框-->
    <seas-pool
      :seaShow.sync="seaShow"
      :objid="valSea"
      @clientPoolCancle="clientPoolCancle"
      :objectApi="objectApi"
    ></seas-pool>
    <!-- 公告发布范围弹框 -->
    <ggReleaseRange
      :ggRecordid="id"
      :isShowggRecord="isShowggRecord"
      @closeGgRecord="closeGgRecord"
    ></ggReleaseRange>
    <!-- 添加 删除标签 -->
    <addLabel></addLabel>
    <!-- 迁移资产 -->
    <AssetsMoveDialogVisible
      ref="assetsBOM"
      :prefix="prefix"
      :id="id"
      :object-api="objectApi"
      :object-name="tabName"
      :dataListPartner="dataList"
      @updateDetail="refreshAll"
    />

    <!-- 销售云 报价表 -->
    <CreatedQuotationDialog
      ref="createdQuotationDialog"
      :id="id"
      :objId="objId"
      :templateList="templateList"
      :quotationDialogVisible="quotationDialogVisible"
      @closeQuotaionDialog="closeQuotaionDialog"
    ></CreatedQuotationDialog>
    <!-- 销售云 选择报价单模板   -->
    <SelectTemplate
      ref="selectTemplate"
      :id="id"
      :objId="objId"
      :templateList="templateList"
      :selectTemplateDialogVisible="selectTemplateDialogVisible"
      @closeSelectTemplateDialog="closeSelectTemplateDialog"
    ></SelectTemplate>
    <!-- 销售云 选择报价单文件 -->
    <selectquotation
      :id="id"
      :objId="objId"
      ref="selectquotation"
      :dialogTableVisible="dialogTableVisible"
      @closedselectquotation="closedselectquotation"
    >
    </selectquotation>
    <!-- 发送邮件页面 -->
    <SendEmailDetail
      :enclosurearr="enclosurearr"
      :dialogVisibleEmail="dialogVisibleEmail"
      @closeEmailDialog="closeEmailDialog"
    ></SendEmailDetail>
    <!--    条形码，二维码弹窗-->
    <code-dialog ref="codeStyleDialog"> </code-dialog>
  </div>
</template>

<script>
import Vue from "vue";
import vueWangIview from "vue-wang-iview"; // 骨架屏
import "vue-wang-iview/dist/vue-wang-iview.css";
Vue.use(vueWangIview);
// 导入draggable组件
import draggable from "vuedraggable";
import SendEmailDetail from "@/views/quotationEmail/components/sendEmailDetail.vue";
import SelectTemplate from "@/views/quotationEmail/components/selectTemplate.vue";
// import treeTransfer from "el-tree-transfer";
import project360 from "./project360.vue";
import relationList from "./relation-list.vue";
import relatedObject from "./related-object";
import addLabel from "@/components/LabelView/addTag.vue";
import dynamicPublic from "../../colleagueCircle/components/dynamic-public.vue";
import activity from "../activity.vue";
import ApprovalHistory from "@/views/approval/components/approval-history.vue";
import roiCard from "../detailChild/ROIcard.vue";
import seasPool from "../detailChild/seasPool.vue";
import { Share, Progress, PdfViewer } from "@/components/index.js";
import job from "@/views/chat/js/request.js";
import synchro from "@/components/synchro/index";
import * as CommonObjApi from "./api";
import createEditObj from "../components/create-edit-obj.vue";
// import operatingTime from "../components/OperatingTime.vue";
import detailsPersonal from "@/views/colleagueCircle/components/details/DetailsPersonal.vue";
import {
  isOpenAsset,
  getPaymentStatus,
  getSetupInfo,
  GetBrief,
  GetTagsByRecordId,
  GetDelete,
  GetSharegetShareRuleList,
  SavePdfToFile,
  getObjectGetObjectInfo,
  getPartnerGetPartnerPermission,
  getPartnerPartnerEnableAccount,
  getPartnerPartnerEnableContact,
  getPartnerQueryAllPartnerByLogin,
  setFollow,
  isFollow,
  cloudccInvoicesToQBInvoices,
} from "./api.js";
import VueCookies from "vue-cookies";
import Bus from "@/mixin/bus.js";
import debounce from "lodash.debounce";
import * as Time from "@/utils/time";
import ggReleaseRange from "../components/ggReleaseRange.vue";
import AssetsMoveDialogVisible from "../components/Assets-move-dialog.vue"; //迁移资产
import CodeDialog from "../components/CodeDialog.vue"; //资产二维码
import CreatedQuotationDialog from "@/views/quotationEmail/components/createdQuotationDialog.vue";
import selectquotation from "@/views/quotationEmail/components/selectquotation.vue";
import actualworklist from "@/views/actualworklist/index.vue";
import resourceManage from "@/views/gantt/resourceManage/index.vue";
import CanttComponent from "@/components/cloudcc-gantt/views/index.vue";
import ConversationRelated from "@/views/controlPanel/components/conversation-related.vue";

export default {
  name: "Activity",
  components: {
    draggable,
    SelectTemplate,
    CreatedQuotationDialog,
    selectquotation,
    relationList,
    project360,
    activity,
    addLabel,
    Share,
    dynamicPublic,
    Progress,
    roiCard,
    PdfViewer,
    synchro,
    createEditObj,
    ApprovalHistory,
    detailsPersonal,
    // operatingTime,
    seasPool, //回退公海池弹框
    ggReleaseRange, //公告发布范围弹框
    AssetsMoveDialogVisible, //迁移资产
    CodeDialog, //资产二维码
    // treeTransfer,
    SendEmailDetail, //发送邮件
    relatedObject, //相关列表
    actualworklist, //实际工作清单
    resourceManage, //资源使用
    CanttComponent, //甘特图
    ConversationRelated, //会话
  },
  props: {
    dataId: {
      type: String,
      default: "",
    },
    // 是否来自服务控制台
    detailType: {
      type: String,
      default: "project",
    },
  },
  data() {
    return {
      // 是否是审批中记录的二次删除，值为true时是
      isConfirmDel:'',
      draggArr: [
        { id: "detail", name: this.$i18n.t("label.report.details") }, //详细信息
        {
          id: "chart",
          name: this.$i18n.t("vue_label_commonobjects_view_chart"),
        }, //图表
      ],
      activeDinamicArr: [
        //详情页360 右侧侧tabs
        { id: "activity", name: this.$i18n.t("vue_label_normal_activity_sf") }, //活动
        { id: "dynamicPublic", name: this.$i18n.t("label.ccchat.2018") }, //动态
      ],
      project360Left: "detail", // 左侧项目360部分名称
      project360Right: "activity", // 左侧项目360部分名称
      detailMode: "",
      showAllTags: false, //展示所有标签的弹窗
      windowWidth: document.documentElement.clientWidth, //实时屏幕宽度
      showEllipsis: false, //标签省略号的显示隐藏
      templateList: [], //模板列表
      selectTemplateDialogVisible: false, //选择模板
      layoutid: "", //布局id
      isShowdynamicPublics: "", // 是否启用微贴
      showPayButton: false, //是否展示付款按钮
      isOpenAssetBom: false, // 资产BOM权限判断是否开启
      isShowggRecord: false, //公告菜单下  标准按钮更改成 发布范围按钮
      valSea: "",
      seaShow: false, //回退公海池
      theme: "", // 主题value
      detailLayoutMode: "mode1",
      //是否为伙伴云用户
      Partnercloud: this.$store.state.userInfoObj.ispartner,
      //群策按钮是否显示
      isShowChat:
        VueCookies.get("countryCode") == "CN" &&
        localStorage.getItem("chatOpen") == "true" &&
        localStorage.getItem("login") == "success",
      dialogAttr: {
        title: "",
        content: "",
      },
      inlineedit: false,
      dynamicType: false, //更改右侧动态tab
      isShowType: true, //判断用户详情页面
      qcImg: "#icon-qc",
      qunceType: "",
      //加入群策需要的数据
      qunceData: {},
      //显示加入群策弹框
      isShowTag: false,
      //当前群策的群ID
      detailGroupId: "",
      //客户群策所有人
      groupStrategyOwnerid: {}, //获取用户的环信ID
      detail: "detail",
      content: this.$i18n.t("vue_label_commonobjects_detail_company_hierarchy"), //公司层级结构
      isCustomer: false,
      isShowAssets: false, //查看资产BOM
      customFlag: false,
      height: "100%",
      active: 0,
      activeName: "first",
      labelArr: [],
      buttonList: [],
      heightLight: [],
      moreButton: [],
      showButton: [],
      color: "#fff",
      hong: "#F6817B",
      huang: "#FEC366",
      lan: "#62A5F3",
      hui: "#C8C6C6",
      lv: "#7cedcc",
      id: this.$route.params.id || this.dataId || this.$router.query.dataId,
      objectApi: "",
      bigTitle: [],
      fieldValue: "",
      tabName: "",
      shares: [],
      follow: "",
      pdfViewerAttr: {
        title: this.$i18n.t("pagecreator_button_preview"), //"预览",
        isShow: false,
        pdfUrl: null,
      },
      objId: this.$route.query.objId || this.objid,
      prefix: "",
      // 右上角审批按钮
      showHistoryBtn: [],
      hideHistoryBtn: [],
      printVisible: false, //详情打印弹窗
      isconvert: "",
      viewId: "",
      pricebookArr: [],
      isQuery: null, // 记录可查权限
      isCanEditOwner: "", //判断是否有更改所以人权限
      isTag: "",
      routerName: "",
      activeList: [],
      isLocked: false, //是否锁定
      isEdit: false, // 编辑权限
      postShowType: "detailInfo", //查询帖子的类型
      recordName: "",
      copyType: "",
      userName: "",
      dialogVisible: false, //删除弹窗给
      childrenOptions: [
        // 列表
        {
          type: "list",
          options: {
            row: 2,
            column: 3,
            width: "80px",
            height: "16px",
            backgroundColor: "#f3f2f2",
            justifyContent: "center",
            active: false,
            itemRowMargin: "8px 8px 8px 26px",
            firstWidth: "50px",
            lastWidth: "80px",
            alignItems: "center",
          },
        },
      ],
      isSkeleton: false,
      partner: [],
      ismanagePartnerUser: null,
      ispartner: null,
      dataList: [],
      isPartnerCloud: false,
      tableData: [],
      userId: "",
      tabStyle: "",
      isShowGanttButton: false, //控制显示甘特图按钮
      screenWidth: document.body.clientWidth,
      timer: false,
      realObjId: "", //对象的objId
      dateFormat: Time.dateFormat(this.$cookies.get("countryCode")),
      datetimeFormat: Time.datetimeFormat(this.$cookies.get("countryCode")),
      countryCode: this.$cookies.get("countryCode"),
      operatingTimeObj: {}, // 服务区域下的操作时间
      twitterImg: "#icon-twitter",
      linkedinImg: "#icon-Linkedin",
      facebookImg: "#icon-facebook",
      twitterImgBlue: "#icon-twitter_blue",
      linkedinImgBlue: "#icon-Linkedin_blue",
      facebookImgBlue: "#icon-facebook_blue",
      underDetailSubmitStatus: true, //提交待审批状态
      editQuoteline: "", //编辑报价行按钮
      editDiscountTiers: "", //编辑阶梯折扣按钮
      filterText: "",
      briefHeight: 0, //简介的高度
      typedisabled: "",
      fullscreenLoading: false,
      quotationDialogVisible: false,
      dialogTableVisible: false,
      //发送邮件弹框是否显示
      dialogVisibleEmail: false,
      is_master_version: "", //文章是否为主版本
      customColors: [
        { color: "#70acf1", percentage: 20 },
        { color: "#f7c97e", percentage: 40 },
        { color: "#faa58c", percentage: 100 },
      ],
      enclosurearr: [], //要发送的报价单文件
      everyObj: {
        relatedlistId: "project360",
      },
      selectproject: "", //项目id
      conversationNum: 0, // 会话相关列表数据个数
      checkTime:[],//实际工作清单时间传参
    };
  },
  beforeRouteEnter(to, from, next) {
    if (VueCookies.get("detailLayoutMode") === null) {
      VueCookies.set("detailLayoutMode", "mode1", { sameSite: 'Strict' });
    }
    next((vm) => {
      // 通过 `vm` 访问组件实例
      vm.detailLayoutMode = VueCookies.get("detailLayoutMode");
    });
  },
  beforeRouteUpdate(to, from, next) {
    if (to.query.viewId) {
      this.viewId = to.query.viewId;
    }
    this.id = to.params.id;
    this.init();
    document.getElementById("contentMainBox").scrollTop = 0;
    this.$nextTick(() => {
      this.$refs.listChild.id = this.id;
      this.$refs.listChild.init();
      this.$refs["project360-left"].init();
      this.$refs["project360-right"].init();
    });
    next();
  },
  created() {
    this.isSkeleton = true;
    this.routerName = this.$route.params.oprateType;
    this.viewId = this.$router.history.current.query.viewId;
    this.init();
  },
  mounted() {
    var that = this;
    // <!--把window.onresize事件挂在到mounted函数上-->
    window.onresize = () => {
      return (() => {
        window.fullWidth = document.documentElement.clientWidth;
        that.windowWidth = window.fullWidth; // 宽
      })();
    };
    this.getIsHasGroup();

    // 如果是资产，获取用户资产操作权限
    if (this.id.substring(0, 3) === "s42") {
      this.getAssetBomPermission();
    }

    if (VueCookies.get("detailLayoutMode") === null) {
      VueCookies.set("detailLayoutMode", "mode1", { sameSite: 'Strict' });
    }
    this.detailLayoutMode = VueCookies.get("detailLayoutMode");
    this.$Bus.$on("deliver-group-strategy-ownerid", this.deliverGroupStrategyOwneridFn);
    // 服务区域下的操作时间
    this.$Bus.$on("service-territory-data", this.serviceTerritoryDataFn);
    // 恢复提交待审批状态
    this.$Bus.$on("recovery-submit-status", this.recoverySubmitStatusFn);
    //获取窗口宽度
    Bus.$on("windowResize", this.windowResizeFn);
    //提交待审批后局部刷新页面
    this.$Bus.$on("approvalrefresh", this.approvalrefreshFn);
    // 监听编辑页面布局设置
    Bus.$on("setColumnsMode", this.setColumnsModeFn);
    // 项目名称、标签及操作区域 宽度样式
    this.$nextTick(() => {
      this.proiectStyle();
      // 从实际工作清单详情跳转过来
      if(this.$route.query.ischeck==1){
        // this.$refs.listChild.ischecked = 6
        this.everyObj.relatedlistType="CloudccProjectActualWorkList"
        // this.checkTime=this.$route.query.dateTime
      }
    });
  },
  beforeDestroy() {
    this.$Bus.$off("deliver-group-strategy-ownerid", this.deliverGroupStrategyOwneridFn);
    // 服务区域下的操作时间
    this.$Bus.$off("service-territory-data", this.serviceTerritoryDataFn);
    // 恢复提交待审批状态
    this.$Bus.$off("recovery-submit-status", this.recoverySubmitStatusFn);
    //获取窗口宽度
    Bus.$off("windowResize", this.windowResizeFn);
    //提交待审批后局部刷新页面
    this.$Bus.$off("approvalrefresh", this.approvalrefreshFn);
    // 监听编辑页面布局设置
    Bus.$off("setColumnsMode", this.setColumnsModeFn);
  },
  methods: {
    deliverGroupStrategyOwneridFn(res) {
      this.groupStrategyOwnerid = res;
    },
    serviceTerritoryDataFn(res) {
      this.operatingTimeObj = res;
    },
    recoverySubmitStatusFn() {
      this.underDetailSubmitStatus = true;
    },
    windowResizeFn(offsetHeight, offsetWidth) {
      this.screenWidth = offsetWidth;
    },
    approvalrefreshFn() {
      this.refreshAll();
    },
    setColumnsModeFn(detailMode) {
      this.changeColumns(detailMode);
    },
   // 文章分类判断是否为主版本
    getstate() {
      this.is_master_version = this.$refs.listChild.is_master_version;
    },
    // 详情页360 tabs切换
    changePart(tab, position) {
      if (position === "left") {
        this.project360Left = tab.id;
        this.$refs["project360-left"].changePart(this.project360Left);
      } else {
        this.project360Right = tab.id;
        this.$refs["project360-right"].changePart(this.project360Right);
      }
    },
    // 项目360tab拖拽事件
    onMove(e, originalEvent, position) {
      if (position === "left") {
        if (this.draggArr.length < 2) return false;
      } else {
        if (this.activeDinamicArr.length < 2) return false;
      }
      return true;
    },
    // 拖拽标签后显示对应部分
    addDragTab(e, position) {
      this.$nextTick(() => {
        if (position === "left") {
          this.draggArr.forEach((item) => {
            if (item.name === e.clone.innerHTML.trim()) {
              this.project360Left = item.id;
              this.project360Right = this.activeDinamicArr[0].id;
            }
            this.$refs["project360-left"].changePart(this.project360Left);
            this.$refs["project360-right"].changePart(this.project360Right);
          });
        } else {
          this.$nextTick(() => {
            this.activeDinamicArr.forEach((item) => {
              if (item.name === e.clone.innerHTML.trim()) {
                this.project360Right = item.id;
                this.project360Left = this.draggArr[0].id;
              }
              this.$refs["project360-right"].changePart(this.project360Right);
              this.$refs["project360-left"].changePart(this.project360Left);
            });
          });
        }
      });
    },
    // 二列布局和响应式布局切换
    changeColumns(columns) {
      this.$refs["project360-left"] &&
        this.$refs["project360-left"].changeColumns(columns);
      this.detailMode = VueCookies.get(`${this.objectApi}DetailMode`);
    },
    // 项目名称和所选标签所占屏幕宽度百分比样式
    proiectStyle() {
      // 获取窗口宽度的1/4  和  1/2
      let offsetClientWidth = this.windowWidth / 4;
      let offsetClientWidthTwo = this.windowWidth / 2;
      // 获取项目名称
      let projectNameDom = document.getElementById("projectName");
      // 设置项目名称的样式
      projectNameDom.style.maxWidth = offsetClientWidth + "px";
      projectNameDom.style.overflow = "hidden";
      projectNameDom.style.whiteSpace = "nowrap";
      projectNameDom.style.textOverflow = "ellipsis";
      projectNameDom.style.display = "inline-block";
      // 获取项目名称和标签的总和
      let projectDom = document.getElementById("projectNameAndTag");
      // 设置项目名称和标签的样式
      projectDom.style.maxWidth = offsetClientWidthTwo + "px";
      projectDom.style.display = "inline-block";
      projectDom.style.overflow = "hidden";
      projectDom.style.whiteSpace = "nowrap";

      // 赋值 项目名称和标签的总宽度
      let projectNameWidth = this.$refs.projectName.offsetWidth;
      let projectTagWidth = this.$refs.projectTag.offsetWidth;
      let projectNameAndTagWidht = projectTagWidth + projectNameWidth;
      //
      if (projectNameAndTagWidht < offsetClientWidthTwo) {
        this.showEllipsis = false;
      } else {
        this.showEllipsis = true;
      }
      this.$forceUpdate();
    },
    // 展示隐藏的标签
    showTagMethod() {
      this.showAllTags = true;
    },
    // 关闭展示所有标签的弹窗
    handleCloseAllTags() {
      this.showAllTags = false;
    },
    closeSelectTemplateDialog(flg, data) {
      this.selectTemplateDialogVisible = false;
      if (flg) {
        this.$refs.createdQuotationDialog.init(data);
        this.quotationDialogVisible = true;
      }
    },
    // 关闭生成报价单弹窗
    closeQuotaionDialog(val, item) {
      this.quotationDialogVisible = false;
      if (item) {
        this.dialogVisibleEmail = true;
        this.enclosurearr = item;
      } else {
        this.dialogVisibleEmail = false;
      }
    },
    //关闭选择报价单弹框
    closedselectquotation(val, selectval) {
      this.dialogTableVisible = val;
      if (selectval) {
        this.enclosurearr = selectval;
        this.dialogVisibleEmail = true;
      } else {
        this.dialogVisibleEmail = false;
      }
    },
    //关闭邮件弹框
    closeEmailDialog() {
      this.dialogVisibleEmail = false;
      this.enclosurearr = [];
    },
    // 获取CCChat后台设置信息
    async getSetupInfo() {
      let res = await getSetupInfo();
      this.isShowdynamicPublics = res.data.isEnable;
      if (res.data.isEnable == "true") {
        this.$refs.dynamicPublics.second();
      }
    },
    goToPay() {
      let totalPrice = "";
      this.heightLight.map((item) => {
        if (item.fieldName == "totalamount") {
          totalPrice = item.fieldValue.split(" ")[1];
        }
      });
      totalPrice = totalPrice.replace(",", "");
      this.$router.push({
        path: `/orderPay/${totalPrice}/${this.id}`,
      });
    },
    // 是否展示付款按钮
    async isPayButton() {
      let result = await getPaymentStatus({ orderid: this.id });
      if (result.data.paymentstatus == "未付款") {
        this.showPayButton = true;
      } else {
        this.showPayButton = false;
      }
    },
    // 如果是资产，获取用户资产操作权限
    getAssetBomPermission() {
      isOpenAsset({}).then((res) => {
        if (res.result) {
          this.isOpenAssetBom = res.data || false;
        }
      });
    },
    // 关闭公告发布范围弹窗
    closeGgRecord(ggdata) {
      this.isShowggRecord = ggdata;
    },
    // 点击跳转新页面
    jumpNew(newHref) {
      window.open(newHref);
    },
    // 左右布局切换
    switchLayout() {
      this.detailLayoutMode =
        this.detailLayoutMode === "mode1" ? "mode2" : "mode1";
      VueCookies.set("detailLayoutMode", this.detailLayoutMode, { sameSite: 'Strict' });
      this.$refs["project360-left"] &&
        this.$refs["project360-left"].changeColumns(
          VueCookies.get(`${this.objectApi}DetailMode`)
        );
    },
    //根据视口宽度计算简介内容展示
    meunsFilter() {
      let itemWidth = 0;
      let intLen = document.getElementsByClassName("introduce_content");
      for (var i = 0; i <= intLen.length; i++) {
        if (intLen[i]) {
          itemWidth = intLen[i].offsetLeft + 300;
        }
        if (itemWidth >= this.screenWidth) {
          if (this.heightLight[i]) {
            this.heightLight[i].isShowMenus = false;
          }
        } else {
          if (this.heightLight[i]) {
            this.heightLight[i].isShowMenus = true;
          }
        }
      }
    },
    // 获取从子组件list传过来的详情页跳转用户名
    personsNameMethod(name) {
      this.userName = name;
      document.title = this.$setTitle(`${name}`);
    },
    // 刷新整个页面
    refreshAll() {
      this.init();
      document.getElementById("contentMainBox").scrollTop = 0;
      this.$nextTick(() => {
        //获取roi卡片数据
        if (this.objectApi && this.objectApi === "campaign") {
          this.$refs.roiCard && this.$refs.roiCard.init();
        }
        this.$refs.uponDetailHistory&&this.$refs.uponDetailHistory.getApprovalPreview();
      });
    },
    //刷新文件列表
    refreshUpload() {
      this.$nextTick(() => {
        this.$refs.listChild.init();
        this.$refs["project360-left"].init();
        this.$refs["project360-right"].init();
        //获取roi卡片数据
        if (this.objectApi && this.objectApi === "campaign") {
          this.$refs.roiCard && this.$refs.roiCard.init();
        }
        this.$refs.uponDetailHistory&&this.$refs.uponDetailHistory.getApprovalPreview();
      });
    },
    joinGroup() {
      if (this.qunceType == "exist") {
        this.$Bus.$emit("create-strategy-groupexist", this.qunceData);
        this.isShowTag = false;
      } else if (this.qunceType == "notexist") {
        if (Object.keys(this.groupStrategyOwnerid).length > 0) {
          if (this.groupStrategyOwnerid.value == "") {
            this.$message({
              showClose: true,
              type: "warning",
              message: this.$i18n.t(
                "vue_label_commonobjects_detail_a_group_strategy"
              ), //该条数据缺少所有人,无法创建群策
            });
            return false;
          }
          if (this.groupStrategyOwnerid.id == localStorage.getItem("myid")) {
            this.qunceData.groupIdArr = [
              JSON.parse(localStorage.getItem("userInfo")).userId,
            ];
            this.qunceData.groupName =
              this.bigTitle && this.bigTitle[0].fieldValue;
            this.qunceData.groupNames = localStorage.getItem("username");
            this.qunceData.userIdArr = localStorage.getItem("myid");
            this.$Bus.$emit(
              "create-strategy-allnotexist",
              this.qunceData,
              this.id
            );
          } else {
            this.qunceData.groupIdArr = [
              JSON.parse(localStorage.getItem("userInfo")).userId,
            ];
            this.qunceData.groupName =
              this.bigTitle && this.bigTitle[0].fieldValue;
            this.qunceData.userIdArr = [localStorage.getItem("myid")];
            this.getUserInfor(this.groupStrategyOwnerid.id, this.qunceData);
          }
        } else {
          this.$message({
            showClose: true,
            type: "warning",
            message: this.$i18n.t(
              "vue_label_commonobjects_detail_customer_details"
            ), //正在加载客户详情
          });
          return false;
        }
        this.isShowTag = false;
      }
    },
    cancelDia() {
      this.isShowTag = false;
    },
    //判断业务机会或客户是否创建了群策
    async getIsHasGroup() {
      let result = await job.judgeIsHasStrategy(this.id);
      if (
        result.result == true &&
        result.data &&
        result.data.strategyGroupId &&
        result.data.strategyGroupId != ""
      ) {
        this.detailGroupId = result.data.strategyGroupId;
      }
    },
    //群策获取客户信息
    judgeIsHas: debounce(
      async function () {
        let result = await job.judgeIsHasStrategy(this.id);
        if (result.result == true && result.data) {
          let item = {};
          item.strategyType = result.data.objid;
          if (
            result.data.strategyGroupId &&
            result.data.strategyGroupId != ""
          ) {
            let groupMessage = await job.getgroupmessage(
              result.data.strategyGroupId
            );
            if (groupMessage.result == true && groupMessage.data) {
              item.name = groupMessage.data.name;
              item.id = result.data.strategyGroupId;
            }
          } //存在群策，并且已经加入
          if (
            result.data.existStrategyGroup == "true" &&
            result.data.joinStrategyGroup == "true"
          ) {
            this.$Bus.$emit("create-strategy-allexist", item);
          } //存在群策，但没有加入
          if (
            result.data.existStrategyGroup == "true" &&
            result.data.joinStrategyGroup == "false"
          ) {
            //打开群策对应的群
            this.isShowTag = true;
            this.qunceType = "exist";
            this.qunceData = item;
          } //不存在群策
          if (
            result.data.existStrategyGroup == "false" &&
            result.data.joinStrategyGroup == "false"
          ) {
            this.isShowTag = true;
            this.qunceType = "notexist";
            this.qunceData = item;
          }
        }
      },
      3000,
      { leading: true, trailing: false }
    ),

    async getUserInfor(id, item) {
      let userId = await job.getUserInformation(id);
      if (userId.result == true && userId.data) {
        item.groupIdArr.push(userId.data[0].imUserId);
        item.groupNames = userId.data[0].name;
        item.userIdArr.push(userId.data[0].id);
        item.userIdArr = item.userIdArr.join();
        this.$Bus.$emit("create-strategy-allnotexist", item, this.id);
      }
    },
    init() {
      this.isQuery = null;
      this.isShowType = false;
      this.fullscreenLoading = true;
      this.isFollow();
      sessionStorage.setItem("id", this.$route.params.id);
      if (this.detailType === "controlPanel") {
        this.everyObj.relatedlistId = "conversation";
      }

      // 获取记录查看权限，无权限时给提示，可返回上一级
      CommonObjApi.getPermissionById({ id: this.id }).then((res) => {
        if (res.returnCode === "1" && res.result) {
          this.objectApi = res.data.objectApi;
          this.prefix = res.data.prefix;
          this.isEdit = res.data.isEdit;
          this.realObjId = res.data.objId;
          this.isLocked = res.data.isLocked;
          this.isCanEditOwner = res.data.canEditOwner;
          this.fullscreenLoading = false;
          this.detailMode = VueCookies.get(`${this.objectApi}DetailMode`);

          if (res.data.isQuery) {
            this.isQuery = true;
            this.isShowType = true;
            if (res.data.objId === "user") {
              this.isShowType = false;
              this.isQuery = true;
              this.dynamicType = true;
              this.activeName = "second";
              this.$refs.dynamicPublics.personDetailPage();
              this.$refs.listChild.init();
              this.$refs["project360-left"].init();
              this.$refs["project360-right"].init();
            } else {
              this.getBrief();
              this.getTagsByRecordId();
              this.$refs.listChild.init();
              if (this.detailType === "controlPanel") {
                this.$nextTick(() => {
                  this.$refs["conversationRelated"].init(this.dataId);
                });
              } else {
                this.$refs["project360-left"].init();
                this.$refs["project360-right"].init();
              }
            }
          } else {
            this.isQuery = false;
          }
        } else {
          this.fullscreenLoading = false;
          this.$message.error(res.returnInfo);
        }
      });
    },
    // 鼠标悬浮
    mouseover() {
      // 当已关注后鼠标悬浮变成取消关注
      if (this.follow === this.$i18n.t("label.chatter.followed")) {
        this.follow = this.$i18n.t("vue_label_CCChat_unfollow");
      }
    },
    // 当按钮为取消关注时鼠标离开变成已关注
    mouseout() {
      if (this.follow === this.$i18n.t("vue_label_CCChat_unfollow")) {
        this.follow = this.$i18n.t("label.chatter.followed");
      }
    },
    // 判断是否关注
    async isFollow() {
      let params = {
        id: this.id,
      };
      let res = await isFollow(params);
      if (res.data.isFollow) {
        // 已关注
        this.follow = this.$i18n.t("label.chatter.followed");
      } else {
        //关注
        this.follow = this.$i18n.t("label.chatter.follow");
      }
    },
    // 点击关注 取消关注
    async setFollow(val) {
      let params = {};
      if (val == this.$i18n.t("label.chatter.follow")) {
        params = {
          id: this.id,
          followType: "record",
          followStatus: "1", //关注
        };
      } else if (val == this.$i18n.t("vue_label_CCChat_unfollow")) {
        params = {
          id: this.id,
          followType: "record",
          followStatus: "0", //取消关注
        };
      }

      let res = await setFollow(params);
      if (res.result) {
        if (val == this.$i18n.t("label.chatter.follow")) {
          // 按钮为关注时点击后变成已关注
          this.follow = this.$i18n.t("label.chatter.followed");
        } else if (val == this.$i18n.t("vue_label_CCChat_unfollow")) {
          // 按钮为取消关注时点击后变成关注
          this.follow = this.$i18n.t("label.chatter.follow");
        }
      }
    },
    //关闭添加标签

    closeAddBtn(n) {
      this.customFlag = n;
    },
    // 获取新添加的标签

    determineFun() {
      this.customFlag = false;
      this.getTagsByRecordId();
    },
    getDelLabelArr(msg) {
      this.labelArr = msg;
      this.$refs.listChild.loadingDetail = true;
      this.$refs.listChild.operation = "DETAIL";
      this.$refs.listChild.getFormAttr();
    },
    customBtn() {
      if (this.labelArr.length !== 0) {
        this.$refs.addlabel.labelArrs(this.labelArr);
      }
      this.$refs.addlabel.tagApi(this.objId, this.objectApi);
      this.$refs.addlabel.isShows();
    },

    next() {
      if (this.active++ > 2) this.active = 0;
    },
    getTagsByRecordId() {
      GetTagsByRecordId({
        recordId: this.id,
      }).then((res) => {
        this.labelArr = res.data;
        // 项目名称、标签及操作区域 宽度样式
        // this.$nextTick(()=> {
        //   this.proiectStyle()
        // })
      });
    },
    resetObjectInfo(data) {
      this.objId = data.objId;
      // this.objectApi = data.objectApi;
      this.prefix = data.prefix;
      if (data.objectApi === "Account") {
        this.isCustomer = true;
        //客户层级结构
        this.content = this.$i18n.t(
          "vue_label_commonobjects_detail_customer_hierarchy"
        );
      } else if (data.objectApi === "Contact") {
        this.isCustomer = true;
        // 联系人层级结构
        this.content = this.$i18n.t(
          "vue_label_commonobjects_detail_contact_hierarchy"
        );
      } else if (data.objectApi === "campaign") {
        this.isCustomer = true;
        //"市场层级结构";
        this.content = this.$i18n.t(
          "vue_label_commonobjects_detail_market_hierarchy"
        );
      } else if (data.objectApi === "Asset") {
        this.isCustomer = true;
        // 资产层级结构
        this.content = this.$i18n.t("front-asset-module-v1-view-asset-bom");
        this.isShowAssets = true; //显示按钮查看资产BOM
      } else {
        this.isCustomer = false;
      }
    },
    //获取标签
    getBrief() {
      this.bigTitle = [];
      this.tabName = "";
      this.tabStyle = "";
      GetBrief({ id: this.id }).then((res) => {
        //如果父对象存在展示菜单中，则为父对象选项卡加选种样式，否则清除所有选中样式
        this.$Bus.$emit("change-detail-style", res.data.objid);
        if (res.result && res.returnCode === "1") {
          if (res.data) {
            this.objectApi = res.data.objapi;
            this.objId = res.data.objid;
            this.$cookies.set("parentObjectApi", res.data.objapi, { sameSite: 'Strict' }); //存储父对象的ObjectApi、详情关联列表-实际工作清单新建使用
            this.buttonList = [];
            // 判断是否返回提交待审批
            let result = res.data.button.some((item) => {
              if (item.name === "Submit") {
                return true;
              }
            });
            this.showSubmitBtn(result, res.data.approvalButtonMap);
            res.data.button.forEach((item) => {
              //改517问题（当出现自定义按钮从业务机会同步、同步到业务机会，先隐藏，用标准按钮功能）
              //('startsyncing '自定义按钮带空格，标准按钮不带，如果改，需要改四块地方)
              if (
                item.name === "fromopportunity" ||
                item.name === "startsyncing "
              ) {
                delete item.iscustom;
              }
              if (item.name == "Share" && this.isCanEditOwner) {
                //共享
                this.buttonList.push(item);
              } else if (
                item.name === "unlock" ||
                item.name === "Edit" ||
                item.name === "Delete" ||
                item.name === "PrintQuote" ||
                item.name === "fromopportunity" ||
                item.name === "startsyncing " ||
                item.name === "Lead_Convers" ||
                item.name === "Lead Convers" ||
                item.name === "Clone" ||
                item.name === "Return Pool" ||
                item.name === "Save and Sync to QB" ||
                item.name === "Print Asset Code" ||
                item.name === "View Asset BOM" ||
                item.name === "Copy Asset BOM" ||
                item.name === "Delete Asset BOM" ||
                item.name === "Transfer Asset BOM" ||
                item.name === "Move Asset BOM" ||
                item.name === "Product To Asset BOM" ||
                item.name === "Order To Asset BOM" ||
                item.name === "fabu" || //公告菜单下  标准按钮更改成 发布范围按钮 wzj  勿删勿删勿删
                item.iscustom === "1" ||
                item.name === "Edit Draft" ||
                item.name === "Publish" ||
                item.name === "Change Record Type" ||
                item.name === "Edit as Draft" ||
                item.name === "Archive" ||
                item.name === "Restore" ||
                item.name === "Delete Draft" ||
                item.name === "Synchronize Opportunity" ||
                item.name === "Generate Quote" ||
                item.name === "Send Quote"
              ) {
                // item.iscustom === "1"
                // 解锁、编辑、删除、报价单打印、从业务机会同步、同步到业务机会、潜在客户转化、复制、自定义按钮、回退公海池
                //查看资产BOM、复制资产BOM、删除资产BOM、转移资产BOM、迁移资产BOM、同步报价行至业务机会
                this.buttonList.push(item);
              } else if (item.name === "Edit Quoteline") {
                this.editQuoteline = item.label;
              } else if (item.name === "Edit Discount Tiers") {
                this.editDiscountTiers = item.label;
              }
            });
            //编辑 删除 共享 转换 打印报价单 从业务机会同步 同步至业务机会 提交待审批 -调回批准请求
            //项目管理系统
            // if (
            //   sessionStorage.getItem('application') === 'ace2020D253B3984Or6Q'
            // ) {
            //   this.showButton = this.buttonList.slice(0, 2);
            // } else {
            this.showButton = this.buttonList.slice(0, 3);
            // }
            this.moreButton = this.buttonList.slice(3);

            // 公告菜单下  标准按钮更改成 发布范围按钮 wzj     勿删勿删勿删
            if (this.objId === "Announcement20210520") {
              this.moreButton.forEach((item) => {
                if (item.name === "Share") {
                  item.name = "fabu";
                  item.label = this.$i18n.t("label.ggReleaseRange.release"); //发布范围
                  item.id = "dfsgdgdfgdfg";
                }
              });
              this.showButton.forEach((item) => {
                if (item.name === "Share") {
                  item.name = "fabu";
                  item.label = this.$i18n.t("label.ggReleaseRange.release"); //发布范围
                  item.id = "dfsgdgdfgdfg";
                }
              });
            }
            // 公告菜单下  标准按钮更改成 发布范围按钮 wzj

            if (
              res.data.objapi === "Account" ||
              res.data.objapi === "Contact"
            ) {
              this.getPartnerGetPartnerPermissions(res.data.objapi);
            }
            this.heightLight = JSON.parse(JSON.stringify(res.data.heigthLight));
            if (res.data.objapi == "CloudccKArticle") {
              this.typedisabled = this.heightLight[2].fieldValue;
            }
            //  获取主题值
            res.data.heigthLight.forEach((item) => {
              if (item.fieldName === "zhuti") {
                this.theme = item.fieldValue;
              }
            });
            this.heightLight.forEach((item) => {
              this.$set(item, "isShowMenus", true);
            });
            //相关列表定位所需
            if (this.heightLight && this.heightLight.length > 0) {
              this.briefHeight = 60;
            } else {
              this.briefHeight = 0;
            }
            this.bigTitle = res.data.title;
            this.tabName = res.data.label;
            this.tabStyle = res.data.tabStyle ? res.data.tabStyle : "";
            this.fieldValue = this.bigTitle[0].fieldValue;
            // 勿删
            this.$store.commit("changeBigTitle", this.fieldValue);
            this.$store.commit("changeTabName", this.tabName);
            this.$nextTick(() => {
              this.meunsFilter();
            });
            // 设置title标题
            if (this.fieldValue) {
              document.title = this.$setTitle(`${this.fieldValue}`);
            }
          }
          this.isSkeleton = false;
          //获取roi卡片数据
          if (this.objectApi && this.objectApi === "campaign") {
            this.$refs.roiCard && this.$refs.roiCard.init();
          }
        } else {
          this.$message.error(res.returnInfo);
          // 让进度条隐藏
        }
        // 如果是伙伴账户 请求订单是否支付接口
        if (
          this.$store.state.userInfoObj.ispartner &&
          res.data.objapi == "cloudccorder"
        ) {
          this.isPayButton();
        }
        // 项目名称、标签及操作区域 宽度样式
        this.$nextTick(() => {
          this.proiectStyle();
        });
      });
    },
    getHistorySubmit() {
      let obj = {
        id: "",
        label: this.$i18n.t("label.submitforapproval"),
        hisId: "0",
      };
      if (this.$route.query.saveandapproval === "true") {
        this.historyDeal(obj);
      }
    },
    // 展示提交按钮
    showSubmitBtn(flag, approvalStatus) {
      if (flag) {
        let tempObj = {};
        if (
          approvalStatus.isApproval ||
          approvalStatus.isReassign ||
          approvalStatus.isRecall
        ) {
          if (approvalStatus.isApproval) {
            tempObj.showApproval = true;
          }
          if (approvalStatus.isReassign) {
            tempObj.showReassign = true;
          }
          if (approvalStatus.isRecall) {
            tempObj.showRecall = true;
          }
        } else {
          tempObj.showSubmit = true;
        }

        this.showHeaderHistoryBtn(tempObj);
      } else {
        let tempObj = {};
        if (approvalStatus.isApproval) {
          tempObj.showApproval = true;
        }
        if (approvalStatus.isReassign) {
          tempObj.showReassign = true;
        }
        if (approvalStatus.isRecall) {
          tempObj.showRecall = true;
        }

        this.showHeaderHistoryBtn(tempObj);
      }
    },
    showHeaderHistoryBtn(data) {
      this.showHistoryBtn = [];
      this.hideHistoryBtn = [];
      let btnArr = [];
      if (data.showSubmit) {
        btnArr.push({
          id: "",
          label: this.$i18n.t("label.submitforapproval"),
          hisId: "0",
        }); //"提交待审批"
      }
      if (data.showApproval) {
        btnArr.push({
          id: "",
          label: this.$i18n.t("label.approveorrefuse"),
          hisId: "2",
        }); //进行审批
      }
      if (data.showReassign) {
        btnArr.push({
          id: "",
          label: this.$i18n.t("label.redistribution"),
          hisId: "3",
        }); //重新分配
      }
      if (data.showRecall) {
        btnArr.push({
          id: "",
          label: this.$i18n.t("label.manageAll.sta4"),
          hisId: "1",
        }); //调回批准请求
      }
      let hasShenpi = false;
      btnArr.forEach((item) => {
        if (item.hisId === "2") {
          hasShenpi = true;
        }
      });
      if (btnArr.length < 2) {
        this.showHistoryBtn = btnArr;
        this.hideHistoryBtn = [];
      } else {
        if (hasShenpi) {
          btnArr.forEach((item) => {
            if (item.hisId === "2") {
              this.showHistoryBtn.push(item);
            } else {
              this.hideHistoryBtn.push(item);
            }
          });
        } else {
          this.showHistoryBtn = btnArr.slice(0, 1);
          this.hideHistoryBtn = btnArr.splice(1);
        }
      }
    },
    // 审批历史右上角展示的按钮
    getHistoryBtn(arr) {
      this.showHistoryBtn = [];
      this.hideHistoryBtn = [];
      let hasShenpi = false;
      arr.forEach((item) => {
        if (item.hisId === "2") {
          hasShenpi = true;
        }
        // if (item.hisId === "0") {
        //   this.historyDeal(item);
        // }
      });
      if (arr.length < 2) {
        this.showHistoryBtn = arr;
        this.hideHistoryBtn = [];
      } else {
        if (hasShenpi) {
          arr.forEach((item) => {
            if (item.hisId === "2") {
              this.showHistoryBtn.push(item);
            } else {
              this.hideHistoryBtn.push(item);
            }
          });
        } else {
          this.showHistoryBtn = arr.slice(0, 1);
          this.hideHistoryBtn = arr.splice(1);
        }
        // this.showHistoryBtn = arr.slice(0, 1);
        // this.hideHistoryBtn = arr.splice(1);
      }
    },
    historyDeal(item) {
      // this.$message(item.label)
      if (item.hisId == 0) {
        if (this.underDetailSubmitStatus) {
          this.underDetailSubmitStatus = false;
          this.$refs.uponDetailHistory.parentClickHistory(
            item.hisId,
            this.detailGroupId,
            this.id
          );
        } else {
          // this.$message.warning('等一下')
        }
      } else {
        this.$refs.uponDetailHistory.parentClickHistory(
          item.hisId,
          this.detailGroupId,
          this.id
        );
      }
    },
    // 刷新知识文章详情页面

    refreshall() {
      this.init();
      document.getElementById("contentMainBox").scrollTop = 0;
      this.$nextTick(() => {
        this.$refs.listChild.init();
        this.$refs["project360-left"].init();
        this.$refs["project360-right"].init();
        //获取roi卡片数据
        if (this.objectApi && this.objectApi === "campaign") {
          this.$refs.roiCard && this.$refs.roiCard.init();
        }
        this.$refs.uponDetailHistory&&this.$refs.uponDetailHistory.getApprovalPreview();
      });
    },
    //右上角按钮点击方法
    recordOperationBtn(item) {
      // 自定义按钮
      if (
        item.iscustom === "1" &&
        item.name !== "Lead_Convers" &&
        item.name !== "Lead Convers"
      ) {
        // lightning版本javescript类型自定义按钮 调用特定方法执行服务
        if (item.event === "lightning") {
          // 替换id
          if (item.function_code.indexOf("{!id}") !== -1) {
            item.function_code = item.function_code.replace("{!id}", this.id);
          }
          // 替换binding
          if(item.function_code.indexOf("{!binding}") !== -1) {
            item.function_code = item.function_code.replace("{!binding}", this.$CCDK.CCToken.getToken());
          }
          let param = JSON.parse(item.function_code);
          param.id = this.id;
          param.token = this.$CCDK.CCToken.getToken()
          CommonObjApi.openCall(param).then((res) => {
            if (res.data && res.data.action) {
              // 刷新页面
              if (res.data.action === "refresh") {
                this.refreshAll();
              } else if (res.data.action === "redirect") {
                // 重定向 需判断当前窗口打开还是新窗口打开
                if (res.data.mode === "new") {
                  window.open(res.data.url);
                } else if (res.data.mode === "now") {
                  window.location.href = res.data.url;
                }
              } else if (res.data.action === "alert") {
                // 不同类型的消息提示   wj
                this.$message({
                  message: res.data.message,
                  type: res.data.messageType || "info",
                });
              }
              //先弹窗，在当前页面跳转
              else if (res.data.action === "alert_refresh") {
                // 不同类型的消息提示   wj
                this.$message({
                  message: res.data.message,
                  type: res.data.messageType || "info",
                });
                setTimeout(() => {
                  this.refreshAll();
                }, 1000);
              }
              //先弹窗,在新标签页跳转
              else if (res.data.action === "alert_redirect") {
                // 不同类型的消息提示   wj
                this.$message({
                  message: res.data.message,
                  type: res.data.messageType || "info",
                });
                setTimeout(() => {
                  if (res.data.mode === "new") {
                    window.open(res.data.url);
                  } else if (res.data.mode === "now") {
                    if (`/${window.location.hash}` === res.data.url) {
                      this.refreshAll();
                    } else {
                      window.location.href = res.data.url;
                    }
                  }
                }, 1000);
              }
            }
          });
        }
        // 自定义按钮lightning-script
        if(item.event === 'lightning-script' ){
          let funText = item.function_code ? item.function_code : item.functionCode
            let newFun = Function(funText)
            newFun()
          }
        if (item.event === "lightning-url") {
          // 替换id
          if (item.function_code.indexOf("{!id}") !== -1) {
            item.function_code = item.function_code.replace("{!id}", this.id);
          }
          // 当前窗口打开
          if (item.behavior === "self") {
            window.location.href = item.function_code;
          } else {
            // 新窗口打开
            window.open(item.function_code);
          }
        } else if (
          (item.event === "JavaScript" &&
            item.id !== "adc201300153022ukrN5" &&
            item.id !== "adc201596326104TADlh") ||
          item.event === "URL"
        ) {
          // JavaScript和URL类型暂时打开接口返回新页面处理
          // item.url != null && window.open(item.url)
          this.$message.warning(this.$i18n.t("lable.Please.switch"));
        }
      } else if (item.name === "unlock") {
        // 解除锁定
        this.dialogVisible = true;
        this.dialogAttr.title = this.$i18n.t("label.unlock");
        this.dialogAttr.content = this.$i18n.t(
          "vue_label_commonobjects_detail_unlockconfirm"
        ); //'确认解除锁定？'
      } else if (item.name === "Delete") {
        // 删除
        if (this.$store.state.userInfoObj.profileId === "aaa000001") {
          this.dialogVisible = true;
          this.dialogAttr.title = this.$i18n.t(
            "component_setup_tabs_label_delete"
          );

          if (this.objId === "cloudcc_project") {
            //删除该项目，将删除项目和项目关联的所有信息，且数据无法恢复，是否确认删除？
            this.dialogAttr.content = this.$i18n.t(
              "label.projectManagement.delete.item"
            );
          } else if (this.objId === "cloudccMilestone") {
            //删除该里程碑，将删除里程碑和里程碑关联的所有信息，且数据无法恢复，是否确认删除？
            this.dialogAttr.content = this.$i18n.t(
              "label.projectManagement.delete.milestone"
            );
          } else if (this.objId === "cloudcc_problem") {
            //删除该问题，将删除问题和问题关联的所有信息，且数据无法恢复，是否确认删除？
            this.dialogAttr.content = this.$i18n.t(
              "label.projectManagement.delete.question"
            );
          } else if (this.objId === "cloudccTask") {
            //删除该任务，将删除任务和任务关联的所有信息，且数据无法恢复，是否确认删除？
            this.dialogAttr.content = this.$i18n.t(
              "label.projectManagement.delete.task"
            );
          } else if (this.objId === "cloudccSubtask") {
            // 删除该子任务，将删除子任务和子任务关联的所有信息，且数据无法恢复，是否确认删除？
            this.dialogAttr.content = this.$i18n.t(
              "label.projectManagement.delete.subtasks"
            );
          } else {
            this.dialogAttr.content = this.$i18n.t(
              "label.weixin.confirm.delete"
            ); //'确认解除锁定？'
          }
        } else {
          CommonObjApi.getPermissionById({ id: this.id }).then((res) => {
            if (res.data.isDelete && !res.data.isLocked) {
              this.dialogVisible = true;
              this.dialogAttr.title = this.$i18n.t(
                "component_setup_tabs_label_delete"
              );
              if (this.objId === "cloudcc_project") {
                //删除该项目，将删除项目和项目关联的所有信息，且数据无法恢复，是否确认删除？
                this.dialogAttr.content = this.$i18n.t(
                  "label.projectManagement.delete.item"
                );
              } else if (this.objId === "cloudccMilestone") {
                //删除该里程碑，将删除里程碑和里程碑关联的所有信息，且数据无法恢复，是否确认删除？
                this.dialogAttr.content = this.$i18n.t(
                  "label.projectManagement.delete.milestone"
                );
              } else if (this.objId === "cloudcc_problem") {
                //删除该问题，将删除问题和问题关联的所有信息，且数据无法恢复，是否确认删除？
                this.dialogAttr.content = this.$i18n.t(
                  "label.projectManagement.delete.question"
                );
              } else if (this.objId === "cloudccTask") {
                // 删除该任务，将删除任务和任务关联的所有信息，且数据无法恢复，是否确认删除？
                this.dialogAttr.content = this.$i18n.t(
                  "label.projectManagement.delete.task"
                );
              } else if (this.objId === "cloudccSubtask") {
                // 删除该子任务，将删除子任务和子任务关联的所有信息，且数据无法恢复，是否确认删除？
                this.dialogAttr.content = this.$i18n.t(
                  "label.projectManagement.delete.subtasks"
                );
              } else {
                this.dialogAttr.content = this.$i18n.t(
                  "label.weixin.confirm.delete"
                ); //'确认解除锁定？'
              }
            } else {
              // 该记录已锁定，请联系管理员
              this.$message(
                this.$i18n.t("vue_label_commonobjects_detail_the_administrator")
              );
            }
          });
        }
      } else if (item.name === "Share") {
        //分享
        this.Getrules();
        this.$refs.isShare.open();
      } else if (item.name === "fabu") {
        //公告菜单下  标准按钮更改成 发布范围按钮 wzj  勿删勿删勿删
        this.isShowggRecord = true;
      } else if (item.name === "PrintQuote") {
        // 打印报价单
        this.previewPdf();
      } else if (item.name === "Lead_Convers" || item.name === "Lead Convers") {
        // 转换
        this.$refs.Progress.Boxs();
      } else if (item.name === "fromopportunity") {
        this.$refs.synchro.gettitle(item);
      } else if (item.name === "startsyncing ") {
        this.$refs.synchro.gettitle(item);
      } else if (item.name === "Edit") {
        // 编辑
        CommonObjApi.getPermissionById({ id: this.id }).then((res) => {
          if (res.data.isEdit) {
            // 如果是日程预约，不用标准弹窗。
            if (this.objId === "appointment") {
              this.$router.push({
                path: "/scheduleappointment/bookingdetails",
                query: {
                  id: this.id,
                },
              });
            } else {
              this.$nextTick(() => {
                this.$refs.createEditObj.objId = this.objId;
                this.$refs.createEditObj.edit();
              });
            }
          } else {
            // 抱歉，您无权编辑该条数据，请联系系统管理员。
            this.$message(this.$i18n.t("label.notEdit.message"));
          }
        });
      } else if (item.name === "Clone") {
        // 复制
        this.copyType = item.name;
        this.$nextTick(() => {
          this.$refs.createEditObj.objId = this.objId;
          this.$refs.createEditObj.copy();
        });
      } else if (item.name === "Submit") {
        //提交
        this.$refs.listChild.openApproval(0);
      } else if (item.name === "Return Pool") {
        // 退回公海池
        this.seaShow = true;
        // location.reload()
        if (this.objId === "account") {
          // this.selMarketsea("account");
          this.valSea = "account";
        } else if (this.objId === "lead") {
          // this.selMarketsea("lead");
          this.valSea = "lead";
        }
      } else if (item.name === "Save and Sync to QB") {
        this.Synchronization();
      } else if (item.name === "Print Asset Code") {
        // 服务云资产对象下打印标签
        this.$refs.codeStyleDialog.openDialog(this.id);
      }
      //查看资产BOM
      else if (item.name === "View Asset BOM") {
        this.routers();
        //复制资产BOM
      } else if (item.name === "Copy Asset BOM") {
        if (this.isOpenAssetBom) {
          this.handleAssetBOM("copy");
        } else {
          // this.$message.warning('请联系管理员，您不具有复制资产BOM的权限')
          this.$message.warning(this.$i18n.t("assetbom.copy.notpermission"));
        }
        //删除资产BOM
      } else if (item.name === "Delete Asset BOM") {
        if (this.isOpenAssetBom) {
          this.handleAssetBOM("del");
        } else {
          // this.$message.warning('请联系管理员，您不具有删除资产BOM的权限')
          this.$message.warning(this.$i18n.t("assetbom.delete.notpermission"));
        }
        //转移资产BOM
      } else if (item.name === "Transfer Asset BOM") {
        if (this.isOpenAssetBom) {
          this.$refs.assetsBOM.assetsTransfer();
        } else {
          // this.$message.warning('请联系管理员，您不具有转移资产BOM的权限')
          this.$message.warning(
            this.$i18n.t("assetbom.transferowner.notpermission")
          );
        }
        //迁移资产BOM
      } else if (item.name === "Move Asset BOM") {
        if (this.isOpenAssetBom) {
          this.$refs.assetsBOM.assetsMove();
        } else {
          // this.$message.warning('请联系管理员，您不具有迁移资产BOM的权限')
          this.$message.warning(
            this.$i18n.t("assetbom.transfer.notpermission")
          );
        }
        //订单产品
      } else if (item.name === "Order To Asset BOM") {
        this.$refs.assetsBOM.orderProduct(this.heightLight, this.layoutid);
        //产品
      } else if (item.name === "Product To Asset BOM") {
        this.$refs.assetsBOM.assetsProduct();
      } else if (item.name === "Synchronize Opportunity") {
        // 同步报价行至业务机会
        this.fullscreenLoading = true;
        CommonObjApi.syncQuoteToOpportunity({ quoteid: this.id })
          .then(() => {
            this.$message.success(
              this.$i18n.t("label.companyprofile.syncsuccess")
            );
            this.fullscreenLoading = false;
          })
          .catch(() => {
            this.fullscreenLoading = false;
          });
        // 保存报价单
      } else if (item.name === "Generate Quote") {
        CommonObjApi.getObjTemplateList({ objId: this.objId }).then((res) => {
          if (res.result) {
            let flg = true;
            res.data.forEach((element) => {
              if (element.isenable === "1") {
                flg = false;
              }
            });
            if (flg) {
              this.$message.error(
                "目前所有报价单模板均处于禁用状态，请联系管理员，启用模板后再生成报价单。"
              );
            } else {
              this.templateList = res.data;
              this.selectTemplateDialogVisible = true;
              this.$refs.selectTemplate.init(this.fieldValue);
            }
          } else {
            this.$message({
              showClose: true,
              type: "error",
              message: res.returnInfo,
            });
          }
        });
        // 发送报价单
      } else if (item.name === "Send Quote") {
        // if (this.$refs.selectquotation.List.length > 0) {
        //   this.dialogTableVisible = true
        // } else {
        //   this.$message({
        //     message: '请点击生成报价单按钮，生成报价单后再发送。',
        //     type: 'warning'
        //   });
        // }
        this.$refs.selectquotation.getFilesdata();
      }
    },
    // 发票页面同步至quickbooks

    async Synchronization() {
      var data = {
        accessToken: this.$CCDK.CCToken.getToken(),
        id: sessionStorage.getItem("id"),
      };
      const res = await cloudccInvoicesToQBInvoices(data);
      if (res.result == true) {
        this.$message({
          type: "success",
          message: this.$i18n.t("label.companyprofile.syncsuccess"),
        });
        history.go(0);
      } else {
        this.$message({
          type: "warning",
          message: this.$i18n.t("lable.Synchronization.failure"),
        });
      }
    },
    // 控制公海池弹框隐藏
    clientPoolCancle() {
      this.seaShow = false;
    },
    // 新增/修改保存
    save() {
      //如果是保存刷新当前页面也用，跳转不需要
      if (this.copyType === "Clone") {
        this.$message.success(this.$i18n.t("label.search.saveok"));
      } else {
        // 局部刷新简要信息、相关列表和详细信息
        this.getBrief();
        this.$refs.listChild.init();
        this.$refs["project360-left"].init();
        this.$refs["project360-right"].init();
      }
    },
    // 打印
    printDetail() {
      localStorage.getItem("openWater") == "true";
      window.open(
        `#/print-detail/${this.id}/${this.prefix}`,
        "_parent - URL",
        "height=700, width=1300, top=50, left=100, toolbar=no, menubar=no, scrollbars=no, resizable=no,location=n o, status=no"
      );
    },

    closeDialog() {
      this.printVisible = false;
    },
    // 返回上一级
    goBack() {
      if (this.$route.query && this.$route.query.from) {
        this.$router.push(this.$route.query.from);
      } else {
        this.$router.go(-1);
      }
    },
    //获取价格手册数据
    getPricebook(n) {
      this.pricebookArr = n;
    },

    previewPdf() {
      let token = this.$CCDK.CCToken.getToken();
      this.pdfViewerAttr.pdfUrl = `api/quote/previewPdf?accessToken=${token}&quoteId=${this.id}`;
      this.pdfViewerAttr.isShow = true;
    },
    // 保存到文件
    saveToAppendix() {
      SavePdfToFile({
        quoteId: this.id,
      }).then((res) => {
        // 保存成功
        if (res.result) {
          this.$message({
            showClose: true,
            type: "success",
            message: this.$i18n.t("label.search.saveok"),
          });
        } else {
          // 保存失败
          this.$message({
            showClose: true,
            type: "error",
            message: this.$i18n.t("savefail"),
          });
        }
        this.pdfViewerAttr.isShow = false;
      });
    },
    closeViewerDialog() {
      this.pdfViewerAttr.isShow = false;
    },
    // 确认
    confirm() {
      if(this.isConfirmDel!=='true'){
        this.dialogVisible = false;
      }

      if (
        this.dialogAttr.title ===
        this.$i18n.t("component_setup_tabs_label_delete")
      ) {
        const params = {
          id: this.$route.params.id ||this.dataId,
          objectApi: this.objectApi,
        };
        if(this.isConfirmDel){
          params.isConfirmDel='true'
        }
        GetDelete(params).then((res) => {
          if (res.result) {
            this.isConfirmDel=''
            this.$message({
              showClose: true,
              type: "success",
              message: this.$i18n.t("label_tabpage_delsuccessz"),
            });
            // this.$router.go(-1);
            this.$router.push({
              path: `/commonObjects/views/table/${this.objectApi}/${this.objId}/${this.prefix}`,
            });
          } else {
            // 审批中的记录需要二次确认才能删除,returnCode返回isConfirmDel时，说明时删除正在审批中的记录
            if(res.returnCode=== 'isConfirmDel'){
              // 二次确认弹窗
              this.isConfirmDel='true'
              this.dialogVisible=true
              this.dialogAttr.content=res.returnInfo

            }else{
                this.$message({
              showClose: true,
              type: "error",
              message: res.returnInfo,
            });
            }


          }
        });
      } else {
        // 解除锁定
        CommonObjApi.recordUnLocked({ id: this.$route.params.id }).then(
          () => {
            // 解除锁定成功
            this.$message.success(
              this.$i18n.t("vue_label_commonobjects_detail_unlocksuccess")
            );
            this.refreshAll();
          }
        );
      }
    },
    routers() {
      if (this.objectApi === "Account") {
        this.$router.push({
          path: "/commonObjects/customer",
          query: {
            ids: this.id,
            type: "Account",
            content: this.objectApi,
          },
        });
      } else if (this.objectApi === "Contact") {
        this.$router.push({
          path: "/commonObjects/customer",
          query: {
            ids: this.id,
            type: "Contact",
            content: this.objectApi,
          },
        });
      } else if (this.objectApi === "campaign") {
        // this.isCustomer = true
        // this.content = '市场层级结构'
        this.$router.push({
          path: "/commonObjects/customer",
          query: {
            ids: this.id,
            type: "Campaign",
            content: this.objectApi,
          },
        });
      } else if (this.objectApi === "Asset") {
        // 资产层级结构
        this.$router.push({
          path: "/commonObjects/customer",
          query: {
            ids: this.id,
            type: "Asset",
            content: this.objectApi,
          },
        });
      } else {
        this.isCustomer = false;
      }
    },

    async Getrules() {
      let params = {
        id: this.$router.history.current.params.id,
      };
      let res = await GetSharegetShareRuleList(params);
      // let res = await GetSearchGroup(params);
      this.shares = res.data;
    },
    //进度条需要

    shuaxin() {
      this.$refs.listChild.getFormAttr();
    },
    convert(val) {
      this.isconvert = val;
    },
    async getObjectGetObjectInfos(val) {
      let params = {
        id: val,
      };
      let res = await getObjectGetObjectInfo(params);
      if (res.result) {
        this.isTag = res.data.istag;
      }
    },
    dataListes(val) {
      this.layoutid = val.layoutId;
      this.activeList = val.dataList;
    },
    // 关闭弹窗
    handleClose() {
      this.dialogVisible = false;
      this.isConfirmDel=''
    },
    handleCloses() {
      this.isPartnerCloud = false;
    },
    //详情简介跳转详情
    briefDetail(row) {
      if (row.fieldName === "ownerid") {
        // 所有人非队列可跳转
        if (row.fieldValueId && row.fieldValueId.slice(0, 3) === "005") {
          this.$router.push({
            path: `/commonObjects/detail/${row.fieldValueId}/DETAIL`,
            query: {
              viewId: "",
            },
          });
        } else {
          // 所有人队列不可跳转
          return false;
        }
      } else {
        CommonObjApi.getPermissionById({
          id: row.fieldValueId,
        }).then((res) => {
          if (res.data.isQuery === true) {
            this.$router.push({
              path: `/commonObjects/detail/${row.fieldValueId}/DETAIL`,
              query: {
                viewId: "",
              },
            });
          } else {
            this.$message.warning(
              this.$i18n.t("vue_label_notice_nopermission_view")
              //"抱歉，您无权查看该条数据，请联系系统管理员。"
            );
          }
        });
      }
    },
    async getPartnerGetPartnerPermissions(val) {
      let params = {
        id: this.id,
      };
      let res = await getPartnerGetPartnerPermission(params);
      if (res.result) {
        this.ismanagePartnerUser = res.data.managePartnerUser;
        this.ispartner = res.data.ispartner;
        this.userId = res.data.userId;
        if (this.ismanagePartnerUser) {
          let obj = {
            label: this.$i18n.t("label.query.detail.manageexuser"),
            name: "manageExternalUsers",
            //管理外部用户
          };
          this.moreButton.push(obj);
          this.unique(this.moreButton);
        }
        if (res.data.ispartner) {
          if (val === "Contact") {
            let buttons = [
              {
                label: this.$i18n.t("label.query.detail.viewpartneruser"),
                name: "viewPartners",
                // 查看合作伙伴用户
              },
              {
                label: this.$i18n.t("label.query.detail.editpartneruser"),
                name: "editPartner",
                // 编辑合作伙伴用户
              },
              {
                label: this.$i18n.t("label.query.detail.forbidpartneruser"),
                name: "disablePartner",
                // 禁用合作伙伴用户
              },
              {
                label: this.$i18n.t(
                  "vue_label_commonobjects_detail_cloud_as_a_user"
                ), //"以用户身份登陆伙伴云",
                name: "userPartner",
                // 以用户身份登陆伙伴云
              },
            ];
            this.partner = buttons;
          } else {
            this.partner = [
              {
                // 禁用合作伙伴
                label: this.$i18n.t("label.query.detail.closepartner"),
                name: "disablePartner",
              },
            ];
          }
        } else {
          if (val === "Account") {
            this.partner = [
              {
                // 启用合作伙伴用户
                label: this.$i18n.t("label.query.detail.openpartner"),
                name: "enablePartne",
              },
            ];
          } else {
            this.partner = [
              {
                // 启用合作伙伴用户
                label: this.$i18n.t("label.query.detail.openpartneruser"),
                name: "enablePartne",
              },
            ];
          }
        }
      }
    },
    external(val) {
      if (val.name === "enablePartne") {
        if (this.objectApi === "Account") {
          this.$confirm(
            // " 一旦将客户作为合作伙伴启用，它就会出现在isPartner字段上筛选过的报表和列表视图中。另外，合作伙伴用户可以从与此客户关联的联系人进行创建。是否确定？",
            this.$i18n.t("label.query.detail.openpartnermessage"),
            // "提示",
            this.$i18n.t("label.ccchat.message.new.error4"),
            {
              // 确定
              confirmButtonText: this.$i18n.t("label.ems.confirm"),
              // 取消
              cancelButtonText: this.$i18n.t("button_partner_cancel"),
              type: "warning",
            }
          ).then(() => {
            this.getPartnerPartnerEnableAccounts();
            this.freshen();
          });
        } else {
          this.getPartnerPartnerEnableContacts();
          this.freshen();
        }
      } else if (val.name === "disablePartner") {
        this.$confirm(
          this.$i18n.t("vue_label_commonobjects_detail_customers"),
          //"永久禁用外部用户的客户将禁止所有与客户关联的外部用户，同时从与他们关联的所有社区、组、小组、权限集和共享规则中删除。同时，将永久删除与该账户关联的角色和组，同时不会出现在回收站中。是否确定？",
          // "提示",
          this.$i18n.t("label.ccchat.message.new.error4"),
          {
            // 确定
            confirmButtonText: this.$i18n.t("label.ems.confirm"),
            // 取消
            cancelButtonText: this.$i18n.t("button_partner_cancel"),
            type: "warning",
          }
        ).then(() => {
          if (this.objectApi === "Account") {
            this.getPartnerPartnerEnableAccounts();
            this.freshen();
          } else if (this.objectApi === "Contact") {
            this.getPartnerPartnerEnableContacts();
            this.freshen();
          }
        });
      } else if (val.name === "viewPartners") {
        this.$router.push({
          path: `/commonObjects/externalUsers/${this.userId}/${this.prefix}/${this.objectApi}`,
        });
      } else if (val.name === "editPartner") {
        this.$router.push({
          path: `/commonObjects/externalUsers/${this.userId}/${this.prefix}/${this.objectApi}`,
          query: {
            edit: false,
          },
        });
      } else if (val.name === "userPartner") {
        this.getPartnerQueryAllPartnerByLogins();
      }
    },
    //客户下启用禁用合作伙伴

    async getPartnerPartnerEnableAccounts() {
      let params = {
        id: this.id,
      };
      await getPartnerPartnerEnableAccount(params);
    },
    //联系人下启用禁用合作伙伴

    async getPartnerPartnerEnableContacts() {
      let params = {
        id: this.id,
      };
      let res = await getPartnerPartnerEnableContact(params);
      if (res.result) {
        if (res.data.isfirst !== null && res.data.isfirst !== false) {
          this.dataList = res.data;
          this.$router.push({
            path: `/commonObjects/externalUsers/${this.id}/${this.prefix}/${this.objectApi}`,
            query: {
              newBuild: true,
            },
          });
          // this.$nextTick(() => {
          //   this.$refs.createEditObj.objId = this.objId
          //   this.$refs.createEditObj.contacts()
          // })
        } else {
          // 启用成功
          this.$message.success(this.$i18n.t("label.group.isusing.success"));
          this.freshen();
        }
      }
    },
    //获取所有能登录的伙伴云链接

    async getPartnerQueryAllPartnerByLogins() {
      let params = {
        id: this.id,
      };
      let res = await getPartnerQueryAllPartnerByLogin(params);
      if (res.result) {
        this.tableData = res.data;
        this.isPartnerCloud = true;
      }
    },
    freshen() {
      this.getBrief();
      this.$refs.addlabel.tagApi(this.objId, this.objectApi);
      this.$refs.listChild.setRelationList();
      this.$refs.listChild.getFormAttr();
    },
    //新建实际工作清单后刷新相关列表个数
    setRelationList() {
      this.$refs.listChild.GetRelationList();
    },
    Refreshes(val) {
      if (val === "true") {
        this.freshen();
      }
    },
    //togantt

    toGannt(type) {
      if (type === "kanbanTaskPage") {
        // 修改看板任务本地存储值的问题，默认第一次进入看板是里程碑页面
        let a = "ffe20201207task11";
        localStorage.setItem("key", a);
      }
      this.$router.push({
        path: "/gantt",
        query: {
          proId: this.id,
          proName: this.fieldValue,
          isGantt: type,
        },
      });
    },
    //数组去重
    unique(arr) {
      for (var i = 0; i < arr.length; i++) {
        for (var j = i + 1; j < arr.length; j++) {
          if (arr[i].name == arr[j].name) {
            //第一个等同于第二个，splice方法删除第二个
            arr.splice(j, 1);
            j--;
          }
        }
      }
      return arr;
    },
    //回到顶部
    backtop() {
      document.getElementById("contentMainBox").scrollTop = 0;
    },
    // 复制、删除资产BOM

    handleAssetBOM(flag) {
      this.$refs.assetsBOM.handleAsset(flag);
    },
    //编辑报价行
    editProduct() {
      this.$router.push(`/quotation/editQuotation/${this.id}`);
    },
    //编辑阶梯折扣
    editDiscount() {
      this.$router.push({
        path: `/quotation/ladderDiscount`,
        query: {
          id: this.id,
        },
      });
    },
    // 进度条格式化
    formatProgress() {
      return "";
    },
    //简介为url跳转
    jumpTo(url) {
      if (url.indexOf("http://") !== -1) {
        window.open(url);
      } else {
        window.open("http://" + url);
      }
    },
    //获取对应的相关列表对象信息
    objectSwitch(item) {
      this.everyObj = item;
      if (this.everyObj.relatedlistId === "project360") {
        this.$nextTick(() => {
          this.$refs["project360-left"].init();
        });
      } else if (this.everyObj.relatedlistId === "conversation") {
        this.$nextTick(() => {
          this.$refs["conversationRelated"].init(this.dataId);
        });
      } else if (this.everyObj.relatedlistId === "projectActivity") {
        // 活动
        this.$nextTick(() => {
          // this.$refs["conversationRelated"].init();
        });
      } else if (this.everyObj.relatedlistId === "projectCccat") {
        // 动态
        this.$nextTick(() => {
          this.getSetupInfo();
        });
      } else if (this.everyObj.relatedlistType== "cloudccGanttChart") {
        this.selectproject = this.id;
        this.$nextTick(() => {
          this.$refs["ganttChild"].ganttoading = true;
          this.$refs["ganttChild"].getPIdMilestoneData();
        });
      } else if (this.everyObj.relatedlistType=="cloudccCalendar") {
        this.selectproject = this.id;
      } else {
        this.$nextTick(() => {
          this.$refs.relatedObject.init();
        });
      }
    },
    //特殊相关列表 新建完后刷新相关列表数据
    refreshRelatedList() {
      this.$nextTick(() => {
        this.$refs.listChild.init();
      });
    },
    //  更新会话相关列表数量
    updateConverNum(n) {
      this.conversationNum = n;
    },
  },
  watch: {
    windowWidth() {
      // 实时获取屏幕更改,项目名称和标签样式随之更改
      this.proiectStyle();
    },
    realObjId(val) {
      // 控制甘特图按钮显隐
      if (val && val === "cloudcc_project") {
        this.isShowGanttButton = true;
      } else {
        this.isShowGanttButton = false;
      }
    },
    screenWidth: {
      immediate: true,
      handler(newValue) {
        if (!this.timer) {
          this.screenWidth = newValue;
          this.meunsFilter();
          this.timer = true;
          let that = this;
          setTimeout(function () {
            that.timer = false;
          }, 200);
        }
      },
    },
    id(newVal, oldVal) {
      if (newVal != oldVal) {
        this.getIsHasGroup();
      }
    },
    // 监听分屏视图数据变化
    dataId(nval) {
      this.id = nval;
      this.$refs.listChild.id = nval;
      this.init();
      this.$refs.listChild.init();
      this.$refs["project360-left"].init();
      this.$refs["project360-right"].init();
    },
    tabName(nval) {
      // 联系人
      if (nval === this.$i18n.t("label_tabpage_contactsz")) {
        let obj = {
          objectApi: "Contact",
        };
        this.resetObjectInfo(obj);
      }
    },
    objId(val) {
      if (val !== "" && val !== undefined) {
        this.getObjectGetObjectInfos(val);
      }
      if (val === "task" || val === "event") {
        this.activeName = "second";
      } else if (this.isShowType === false) {
        this.activeName = "second";
      } else {
        this.activeName = "first";
      }
    },
    objectApi(val) {
      if (val === "Task" || val === "Event") {
        this.activeName = "second";
      } else if (this.isShowType === false) {
        this.activeName = "second";
      } else {
        this.activeName = "first";
      }
      // 公告菜单下  标准按钮更改成 发布范围按钮 wzj     勿删勿删勿删
      // if (val === 'Announcement') {
      //   this.moreButton.forEach((item) => {
      //     if (item.name === 'Share') {
      //       item.name = 'fabu'
      //       item.label = '发布范围'
      //       item.id = 'dfsgdgdfgdfg'
      //     }
      //   })
      //   this.showButton.forEach((item) => {
      //     if (item.name === 'Share') {
      //       item.name = 'fabu'
      //       item.label = '发布范围'
      //       item.id = 'dfsgdgdfgdfg'
      //     }
      //   })
      // }
    },
    activeName(val) {
      if (val === "first") {
        this.$refs.activitys.init();
      } else if (val === "second" && this.isShowType === true) {
        this.getSetupInfo();
      }
    },
    filterText(val) {
      this.$refs.tree.filter(val);
    },
  },
  computed: {},
};
</script>

<style scoped lang="scss">
.main, .mainOther{
  z-index:0;
  width: calc(100% - 10px);
}
.resource ::v-deep {
  .sourceManage {
    height: 100% !important;
  }
  .zps-resource {
    height: 100% !important;
  }
  .resourceutilContainer {
    min-height: calc(100% - 40px) !important;
  }
  .resourceutilTableholder {
    height: calc(100% - 10px) !important;
    overflow-y: hidden;
  }
}
.downXiala {
  cursor: pointer;
  width: 24px;
  height: 24px;
  margin-right: 10px;
  float: right;
}
.li {
  padding: 0px 10px !important;
}

.layoutSwitchBox {
  position: absolute;
  left: calc(66.7% - 32px);
  top: -2px;
  text-align: center;
  height: 32px;
  line-height: 32px;
  cursor: pointer;
  z-index: 100;

  svg {
    width: 41px;
    height: 49px;
  }
}

.project360 {
  position: relative;
  width: 100%;
  display: flex;
}

.draggTabsActive {
  border-bottom: 2px solid #006dcc;
}

.projectBox {
  position: relative;
  border-bottom: 1px solid #dedc;

  .changeColumnBtnGroup {
    position: absolute;
    top: 5px;
    right: 0;
    height: 30px;
    line-height: 30px;
    float: left;
    border: 1px solid #dedcda;
    border-radius: 3px;
    li {
      float: left;
      width: 30px;
      height: 30px;
      padding-top: 1px;
    }
    .setColumnsIcon {
      width: 16px;
      // height: 28px;
      margin-left: 8px;
    }

    .autoColumnsIcon {
      width: 16px;
      margin-left: 7px;
    }
  }

  // 详情页360 左侧tabs切换 样式
  .draggaBox {
    width: 100%;
    height: 42px;
    .draggaContent {
      width: 100%;
      display: flex;
      .draggTabs {
        padding: 0 10px;
        height: 42px;
        font-size: 16px;
        font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
        font-weight: bold;
        color: #080707;
        line-height: 41px;
        text-align: center;
        margin-right: 50px;
        cursor: pointer;
      }
    }
  }
}

::v-deep .el-dialog.qunce-dialog {
  padding: 20px;
  border-radius: 8px;
  .el-dialog__header {
    border-bottom: 1px solid #dcdcdc;
    padding: 0 0 10px;
    .el-dialog__title {
      font-size: 14px;
      font-weight: 900;
    }
  }
  .el-dialog__body {
    padding: 0;
    padding-top: 17px;
  }
  .el-button {
    margin-top: 35px;
    padding: 10px 15px;
  }
  .cancel {
    margin-left: calc(50% - 70px);
  }
}
.top {
  width: 100%;
  height: 100%;
  background: #f2f2f5;
  padding: 10px 10px 0px 10px !important;
}
.pop_box .moreButton,
.pop_small .moreButton {
  margin: 0 -12px;
  min-height: 26px;
}
.pop_box .moreButton li,
.pop_small .moreButton li {
  min-height: 26px;
  padding: 0 10px;
  font-size: 12px;
  padding: 0;
  text-align: center;
  line-height: 26px;
  color: #3e3e3c;
}

.pop_box .moreButton li:hover,
.pop_small .moreButton li:hover {
  background: #f3f2f2;
  color: #006dcc;
}
.moreButtons li {
  min-height: 26px;
  padding: 0 10px;
  font-size: 12px;
  padding: 0;
  text-align: center;
  line-height: 26px;
  color: #3e3e3c;
}
.moreButtons li:hover {
  background: #f3f2f2;
  color: #006dcc;
}
</style>
<style scoped lang="scss">
.isEnableBox {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  height: 400px;
}
::v-deep .dynamic {
  padding: 0 10px;
  .Post {
    margin-right: 0;
    margin-top: 20px;
  }
}

::v-deep .dynamic-public .detail-box {
  padding: 5px 0px 5px 0;
}
.home {
  height: 100%;
  overflow: auto;
  width: 100%;
  position: relative;
  height: calc(100% - 10px);
  border-radius: 3px;

  .noPermissionBox {
    // width: 100%;
    text-align: center;
    position: absolute;
    top: calc(50% - 136px);
    left: calc(50% - 176.5px);
    svg {
      width: 353px;
      height: 194px;
      margin: 0 auto;
    }
    .noPermissionTip {
      margin: 10px 0;
      font-size: 20px;
      font-weight: bold;
    }
  }

  .contentBox {
    position: sticky;
    left: 0px;
    right: 0px;
    z-index: 1001;
    top: 0.1px;
    // margin-left: 10px;
    margin-right: 10px;
    width: calc(100% - 10px);
  }
  .contentBoxs {
    z-index: 1001;
    top: 104px;
  }
}

::v-deep .el-card__body {
  padding: 0px;
}

.box-card {
  width: 100%;
  border: 1px solid rgba(221, 219, 218, 1);
  border-radius: 0 !important;
  ul {
    padding: 10px;
    box-sizing: border-box;

    li {
      font-size: 12px;
      line-height: 30px;
      width: 100%;
      display: flex;
      justify-content: flex-start;

      .label_span {
        width: 80px;
        display: inline-block;
        margin-right: 20px;
      }

      .color_s span {
        width: 20px;
        height: 20px;
        background: green;
        margin: 5px;
        display: inline-block;
      }
    }
  }

  ::v-deep .el-radio__label {
    font-size: 12px;
  }
}

.box-card {
  width: 100%;
  border: 1px solid rgba(221, 219, 218, 1);
  ::v-deep .el-card__body {
    padding: 0;
  }
  border-radius: 0 !important;
  .introduce {
    width: 100%;
    overflow-x: hidden;
    height: auto;
    display: flex;
    .introduce_title {
      font-size: 12px;
      color: #3e3e3c;
      letter-spacing: 0;
      text-align: justify;
      line-height: 22px;
      min-width: 80px;
      display: inline-block;
      white-space: nowrap;
      svg {
        margin-left: 1px;
        margin-top: -2px;
        cursor: pointer;
        width: 18px;
        height: 18px;
      }
    }

    .introduce_cet {
      font-size: 14px;
      color: #080707;
      letter-spacing: 0;
      text-align: justify;
      line-height: 18px;
      display: inline-block;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      max-width: 300px;
      ::v-deep .is-checked {
        .el-checkbox__input {
          .el-checkbox__inner {
            background-color: #006dcc !important;
            border-color: #006dcc !important;
          }
        }
      }
      ::v-deep .el-progress-bar {
        padding-right: 0;
        width: 50px;
        margin-right: 0;
      }
    }

    .briefDetail_s {
      color: #006dcc;
      cursor: pointer;
    }

    .introduce_content {
      float: left;
      margin: 10px 0 10px 35px;
      // min-width: 20px;
    }
  }
}

.box-main_main {
  width: 100%;
  height: auto;
  background: none;
  border-radius: 3px 3px 0px 0px;
  margin-top: 10px;
  &.box-main_main1 {
    height: calc(100% - 132px);
    ::v-deep .el-card__body {
      height: 100%;
    }
  }
  &.box-main_main2 {
    height: calc(100% - 100px);
    ::v-deep .el-card__body {
      height: 100%;
    }
  }
}
.worklist {
  width: 100%;
  height: calc(100% - 125px);
  margin-top: 10px;
  ::v-deep .el-table__body-wrapper {
    height: 368px;
  }
}
.guanbi {
  font-size: 16px;
  position: relative;
  top: 15px;
  left: 93%;
  z-index: 1;
}

.left {
  width: calc(66.7% - 10px);
  margin: 10px 10px 10px 0;
  float: left;
  position: relative;
}

::v-deep .right {
  float: right;
  margin-top: 10px;
  width: 33.3%;
  position: relative;
}

// 详情页360 活动 动态 图表 拖拽样式
.activeDinamic {
  .activeDinamicContent {
    display: flex;
    border-bottom: solid 1px #dedcda;
    .activeDinamicTabs {
      padding: 0 10px;
      height: 42px;
      font-size: 16px;
      font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
      font-weight: bold;
      color: #080707;
      line-height: 41px;
      margin-right: 40px;
      text-align: center;
      cursor: pointer;
    }
  }
  .activeDinamicActive {
    border-bottom: 2px solid #006dcc;
  }
}

.box_top {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  background: #f3f2f2;
  border: 1px solid #dddbda;
  border-bottom: 1px solid rgba(221, 219, 218, 1);
  min-height: 65px;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  padding: 14px 0;
  .mainContantBox {
    display: flex;
    align-items: center;
    max-width: calc(100% - 510px);
    min-width: 40%;
    // border: solid 1px;
    .left {
      width: 7px;
      height: 12px;
      display: inline-block;
      margin-left: 11px;
      // margin-top: 20px;
      margin-right: 12px;
      // float: left;
      cursor: pointer;
    }

    .box_header {
      width: 36px;
      height: 36px;
      .icon {
        width: 36px;
        height: 36px;
        display: inline-block;
      }
    }

    .title {
      max-width: calc(100% - 20px);
      margin-top: 3px;
      margin-left: 8px;

      .title_main {
        display: flex;
        align-items: center;
        font-size: 16px;
        font-weight: 600;
        color: #080707;

        // 项目名称和所选标签
        .projectNameAndTag {
          display: inline-block;
          // 项目名称
          .projectName {
            display: inline-block;
          }
          // 标签
          .projectTag {
            display: inline-block;
            vertical-align: 3px;
            // overflow: hidden;
            // white-space: nowrap;
            // text-overflow: ellipsis;
            .iconTag {
              margin: 0 5px 0 10px;
            }
            span {
              span {
                padding: 0 5px;
              }
            }
          }
        }
        .tagClosed {
          padding: 0 6px;
          cursor: pointer;
          display: inline-block;
        }
        .link {
          margin-left: 5px;
          vertical-align: 5px;
          min-width: 48px;
          font-size: 12px;
          font-weight: 400;
          color: rgba(0, 109, 204, 1);
        }
      }
    }
  }

  .header_right {
    min-width: 535px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin: 2px 0;
    padding: 0px 10px 0px 32px;
    .dayin {
      width: 28px;
      height: 28px;
      border: 1px solid #ccc;
      background: #fff;
      border-radius: 3px;
      cursor: pointer;
      margin: 0 0 0 10px;
      svg {
        margin: 5px;
        width: 16px;
        height: 16px;
      }
    }
    ::v-deep .el-button-group {
      // margin-top: 16px;
      position: relative;
      .moreButton {
        margin-bottom: 0;
        li {
          height: 30px;
          line-height: 30px;

          &:hover {
            color: #006dcc;
          }
        }
      }
      ::v-deep .button {
        height: 30px;
        text-align: center;
        padding: 0 15px;
        color: #006dcc;
        border: 1px solid #dedcda;
        // &:hover {
        //   background: #fff;
        //   border: 1px solid #dedcda;
        // }
      }
    }
    .xiala {
      width: 28px;
      height: 28px;
      border: 1px solid #dedcda;
      border-top-right-radius: 3px;
      border-bottom-right-radius: 3px;
      display: inline-block;
      background: #fff;
      text-align: center;
      cursor: pointer;
      svg {
        width: 16px;
        height: 16px;
        margin-top: 5px;
      }
    }

    .daying {
      width: 28px;
      height: 28px;
      background: rgba(255, 255, 255, 1);
      border: 1px solid #ccc;
      position: relative;
      left: 24px;
    }
  }
  .el-tag {
    margin-right: 5px;
    height: 18px;
    padding: 0 4px;
    line-height: 18px;
    border: 0;
  }
  .label {
    margin-left: 40px;
    margin-bottom: 8px;
    margin-right: 40px;
    svg {
      float: left;
      width: 14px;
      display: inline-block;
      margin: 5px 5px 0 0;
    }
  }
}
.isBgColor {
  background-color: #fff;
}

.clears {
  min-height: 8px;
}
::v-deep .el-card.is-always-shadow {
  box-shadow: none;
  border: 1px solid #dddbda;
}
::v-deep .partnercloud {
  .el-dialog__header {
    font-size: 14px;
    color: #080707;
  }
  .el-dialog__body {
    padding: 30px 36px 30px 33px;
  }
}
::v-deep .el-tabs__nav-wrap::after {
  height: 1px !important;
  background-color: #dedcda !important;
}
.backtop {
  width: 40px;
  height: 39px;
  position: fixed;
  bottom: 90px;
  right: -5px;
  cursor: pointer;
  z-index: 9;
  // box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.2);
}
.knowledge ::v-deep .el-dialog--center .el-dialog__body {
  min-height: 380px;
  max-height: 380px;
}
</style>
<style>
.external {
  padding: 12px 0px 12px 0px !important;
}
.pop_small ul {
  margin: 0 -12px;
  min-height: 26px;
}

.pop_small li {
  min-height: 26px;
  padding-right: 10px;
  font-size: 12px;
  line-height: 26px;
  cursor: pointer;
  color: #3e3e3c;
}
.pop_small li span {
  padding-left: 10px;
}
.pop_small li:hover {
  background: #f3f2f2;
  color: #006dcc;
}
.type {
  display: flex;
}
.typetree {
  width: 80%;
  margin-left: 10%;
}
.conversation {
  background-color: #fff;
}
.conversationMargin {
  margin-top: 20px;
}
</style>
<style lang="scss">
.allTagDialog {
  padding: 10px 0;
  // border:solid 1px;
  display: flex;
  flex-wrap: wrap;
  span {
    margin: 3px;
  }
}
</style>
