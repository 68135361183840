<template>
  <div class="conversation-mail conversation">
    <div class="conversation-top">
      <div class="to-left">
        <div class="avater">
          <svg
            v-if="emailList.incoming == '0'"
            aria-hidden="true"
            class="icon"
            style="width: 100%; height: 100%"
          >
            <use href="#icon-headPortrait_inside_send1"></use>
          </svg>
          <svg
            v-if="emailList.incoming == '1'"
            aria-hidden="true"
            class="icon"
            style="width: 100%; height: 100%"
          >
            <use href="#icon-headPortrait_outside_get"></use>
          </svg>
        </div>
        <div class="perInfo">
          <div>
            <span class="name" v-if="emailList.incoming == '0'">{{
              emailList.ownerName
            }}</span>
            <span class="name" v-if="emailList.incoming == '1'">{{
              emailList.contactName
            }}</span>
            <span>&emsp;{{ emailList.createdate }}&emsp;</span>
            <!-- 邮件 label.chatter.mail-->
            <span>{{ $t("label.chatter.mail") }}</span>
          </div>
          <div>
            <!-- 从 label.appointment.wizard.subtitle2-1-->
            <span>{{ $t("label.appointment.wizard.subtitle2-1") }}</span>
            <span>&emsp;{{ emailList.fromaddress }}</span>
          </div>
          <div>
            <div>
              <!-- 收件人 component.sendmail.label.touser-->
              <span>{{ $t("component.sendmail.label.touser") }}</span>
              <span>&emsp;{{ emailList.toaddress }}</span>
              <span
                v-show="isShowannex && emailList.ccaddress != ''"
                class="shouqi-icon"
                @click="isShowannex = !isShowannex"
              >
                <svg
                  aria-hidden="true"
                  class="icon"
                  style="width: 100%; height: 100%; margin-left: 10px"
                >
                  <use href="#icon-shouqi1"></use>
                </svg>
              </span>
              <span
                v-show="!isShowannex && emailList.ccaddress != ''"
                class="shouqi-icon"
                @click="isShowannex = !isShowannex"
              >
                <svg
                  aria-hidden="true"
                  class="icon"
                  style="width: 100%; height: 100%; margin-left: 10px"
                >
                  <use href="#icon-xiala1"></use>
                </svg>
              </span>
            </div>
            <div>
              <div v-show="isShowannex && emailList.ccaddress != ''">
                <!-- 抄送人 label.mobile.phone.cc.people-->
                <span>{{ $t("label.mobile.phone.cc.people") }}</span>
                <span>{{ emailList.ccaddress }}</span>
              </div>
            </div>
          </div>
          <div>
            <span
              v-for="(file, FIndex) in emailList.attachmentList"
              :key="FIndex"
              @click="downloadfile(file)"
              class="fileList"
            >
              <span class="annex-icon">
                <svg
                  aria-hidden="true"
                  class="icon"
                  style="width: 100%; height: 100%"
                >
                  <use href="#icon-addassemblyblue"></use>
                </svg>
              </span>
              {{ file.fileName }}{{ file.fileSuffix }}
            </span>
          </div>
        </div>
      </div>
      <div class="point-box">
        <el-dropdown
          @command="handleCommand"
          @visible-change="active = !active"
        >
          <div class="el-dropdown-link point-icon">
            <svg
              aria-hidden="true"
              class="icon"
              style="width: 100%; height: 100%"
            >
              <use
                :href="!active ? '#icon-full_more_def' : '#icon-full_more_cli'"
              ></use>
            </svg>
          </div>
          <el-dropdown-menu slot="dropdown" class="point-li">
            <el-dropdown-item
              v-for="(item, index) in moreOperationData"
              :key="index"
              :command="item.type"
              @mouseover.native="enter(index)"
              @mouseout.native="outer(index)"
              @click.native="OperationItem(item)"
            >
              <span class="point-icon">
                <svg
                  aria-hidden="true"
                  class="icon"
                  style="width: 100%; height: 100%"
                >
                  <use :href="!item.isHov ? item.icon : item.hovicon"></use>
                </svg> </span
              ><span>{{ item.text }}</span>
            </el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
    </div>
    <div class="conversation-content warp">
      <div class="content-mail">
        <div class="mail-title">{{ emailList.name }}</div>
        <div class="mail-container">
          <!-- 邮件正文： label.emailmainbody-->
          <p class="mail-text">{{ $t("label.emailmainbody") + ":" }}</p>
          <p v-html="emailList.htmlbody" class="mail-body"></p>
        </div>
        <!-- <div class="mail-icon" @click="isShowMail = !isShowMail">
          <svg
            aria-hidden="true"
            class="icon"
            style="width: 100%; height: 100%"
            v-show="isShowMail"
          >
            <use href="#icon-console_email_putAway"></use>
          </svg>
          <svg
            aria-hidden="true"
            class="icon"
            style="width: 100%; height: 100%"
            v-show="!isShowMail"
          >
            <use href="#icon-console_email_open"></use>
          </svg>
        </div> -->
        <!-- <div v-if="isShowMail" class="mail-original">
          <div>
            <span>----------原始邮件----------</span>
          </div>
          <div class="mail-info">
            <ul class="mail-info-detail">
              <li>
                <span>发件人:</span>
              </li>

              <li>
                <span>发送时间:</span>
                <span>2021年10月19日(星期二) 中午11:21 </span>
              </li>
              <li>
                <span>收件人:</span>
              </li>
              <li>
                <span>主题:</span>
                <span>[bihukeji] Re:你好</span>
              </li>
            </ul>
            <div>
              正文：您好，关于产品咨询的问题我想请教，期待收到您的回复。您好，关于产品咨询的问题我想请教，期待收到您的回复。
            </div>
          </div>
        </div> -->
      </div>
    </div>
    <separateCase
      :separateCaseDialog="separateCaseDialog"
      :recordId="recordId"
      @closeSeparate="closeSeparate"
    />
  </div>
</template>
<script>
import separateCase from "@/views/controlPanel/components/conversion-components/components/separateCase.vue";
import * as controlPanelApi from "../../api.js";

export default {
  components: {
    separateCase,
  },
  data() {
    return {
      separateCaseDialog: false,
      recordId: "",
      caseid: this.$route.query.caseId,
      isShowannex: false, //是否显示fujian
      // isShowMail: true, // 是否显示原始邮件
      moreOperationData: [
        {
          //邮件回复 front-console-conversation-btn2
          text: this.$i18n.t("front-console-conversation-btn2"),
          icon: "#icon-full_replyMail_hov",
          hovicon: "#icon-full_replyMail_cli",
          isHov: false,
          type: "replyMail",
          index: 0,
        },
        {
          //转发邮件 front-console-conversation-btn3
          text: this.$i18n.t("front-console-conversation-btn3"),
          icon: "#icon-full_forwardMail_hov",
          hovicon: "#icon-full_forwardMail_cli",
          isHov: false,
          type: "forwardMail",
          index: 0,
        },
        {
          //电话回复 front-console-conversation-btn1
          text: this.$i18n.t("front-console-conversation-btn1"),
          icon: "#icon-full_callBack_hov",
          hovicon: "#icon-full_callBack_cli",
          isHov: false,
          type: "callBack",
          index: 1,
        },
        {
          //分解为新个案 front-console-conversation-btn4
          text: this.$i18n.t("front-console-conversation-btn4"),
          icon: "#icon-full_separationCase_hov",
          hovicon: "#icon-full_separationCase_cli",
          isHov: false,
          type: "separationCase",
          index: 2,
        },
      ], // 会话记录操作
      active: false, // 会话记录操作选中与否
    };
  },
  watch: {
    Num: {
      immediate: true,
      handler: function (newVal) {
        let that = this;
        if (newVal > 1) {
          that.moreOperationData = [
            {
              text: this.$i18n.t("front-console-conversation-btn2"),
              icon: "#icon-full_replyMail_hov",
              hovicon: "#icon-full_replyMail_cli",
              isHov: false,
              type: "replyMail",
              index: 0,
            },
            {
              text: this.$i18n.t("front-console-conversation-btn3"),
              icon: "#icon-full_forwardMail_hov",
              hovicon: "#icon-full_forwardMail_cli",
              isHov: false,
              type: "forwardMail",
              index: 0,
            },
            {
              text: this.$i18n.t("front-console-conversation-btn1"),
              icon: "#icon-full_callBack_hov",
              hovicon: "#icon-full_callBack_cli",
              isHov: false,
              type: "callBack",
              index: 1,
            },
            {
              text: this.$i18n.t("front-console-conversation-btn4"),
              icon: "#icon-full_separationCase_hov",
              hovicon: "#icon-full_separationCase_cli",
              isHov: false,
              type: "separationCase",
              index: 2,
            },
          ];
        } else {
          that.moreOperationData = [
            {
              text: this.$i18n.t("front-console-conversation-btn2"),
              icon: "#icon-full_replyMail_hov",
              hovicon: "#icon-full_replyMail_cli",
              isHov: false,
              type: "replyMail",
              index: 0,
            },
            {
              text: this.$i18n.t("front-console-conversation-btn3"),
              icon: "#icon-full_forwardMail_hov",
              hovicon: "#icon-full_forwardMail_cli",
              isHov: false,
              type: "forwardMail",
              index: 0,
            },
            {
              text: this.$i18n.t("front-console-conversation-btn1"),
              icon: "#icon-full_callBack_hov",
              hovicon: "#icon-full_callBack_cli",
              isHov: false,
              type: "callBack",
              index: 1,
            },
          ];
        }
      },
    },
  },
  mounted() {},
  props: {
    emailList: {
      type: Object,
      default: () => {
        return {};
      },
    },
    Num: {
      type: Number,
      default: 0,
    },
  },
  methods: {
    //下载文件
    downloadfile(item) {
      const baseURL = this.$baseConfig.baseURL;
      const token = this.$CCDK.CCToken.getToken();
      const link = document.createElement("a");
      link.setAttribute("download", "");
      link.href = `${baseURL}/file/downloadFile?accessToken=${token}&id=${item.file_info_id}`;
      link.click();
    },
    //会话列表中单个卡片的操作项
    OperationItem(params) {
      //如果为邮件回复
      if (params.type == "replyMail") {
        this.$emit("toSend", true, this.emailList, true);
      }
      //如果为邮件转发
      if (params.type == "forwardMail") {
        this.$emit("forwardEmail", true, this.emailList);
      }
    },
    enter(index) {
      this.moreOperationData[index].isHov = true;
    },
    outer(index) {
      this.moreOperationData[index].isHov = false;
    },

    // 点击菜单项
    handleCommand(command) {
      // 电话回复
      let conf = {
        caseid: this.caseid,
      };
      if (command == "callBack") {
        controlPanelApi.getContactInfo(conf).then((res) => {
          let phone = res.data[0].shouji || res.data[0].dianhua;
          this.$bus.$emit("newCLick", phone);
        });
      }
      // 拆分新个案
      else if (command == "separationCase") {
        controlPanelApi.getPermissionById({ id: this.caseid }).then((res) => {
          if (res.data.isEdit) {
            this.separateCaseDialog = true;
            this.recordId = this.emailList.id;
          } else {
            this.$message.warning(
              this.$i18n.t("front-kefu-moudle-v1-Operation-without0-permission")
            );
          }
        });
      }
    },

    closeSeparate() {
      this.separateCaseDialog = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.conversation {
  margin: 20px 0;
  .fileList {
    cursor: pointer;
  }
  .conversation-top {
    width: 100%;
    display: flex;
    justify-content: space-between;
    .to-left {
      display: flex;
      justify-content: space-between;

      .avater {
        width: 48px;
        height: 48px;
        margin-right: 20px;
      }
      .perInfo {
        color: #666;
        // > div {
        //   height: 22px;
        //   line-height: 22px;
        //   > span {
        //     margin-right: 10px;
        //   }
        // }
        .name {
          font-size: 16px;
          color: #333;
        }
      }
      .annex-icon,
      .shouqi-icon {
        display: inline-block;
        width: 12px;
        height: 12px;
        cursor: pointer;
      }
    }
    .point-icon {
      cursor: pointer;
      width: 30px;
      height: 20px;
    }
  }
  .warp {
    width: 86%;
    margin-left: 67px;
    margin-top: 16px;
  }
  .conversation-content {
    .mail-body {
      width: 100%;
      word-wrap: break-word;
      ::v-deep pre {
        white-space: pre-wrap;
        word-wrap: break-word;
      }
      ::v-deep xmp {
        white-space: pre-wrap;
        word-wrap: break-word;
      }
    }
    .content-mail {
      margin-top: 10px;
      padding: 16px;
      border-radius: 4px;
      border: 1px solid #dedcda;
      .mail-title {
        font-weight: bold;
        color: #333;
      }
      .mail-text {
        font-size: 12px;
        color: #666;
        margin: 12px 0 8px 0;
      }
      .mail-icon {
        width: 40px;
        height: 20px;
        margin: 12px 0 10px 0;
        cursor: pointer;
      }
      .mail-original {
        border-left: 1px solid #dedcda;
        padding-left: 9px;
        .mail-info {
          padding: 8px;
          background: #f0f0f0;
          .mail-info-detail {
            font-size: 12px;
            margin-bottom: 8px;
          }
        }
      }
    }
  }
}
// 搁置样式
.point-li {
  border: 0;
  .point-icon {
    display: inline-block;
    width: 30px;
    height: 20px;
    margin-right: 5px;
    position: relative;
    top: 4px;
  }
}
</style>