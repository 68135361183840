import axios from '@/config/httpConfig'

/**
 * 请求获取用户信息
 * @param {data} 请求参数 
 * @param {enforce} 是否强制请求最新用户信息 
 * @returns 用户信息
 */
export function getUserInfo(data, enforce = false) {
    if (enforce || localStorage.getItem("userInformation") === null) {
        return axios.get('/user/getUserInfo', data)
    } else {
        return new Promise(function (resolve) {
            resolve(JSON.parse(localStorage.getItem("userInformation"))); // 回调成功返回
        })
    }
}

// 获取资产BOM管理权限 --- 赵晨宇
export function isOpenAsset(args) {
    return axios.post('ManageAssetBOM/getManageAssetBOMPermission', args)
}
//文件-附件添加Dropbox类型文件
export function getSaveDropbox(data) {
    return axios.post('/file/saveDropbox', data)
}
// 查询用户界面设置信息
export function getUserInterface(data) {
    return axios.post('/user/getUserInterface', data)
}
//文件-附件添加
export function Bind(data) {
    return axios.post('/file/bind', data)
}
// 获取订单支付状态
export function getPaymentStatus(data) {
    return axios.post('/partnerorder/getPaymentStatus', data)
}

// 获取CCChat后台设置信息
export function getSetupInfo() {
    return axios.post('/ccchat/person/getSetupInfo')
}
// 同步至quickbooks
export function cloudccInvoicesToQBInvoices(data) {
    return axios.post('/quickbooks/cloudccInvoicesToQBInvoices', data)
}
//获取管理合作伙伴的权限
export function getPartnerGetPartnerPermission(data) {
    return axios.post('/partner/getPartnerPermission', data)
}
// 接口1 根据对象获取对象对应的模板列表
export function getObjTemplateList(data) {
    return axios.post('/objTemplate/getObjTemplateList', data)
}
// 获取详情页标签
export function GetBrief(data) {
    return axios.post('/objectdetail/getBrief', data)
}
//根据记录id获取标签
export function GetTagsByRecordId(data) {
    return axios.post('/tag/getTagsByRecordId', data)
}
// 详情删除接口
export function GetDelete(data) {
    return axios.post('/objectdetail/delete', data)
}
//获取项目管理主页甘特图
export function getPIdMilestone(data) {
    return axios.post('/ganttChart/getPIdMilestone', data)
}
// 获取共享规则列表
export function GetSharegetShareRuleList(data) {
    return axios.post('/share/getShareRuleList', data)
}
//获取相关列表数据及按钮和字段信息
export function GetDetailRelatedItems(data) {
    return axios.post('/objectdetail/getDetailRelatedItems', data)
}
//获取相关列表对象信息
export function GetRelationList(data) {
    return axios.post('/objectdetail/getRelationList', data)
}
//保存pdf到附件
export function SavePdfToFile(data) {
    return axios.post('/quote/savePdfToFile', data)
}
// 获取对象信息
export function getObjectGetObjectInfo(data) {
    return axios.post('/object/getObjectInfo', data)
}
// 同步报价行至业务机会
export function syncQuoteToOpportunity(data) {
    return axios.post('/cpq/quote/syncQuoteToOpportunity', data)
}
// 根据记录类型查询报表
export function queryReprtRecordtypeid(data) {
    return axios.post('/report/chart/queryReprtRecordtypeid', data)
}
//客户下启用禁用合作伙伴
export function getPartnerPartnerEnableAccount(data) {
    return axios.post('/partner/partnerEnableAccount', data)
}

//联系人下启用禁用合作伙伴
export function getPartnerPartnerEnableContact(data) {
    return axios.post('/partner/partnerEnableContact', data)
}
//获取所有能登录的伙伴云链接
export function getPartnerQueryAllPartnerByLogin(data) {
    return axios.post('/partner/queryAllPartnerByLogin', data)
}

// 追随或取消追随,=关注，取消关注
export function setFollow(data) {
    return axios.post('/ccchat/person/setFollow', data)
}

// 是否追随或取消追随,=是否关注，取消关注
export function isFollow(data) {
    return axios.post('/ccchat/person/isFollow', data)
}

//获取对象记录权限
export function getPermissionById(data) {
    return axios.post('/objectdetail/getPermissionById', data)
}

// 自定义按钮
export function openCall(data) {
    return axios.post('/openCall/common', data)
}

// 解除记录锁定状态
export function recordUnLocked(data) {
    return axios.post('/objectdetail/recordUnLocked', data)
}

// 获取查找信息
export function getLookupInfo(data) {
    return axios.post('/lookup/getLookupInfo', data)
}

// 根据名称搜索对象记录接口--查找字段自动搜索
export function queryMoreDataByName(data) {
    return axios.post('objectdetail/queryMoreDataByName', data)
}

// 获取对象详细信息
export function getDetail(data) {
    return axios.post('/objectdetail/getDetail', data)
}

// 查找带值
export function getLookupRelatedFieldValue(data) {
    return axios.post('/lookup/getLookupRelatedFieldValue', data)
}

// 查询动态布局规则
export function getDynamicLayoutListDetail(data) {
    return axios.post('/objectdetail/getDynamicLayoutListDetail', data)
}

// 获取选项列表值
export function getSelectValue(data) {
    return axios.post('/view/getSelectValue', data)
}

// 添加或更新对象记录
export function save(data) {
    return axios.post('/objectInfo/save', data)
}

// 获取记录类型
export function getRecordType(data) {
    return axios.post('/batch/getRecordType', data)
}

// 获取新建事件相关项
export function getDialigItem(data) {
    return axios.post('activity/getQuickActivityPage', data)
}

// 删除记录
export function del(data) {
    return axios.post('/objectdetail/delete', data)
}