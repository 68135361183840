<template>
  <div
    :class="[
      'conversation-container',
      showScrollBar ? 'case-detail-scroll' : 'case-detail-noScroll',
    ]"
    id="scrollContent"
    @scroll="scrollContent"
  >
    <div class="component" v-loading="showLoading">
      <div v-show="!isSend">
        <div v-for="(item, index) in dataList" :key="index">
          <call
            ref="callRef"
            v-if="item.object == 'voice'"
            :infoObj="item"
            @toSend="toSend"
            :EmailInfo="isEmail"
            :contactEmail="contactEmail"
            :Num="arrLength"
          >
            <record-player :infoObj="item"></record-player>
          </call>
          <chatonline
            ref="chatonlineRef"
            @toSend="toSend"
            v-if="item.object == 'conversation'"
            :conversationList="item"
            :EmailInfo="isEmail"
            :contactEmail="contactEmail"
            :Num="arrLength"
          />
          <mail
            ref="mailRef"
            v-if="item.object == 'emailObject'"
            :EmailInfo="isEmail"
            :emailList="item"
            @toSend="toSend"
            @forwardEmail="forwardEmail"
            :Num="arrLength"
          />
          <evaluate
            ref="evaluateRef"
            @toSend="toSend"
            v-if="item.object == 'serviceEvaluation'"
            :EmailInfo="isEmail"
            :contactEmail="contactEmail"
            :contactName="contactName"
            :evaluateInfo="item"
            :Num="arrLength"
          />
        </div>
      </div>

      <!-- <case-line></case-line> -->
      <div v-show="isSend">
        <!-- 发邮件页 -->
        <div v-show="Associated">
          <sendEmail
            ref="sendEmail"
            @closeEmailDialog="closeEmailDialog"
            :showFooterToolbar="true"
            :emailIsOperation="true"
            :isShowRelated="false"
          ></sendEmail>
        </div>
        <!-- 邮件关联页 -->
        <div v-show="!Associated">
          <relation @AssociatedMailbox="AssociatedMailbox"> </relation>
        </div>
      </div>
      <div class="no-data" v-show="!isSend && arrLength === 0">
        <img class="no-data-img" src="./../assets/releated-none.svg" alt="" />
      </div>
    </div>

    <close-case
      :cId="id"
      :isSend="isSend"
      @close="close"
      :contactEmail="contactEmail"
    ></close-case>
  </div>
</template>

<script>
import * as controlPanelApi from "../api.js";
import RecordPlayer from "@/views/controlPanel/components/record-player";
// import caseLine from "@/views/controlPanel/components/case-line";
import sendEmail from "@/components/Email/sendEmail.vue";
import * as Emailobject from "../api.js";
import call from "./conversion-components/call.vue"; // 通话
import chatonline from "./conversion-components/chatOnline.vue"; // 在线聊天
import evaluate from "./conversion-components/evaluate.vue"; // 评价
import mail from "./conversion-components/mail.vue"; // 邮件
import relation from "@/views/controlPanel/components/relation.vue"; //邮件关联页
import closeCase from "@/views/controlPanel/components/close-case.vue"; // 关闭个案

export default {
  components: {
    RecordPlayer,
    sendEmail,
    relation,
    call,
    chatonline,
    evaluate,
    mail,
    closeCase,
  },
  data() {
    return {
      id: "",
      contactName: "",
      contactEmail: "",
      arrLength: -1,
      showLoading: true,
      //会话列表
      dataList: [],
      //是否为发送邮件
      isSend: false,
      //邮件是否关联
      Associated: false,
      //回复还是转发
      sendStatus: "",
      //邮件数据
      emailMsg: {},
      //邮件列表
      isEmail: [],
      // isShowForwardicons: true, // 是否显示转发的图标
      showScrollBar: false, // 是否展示滚动条
      scrollTimer: null, // 滚动条定时器
    };
  },
  mounted() {
    // this.AllSessions();
    this.isSend = false;
    this.$bus.$on("servephoneCallback", this.servephoneCallbackFn);
    //左侧切换个案卡片时关闭邮件页
    this.$Bus.$on("Close-mail-page", this.closeMailPageFn);
    // 分立个案后刷新会话相关列表
    this.$bus.$on("fresh-conversation-related", this.AllSessions);
  },
  beforeDestroy() {
    this.$bus.$off("servephoneCallback", this.servephoneCallbackFn);
    //左侧切换个案卡片时关闭邮件页
    this.$Bus.$off("Close-mail-page", this.closeMailPageFn);
    // 分立个案后刷新会话相关列表
    this.$bus.$off("fresh-conversation-related", this.AllSessions);
  },
  methods: {
    servephoneCallbackFn(type) {
      if (type == "callBack") {
        this.callBackClick();
      }
      if (type == "replyMail") {
        let obj = this.isEmail.length == 0 ? {} : this.isEmail[0];
        this.toSend(true, obj);
      }
      if (type == "forwardMail") {
        let obj = this.isEmail.length == 0 ? {} : this.isEmail[0];
        this.forwardEmail(true, obj);
      }
    },
    closeMailPageFn() {
      this.isSend = false;
    },
    // 监听会话记录滚动
    scrollContent(event) {
      //关闭发邮件组件中的select下拉选项
      this.$refs.sendEmail.closeSelect();
      let roll = 0; // 滚动的值
      let stop = 0; // 停止时候的值
      let that = this;
      let e = event || event.target;
      // 每次滑动前清除定时器
      clearTimeout(that.scrollTimer);
      // 滚动的时候展示滚动条
      that.showScrollBar = true;
      roll = e.srcElement.scrollTop;
      // 
      // 每次滚动的时候，都让box回到原来的宽度
      that.scrollTimer = setTimeout(function () {
        stop = document.getElementById("scrollContent").scrollTop;
        // 
        if (stop == roll) {
          that.showScrollBar = false;
        }
      }, 1500);
    },
    //发送邮件后回到会话列表
    closeEmailDialog() {
      this.$bus.$emit("fresh-conversation-related");
      this.isSend = false;
    },
    //关闭邮件页
    close(params) {
      this.isSend = params;
    },
    //关联邮箱后是回复还是转发
    AssociatedMailbox() {
      if (this.sendStatus == "toSend") {
        this.toSend(true, this.emailMsg);
      } else {
        this.forwardEmail(true, this.emailMsg);
      }
    },
    //打开邮件页
    async toSend(params, list, isEmail) {
      let resultData = await Emailobject.getEmailPageInfo({});
      if (resultData.data.relatedActiveMailSetting) {
        //为相关项赋值
        this.sendStatus = "toSend";
        this.emailMsg = list;
        let obj = {};
        let id = this.id || this.$route.query.caseId;
        obj.objectLabel = "008";
        obj.isDisabled = true;
        obj.relateidccname = this.$route.query.caseOrder;
        this.$Bus.$emit("component-Set-val", obj);
        this.isSend = params;
        this.Associated = true;
        if (isEmail) {
          this.$refs.sendEmail.replyEmail(list, id, isEmail);
        } else {
          controlPanelApi.getCaseEmailByCaseid({ caseId: id }).then((res) => {
            if (res.data[0].emailaddress) {
              let fromaddressObj = {};
              fromaddressObj.fromaddress = res.data[0].emailaddress;
              this.$refs.sendEmail.replyEmail(fromaddressObj, id, isEmail);
            } else {
              this.$refs.sendEmail.replyEmail({}, id, isEmail);
            }
          });
        }
      } else {
        this.isSend = params;
        this.Associated = false;
      }
    },
    //转发邮件
    async forwardEmail(params, list) {
      let resultData = await Emailobject.getEmailPageInfo({});
      if (resultData.data.relatedActiveMailSetting) {
        //为相关项赋值
        this.sendStatus = "forwardEmail";
        this.emailMsg = list;
        let obj = {};
        obj.objectLabel = "008";
        obj.isDisabled = true;
        obj.relateidccname = this.$route.query.caseOrder;
        this.$Bus.$emit("component-Set-val", obj);
        this.isSend = params;
        this.Associated = true;
        this.$refs.sendEmail.forwardEmail(list, this.$route.query.caseId);
      } else {
        this.isSend = params;
        this.Associated = false;
      }
    },
    //获取全部会话
    async AllSessions() {
      this.showLoading = true;
      this.arrLength = -1;
      let resultData = await controlPanelApi.getCaseConversationDetail({
        caseId: this.id || this.$route.query.caseId,
        pageSize: 10,
        pageNum: 1,
      });
      if (resultData.result) {
        this.dataList = resultData.data.dataList;
        this.arrLength = resultData.data.totalCount || 0;
        this.$emit("updateConverNum", this.arrLength);
        this.showLoading = false;
        this.isEmail = resultData.data.dataList.filter((item) => {
          return item.object == "emailObject";
        });
      }
    },
    /**
     * 父组件ref调用
     */
    init(id) {
      this.id = id;
      this.AllSessions();
      this.getContact();
    },

    // 获取联系人信息
    async getContact() {
      let conf = {
        caseid: this.id,
      };
      let res = await controlPanelApi.getContactInfo(conf);
      if (res.result) {
        if (res.data.length === 1) {
          this.contactName = res.data[0].name;
          this.contactEmail = res.data[0].email;
        } else {
          this.contactName = "";
          this.contactEmail = "";
        }
      }
    },
    // 转发icon的点击事件
    // handleClick(type) {},

    // 关闭台上电话回复
    callBackClick() {
      let conf = {
        caseid: this.id || this.$route.query.caseId,
      };
      controlPanelApi.getContactInfo(conf).then((res) => {
        let phone = res.data[0].shouji || res.data[0].dianhua;
        this.$bus.$emit("newCLick", phone);
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.fotter-toolbar {
  width: 56.2%;
  height: 44px;
  box-shadow: 0px -2px 4px 0px rgba(0, 0, 0, 0.11);
  background: #fff;
  position: fixed;
  bottom: 12px;
  right: 0;
  z-index: 10;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
  .toolbar {
    margin: 0;
  }
  .footer-btn {
    display: flex;
    justify-content: flex-end;
    cursor: pointer;
    .f-btn {
      width: 82px;
      height: 44px;
      line-height: 44px;
      text-align: center;
    }
    .f-bg {
      background: #ebf1ff;
      color: #2d6cfc;
    }
  }
  .f-icon-box {
    background: #d5e2ff;
  }
  .f-icon {
    width: 16px;
    height: 16px;
    position: relative;
    top: 50%;
    transform: translateY(-50%);
  }
}
.f-send-menu {
  border: 0;
}
.conversation-container {
  overflow: auto;
  height: calc(100vh - 370px);
  margin-top: 20px;
  background-color: #fff;
  position: relative;
  padding-bottom: 30px;
  .component {
    padding: 0px 32px;
    min-height: 300px;
    .no-data {
      padding: 50px;
      display: flex;
      align-items: center;
      justify-content: center;
      &-img {
        width: 100%;
        height: 351px;
      }
    }
  }
}
.case-detail-noScroll::-webkit-scrollbar {
  width: 0px;
  height: 10px;
}
.case-detail-scroll::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}
</style>