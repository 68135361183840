<template>
  <div class="conversation-call conversation">
    <div class="conversation-top">
      <div class="to-left">
        <div class="avater">
          <svg
            aria-hidden="true"
            class="icon"
            style="width: 100%; height: 100%"
          >
            <use
              :href="
                infoObj.codeValueCn == $t('label.callcenter.call.out')
                  ? '#icon-headPortrait_inside_send1'
                  : '#icon-headPortrait_outside_get'
              "
            ></use>
          </svg>
        </div>
        <div class="perInfo">
          <div>
            <span
              class="name"
              v-if="infoObj.codeValueCn == $t('label.callcenter.call.out')"
              >{{ infoObj.agentname }}</span
            >
            <span v-else
              >{{
                infoObj.contactname ||
                infoObj.vvisitorname ||
                $t("front-console-no-contact")
              }}
            </span>
            <span>{{ this.formatData(infoObj.createdate) }}</span>
            <span>{{ $t("front-kefu-moudle-call") }}</span>
          </div>
          <div>
            <span>{{ $t("label.chatter.addressee") }}</span>
            <span>{{ infoObj.callednumber }}</span>
          </div>
        </div>
      </div>
      <div class="point-box">
        <el-dropdown
          @visible-change="active = !active"
          @command="handleCommand"
        >
          <div class="el-dropdown-link point-icon">
            <svg
              aria-hidden="true"
              class="icon"
              style="width: 100%; height: 100%"
            >
              <use
                :href="!active ? '#icon-full_more_def' : '#icon-full_more_cli'"
              ></use>
            </svg>
          </div>
          <el-dropdown-menu slot="dropdown" class="point-li">
            <el-dropdown-item
              v-for="(item, index) in moreOperationData"
              :command="item.type"
              :key="index"
              @mouseover.native="enter(index)"
              @mouseout.native="outer(index)"
            >
              <span class="point-icon">
                <svg
                  aria-hidden="true"
                  class="icon"
                  style="width: 100%; height: 100%"
                >
                  <use :href="!item.isHov ? item.icon : item.hovicon"></use>
                </svg> </span
              ><span>{{ item.text }}</span>
            </el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
    </div>
    <div class="conversation-content warp">
      <div class="content-call">
        <div class="call-icon">
          <svg
            aria-hidden="true"
            class="icon"
            style="width: 100%; height: 100%"
          >
            <use href="#icon-console_conversation_call"></use>
          </svg>
        </div>
        <div class="call-detail">
          <div>{{ $t("front-kefu-moudle-call-recorder") }}</div>
          <slot></slot>
          <div
            class="hidden-call"
            @click="isShowCalldetail = !isShowCalldetail"
          >
            {{
              isShowCalldetail
                ? $t("label.custom.page.hide")
                : $t("label.email.show")
            }}{{ $t("front-kefu-moudle-call-detail") }}
          </div>
          <ul class="call-detail-desc" v-show="isShowCalldetail">
            <li v-for="(item, index) in callDetailData" :key="index">
              <span class="call-title">{{ item.title }}</span>
              <span>{{ item.desc }}</span>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <separateCase
      :separateCaseDialog="separateCaseDialog"
      :recordId="recordId"
      @closeSeparate="closeSeparate"
    />
  </div>
</template>
<script>
import separateCase from "@/views/controlPanel/components/conversion-components/components/separateCase.vue";
import { getPermissionById } from "../../api";

export default {
  props: {
    infoObj: {
      type: Object,
      default: ()=>({}),
    },
    EmailInfo: {
      type: Array,
      default: () => {
        return [];
      },
    },
    contactEmail: {
      type: String,
      default: "",
    },
    Num: {
      type: Number,
      default: 0,
    },
  },

  watch: {
    Num: {
      immediate: true,
      handler: function (newVal) {
        let that = this;
        if (newVal > 1) {
          that.moreOperationData = [
            {
              text: this.$$i18n.t("front-console-conversation-btn2"),
              icon: "#icon-full_replyMail_hov",
              hovicon: "#icon-full_replyMail_cli",
              isHov: false,
              type: "replyMail",
              index: 0,
            },
            {
              text: this.$i18n.t("front-console-conversation-btn1F"),
              icon: "#icon-full_callBack_hov",
              hovicon: "#icon-full_callBack_cli",
              isHov: false,
              type: "callBack",
              index: 1,
            },
            {
              text: this.$i18n.t("front-console-conversation-btn4"),
              icon: "#icon-full_separationCase_hov",
              hovicon: "#icon-full_separationCase_cli",
              isHov: false,
              type: "separationCase",
              index: 2,
            },
          ];
        } else {
          that.moreOperationData = [
            {
              text: this.$i18n.t("front-console-conversation-btn2"),
              icon: "#icon-full_replyMail_hov",
              hovicon: "#icon-full_replyMail_cli",
              isHov: false,
              type: "replyMail",
              index: 0,
            },
            {
              text: this.$i18n.t("front-console-conversation-btn1"),
              icon: "#icon-full_callBack_hov",
              hovicon: "#icon-full_callBack_cli",
              isHov: false,
              type: "callBack",
              index: 1,
            },
          ];
        }
      },
    },
  },
  components: {
    separateCase,
  },
  data() {
    return {
      isShowCalldetail: true, // 是否显示通话详情
      separateCaseDialog: false,
      recordId: "",
      caseid: this.$route.query.caseId,
      callDetailData: [
        {
          title: this.$i18n.t("front-kefu-moudle-calling-num"),
          desc: "",
        },
        {
          title: this.$i18n.t("front-kefu-moudle-called-num"),
          desc: "",
        },
        {
          title: this.$i18n.t("label.emailsync.status.time"),
          desc: "",
        },
        {
          title: this.$i18n.t("front-kefu-moudle-length-min"),
          desc: "",
        },
        {
          title: this.$i18n.t("front-kefu-moudle-called-user"),
          desc: "",
        },
      ],
      moreOperationData: [
        {
          text: this.$i18n.t("front-console-conversation-btn2"),
          icon: "#icon-full_replyMail_hov",
          hovicon: "#icon-full_replyMail_cli",
          isHov: false,
          type: "replyMail",
          index: 0,
        },
        {
          text: this.$i18n.t("front-console-conversation-btn1"),
          icon: "#icon-full_callBack_hov",
          hovicon: "#icon-full_callBack_cli",
          isHov: false,
          type: "callBack",
          index: 1,
        },
        {
          text: this.$i18n.t("front-console-conversation-btn4"),
          icon: "#icon-full_separationCase_hov",
          hovicon: "#icon-full_separationCase_cli",
          isHov: false,
          type: "separationCase",
          index: 2,
        },
      ], // 会话记录操作
      active: false, // 会话记录操作选中与否
    };
  },
  mounted() {
    if (this.infoObj.codeValueCn == this.$i18n.t("label.callcenter.call.out")) {
      this.callDetailData[4].desc =
        this.infoObj.contactname || this.infoObj.vvisitorname;
    } else if (
      this.infoObj.codeValueCn == this.$i18n.t("label.callcenter.call.in")
    ) {
      this.callDetailData[4].desc = this.infoObj.agentname;
    }
    this.callDetailData[0].desc = this.infoObj.callingnumber;
    this.callDetailData[1].desc = this.infoObj.callednumber;
    this.callDetailData[2].desc = this.formatData(this.infoObj.voicestarttime);
    this.callDetailData[3].desc = this.infoObj.voiceduration;
  },
  methods: {
    enter(index) {
      this.moreOperationData[index].isHov = true;
    },
    outer(index) {
      this.moreOperationData[index].isHov = false;
    },
    // 点击菜单项
    handleCommand(command) {
      // 电话回复
      if (command == "callBack") {
        let phone = "";
        if (
          this.infoObj.codeValueCn == this.$i18n.t("label.callcenter.call.out")
        ) {
          phone = this.infoObj.callednumber;
        } else if (
          this.infoObj.codeValueCn == this.$i18n.t("label.callcenter.call.in")
        ) {
          phone = this.infoObj.callingnumber;
        }
         this.$bus.$emit("newCLick", phone);
      }
      // 拆分新个案
      else if (command == "separationCase") {
        getPermissionById({ id: this.caseid }).then((res) => {
          if (res.data.isEdit) {
            this.separateCaseDialog = true;
            this.recordId = this.infoObj.id;
          } else {
            this.$message.warning(
              this.$i18n.t("front-kefu-moudle-v1-Operation-without0-permission")
            );
          }
        });
      } else if (command == "replyMail") {
        let obj = this.EmailInfo.length == 0 ? {} : this.EmailInfo[0];
        obj.fromaddress = this.contactEmail;
        this.$emit("toSend", true, obj,false);
      }
    },

    closeSeparate() {
      this.separateCaseDialog = false;
    },

    // 时间戳转换
    formatData(val) {
      if (val) {
        var date = new Date(val); //时间戳为10位需*1000，时间戳为13位的话不需乘1000
        var Y = date.getFullYear() + "-";
        var M =
          (date.getMonth() + 1 < 10
            ? "0" + (date.getMonth() + 1)
            : date.getMonth() + 1) + "-";
        var D =
          (date.getDate() < 10 ? "0" + date.getDate() : date.getDate()) + " ";
        var h =
          (date.getHours() < 10 ? "0" + date.getHours() : date.getHours()) +
          ":";
        var m =
          (date.getMinutes() < 10
            ? "0" + date.getMinutes()
            : date.getMinutes()) + ":";
        var s =
          date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds();
        return Y + M + D + h + m + s;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.conversation {
  margin: 20px 0;
  .conversation-top {
    width: 100%;
    display: flex;
    justify-content: space-between;
    .to-left {
      display: flex;
      justify-content: space-between;

      .avater {
        width: 48px;
        height: 48px;
        margin-right: 20px;
      }
      .perInfo {
        color: #666;
        > div {
          height: 22px;
          line-height: 22px;
        }
        span {
          margin-right: 12px;
        }
        .name {
          font-size: 16px;
          color: #333;
        }
      }
    }
    .point-icon {
      cursor: pointer;
      width: 30px;
      height: 20px;
    }
  }
  .warp {
    width: 86%;
    margin-left: 67px;
    margin-top: 16px;
  }
  .conversation-content {
    .content-call {
      padding: 16px;
      border-radius: 4px;
      border: 1px solid #dedcda;
      display: flex;
      justify-content: flex-start;
      .call-icon {
        width: 20px;
        height: 20px;
        margin-right: 10px;
      }
      .call-detail {
        width: 100%;
        div {
          margin-bottom: 16px;
        }
        .hidden-call {
          font-size: 12px;
          color: #006dcc;
          cursor: pointer;
        }
        .call-detail-desc {
          display: inline-block;
          width: 100%;
          > li {
            height: 24px;
            line-height: 24px;
          }
          > li:nth-child(odd) {
            background: #fafaf9;
          }
          .call-title {
            display: inline-block;
            min-width: 103px;
            margin-right: 20px;
            line-height: 16px;
          }
        }
      }
    }
  }
}
// 搁置样式
.point-li {
  border: 0;
  .point-icon {
    display: inline-block;
    width: 30px;
    height: 20px;
    margin-right: 5px;
    position: relative;
    top: 4px;
  }
}
</style>