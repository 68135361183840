/*
 * @Author: your name
 * @Date: 2021-10-18 10:34:53
 * @LastEditTime: 2022-02-23 19:53:55
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \lightning-web\src\api\callCenter.js
 */
import axios from "@/config/httpConfig";

// 获取详情页标签
export function GetBrief(data) {
  return axios.post('/objectdetail/getBrief', data)
}
// 获取默认视图
export function SetViewDefault(data) {
  return axios.post('/view/list/setViewDefault', data)
}
// 获取视图列表
export function GetViewList(data) {
  return axios.post('/view/list/getViewList', data)
}

// 保存呼叫中心设置
export function saveCCSetting(data) {
  return axios.post("callCenter/saveCCSetting", data);
}
// 获取呼叫中心设置
export function getCallCenterInfo(data) {
  return axios.post("callCenter/getCallCenterInfo", data);
}
// 查询坐席下拉列表
export function getClientList(data) {
  return axios.post("customerSet/getClientList", data);
}
// 查询绑定列表
export function getBindList(data) {
  return axios.post("customerSet/getBindList", data);
}
// 用户绑定坐席电话
export function bindClientUser(data) {
  return axios.post("customerSet/bindClientUser", data);
}
// 编辑绑定信息
export function updateClientUser(data) {
  return axios.post("customerSet/updateClientUser", data);
}
// 删除绑定信息
export function deleteClientUser(data) {
  return axios.post("customerSet/deleteClientUser", data);
}
// 批量绑定坐席
export function bindClientUsers(data) {
  return axios.post("customerSet/bindClientUsers", data);
}
// 同步绑定天润系统的电话号码
export function bindTel(data) {
  return axios.post("customerSet/bindTel", data);
}
// 发起绑定电话号码验证
export function launchBindTelCheck(data) {
  return axios.post("customerSet/launchBindTelCheck", data);
}
// 确认绑定电话号码验证
export function bindTelCheck(data) {
  return axios.post("customerSet/bindTelCheck", data);
}
// 根据坐席号或者用户查询绑定信息
export function getBindByCnoOrUserId(data) {
  return axios.post("customerSet/getBindByCnoOrUserId", data);
}
// 获取Amazon区域列表
export function getAwsRegions(data) {
  return axios.post("/callCenter/getAwsRegions", data);
}
// 天润查询通话录音记录
export function getCdr(data) {
  return axios.post("/customerSet/getCdr", data);
}
// 天润保存登入密码
export function saveTianRunLogin(data) {
  return axios.post("/customerSet/saveTianRunLogin", data);
}
// 查询手机号码归属地
export function getPhoneCity(data) {
  return axios.post("/customerSet/getPhoneCity", data);
}
// 获取amazon实例列表
export function getInstanceAlias(data) {
  return axios.post("callCenter/listInstance", data);
}
// 保存amzon实例名
export function saveInstance(data) {
  return axios.post("/callCenter/saveInstance", data);
}

// 呼叫中心访客创建规则
export function creatVisitor(data) {
  return axios.post("/callCenter/creatVisitor", data);
}
// 根据电话号码识别联系人与客户
export function IdentifyPhonenumber(data) {
  return axios.post("/callCenter/IdentifyPhonenumber", data);
}
// 呼叫中心保存个案
export function saveCase(data) {
  return axios.post("/callCenter/saveCase", data);
}
// 根据语音记录查询语音记录id
export function getVoiceByVoicerecording(data) {
  return axios.post("/customerSet/getVoiceByVoicerecording", data);
}
// 更新语音转接内容
export function updateTransfer(data) {
  return axios.post("/customerSet/updateTransfer", data);
}
// 获取aws语音录音
export function getConnectCallRecording(data) {
  return axios.post("/callCenter/getConnectCallRecording", data);
}
// 查询试用坐席号
export function getOnTrial(data) {
  return axios.post("/customerSet/getOnTrial", data);
}
// 天润结束免费试用
export function updateCnoActive(data) {
  return axios.post("/callCenter/updateCnoActive", data);
}
// 天润试用发送邮件
export function sendTianRunEmail(data) {
  return axios.post("/customerSet/sendTianRunEmail", data);
}

// 获取个案列表
export function getCaseListData(data) {
  return axios.post('/caseServiceConsole/getViewListAjax', data)
}
// 根据个案上的联系人字段获取联系人信息
export function getContactInfo(data) {
  return axios.post('caseServiceConsole/getContact', data)
}
// 识别联系人
export function identifyContact(data) {
  return axios.post('caseServiceConsole/identifyContact', data)
}
// 关联联系人
export function associateContact(data) {
  return axios.post('caseServiceConsole/associateContact', data)
}
// 根据个案id获取固定数据
export function getDateByCaseid(data) {
  return axios.post('caseServiceConsole/getDateByCaseid', data)
}
// 更改个案状态
export function changeCaseStatus(data) {
  return axios.post('caseServiceConsole/changeCaseStatus', data)
}
// 个案历史记录
export function getCccaseByContactid(data) {
  return axios.post('caseServiceConsole/getCccaseByContactid', data)
}
//拆分个案
export function splitCase(data) {
  return axios.post('caseServiceConsole/splitCase', data)
}
//全部会话记录展示
export function getCaseConversationDetail(data) {
  return axios.post('caseServiceConsole/getCaseConversationDetail', data)
}

//展示会话记录下的聊天记录
export function getChatRecord(data) {
  return axios.post('HuanxinChatRecord/getChatRecord', data)
}

//根据个案id获取个案联系人邮箱字段
export function getCaseEmailByCaseid(data) {
  return axios.post('/caseServiceConsole/getCaseEmailByCaseid', data)
}


//邮件发送页
export function getEmailPageInfo(data) {
  return axios.post('emailObject/getEmailPageInfo', data)
}

//获取邮件模板
export function getEmailTemplate(onlyShowMyTemplate, searchWord) {
  return axios.post('emailObject/getEmailTemplates', { onlyShowMyTemplate: onlyShowMyTemplate, searchWord: searchWord })
}

//置顶模板
export function toTopTemplate(options) {
  return axios.post('emailObject/topEmailTemplate', options)
}

//保存邮件模板
export function saveEmailTemplate(options) {
  return axios.post('emailObject/saveEmailTemplate', options)
}
//发送邮件
export function sendemail(data) {
  return axios.post('emailObject/sendEmailObject', data)
}

//获取对象列表
export function getobjectlist() {
  return axios.post('object/getAllObjectList')
}

//获取字段
export function getsize(data) {
  return axios.post('emailObject/queryFields', data)
}

//插入字段
export function insertFields(data) {
  return axios.post('emailObject/mergeField', data)
}

//收件箱
export function inbox(data) {
  return axios.post('emailObject/queryEmailList', data)
}

//邮件详情
export function emaildetail(data) {
  return axios.post('emailObject/detail', data)
}

//更改邮件状态
export function changeread(data) {
  return axios.post('emailObject/changeRead', data)
}

//删除邮件
export function deleteemail(data) {
  return axios.post('emailObject/delete', data)
}
//根据模板id和记录获取模板内容获取
export function getTemplateSubject(data) {
  return axios.post('emailObject/getTemplateBody', data)
}

//获取收件人信息
export function getReceivePeo(data) {
  return axios.post('emailObject/getShouJianRenData', data)
}

//搜索收件人
export function seachemail(data) {
  return axios.post('emailObject/searchEmail', data)
}

//上传文件
export function uploadfile(data) {
  return axios.post('file/upload', data)
}

// 查询邮件签名
export function queryEmailSign(data) {
  return axios.post('/emailObject/querySign', data)
}

// 保存邮件签名
export function saveEmailSign(data) {
  return axios.post('/emailObject/saveSign', data)
}


//更改邮件关联记录
export function changerelateid(data) {
  return axios.post('emailObject/changeRelateid', data)
}

//删除邮件关联记录
export function deleterelateid(data) {
  return axios.post('emailObject/deleteRelateid', data)
}

//获取邮件数量
export function getemailnum(data) {
  return axios.post('emailObject/queryEmailCount', data)
}
/**
 * 请求获取用户信息
 * @param {data} 请求参数 
 * @param {enforce} 是否强制请求最新用户信息 
 * @returns 用户信息
 */
export function getUserInfo(data, enforce = false) {
  if (enforce || localStorage.getItem("userInformation") === null) {
    return axios.get('/user/getUserInfo', data)
  } else {
    return new Promise(function (resolve) {
      resolve(JSON.parse(localStorage.getItem("userInformation"))); // 回调成功返回
    })
  }
}


//查看翻译
export function findArticleIdTranslate(data) {
  return axios.post("/KnowledgeArticle/findArticleIdTranslate", data);
}

//归档
export function articlePigeonhole(data) {
  return axios.post("/KnowledgeArticle/articlePigeonhole", data);
}

//删除知识文章
export function removeArticle(data) {
  return axios.post("/knowledgeArticleOperate/removeArticle", data);
}

// 根据语言和状态获取知识文章
export function findConditionKnowledgeArticle(data) {
  return axios.post("/KnowledgeArticle/findConditionKnowledgeArticle", data);
}

// 查询语言接口
export function queryLanguage(data) {
  return axios.post("/knowledgeTranslate/queryLanguage", data);
}

// 判断是否是作为主版本发布的条件
export function ismasterVersion(data) {
  return axios.post("/knowledge/ismasterVersion", data);
}

// 发布文章接口
export function publishArticle(data) {
  return axios.post("/knowledge/publishArticle", data);
}

// 指派文章接口
export function designate(data) {
  return axios.post("/knowledgeDesignate/designate", data);
}

// 提交翻译校验
export function designateCheckout(data) {
  return axios.post("/knowledgeTranslate/designateCheckout", data);
}

// 提交翻译
export function translate(data) {
  return axios.post("/knowledgeTranslate/translate", data);
}

// 删除发布状态为草稿的知识文章
export function removeDraft(data) {
  return axios.post("/knowledgeArticleOperate/removeDraft", data);
}

// 恢复发布状态为已归档的知识文章
export function recoverArchived(data) {
  return axios.post("/knowledgeArticleOperate/recoverArchived", data);
}
// 作为草稿编辑
export function asDraftEdit(data) {
  return axios.post("/knowledgeArticleOperate/asDraftEdit", data);
}

// 知识文章 版本列表
export function queryArticleVersionList(data) {
  return axios.post("/knowledgeArticleOperate/queryArticleVersionList", data);
}

// 知识文章下的个案相关列表
export function findArticleAndIndividualCase(data) {
  return axios.post('/KnowledgeArticle/findArticleAndIndividualCase', data)
}

// 全局搜索高级筛选
export function getGlobalSearchSingleResult(data) {
  return axios.post("/KnowledgeArticle/getGlobalSearchSingleResult", data);
}

// 知识文章批量归档
export function batchArticlePigeonhole(data) {
  return axios.post('/KnowledgeArticle/batchArticlePigeonhole', data)
}

// 批量删除 知识文章
export function batchRemoveArticle(data) {
  return axios.post('/knowledgeArticleOperate/batchRemoveArticle', data)
}

// 批量删除发布状态为草稿的知识文章
export function batchRemoveDraft(data) {
  return axios.post('/knowledgeArticleOperate/batchRemoveDraft', data)
}

// 批量恢复 发布状态为已归档的知识文章
export function batchRecoverArchived(data) {
  return axios.post('/knowledgeArticleOperate/batchRecoverArchived', data)
}

// 获取快速操作错误信息
export function getErrorInfo(data) {
  return axios.post('/KnowledgeArticle/getErrorInfo', data)
}

// 解决关闭错误信息
export function operationErrorInfo(data) {
  return axios.post('/KnowledgeArticle/operationErrorInfo', data)
}

// 查询数据分类和数据分类组
export function queryDataCategory(data) {
  return axios.post('dataCategory/queryDataCategory', data)
}

// 保存数据分类选择
export function saveDataCategory(data) {
  return axios.post('/dataCategory/saveDataCategory', data)
}

// 查询已选择数据分类
export function queryDataCategorySelection(data) {
  return axios.post('/dataCategory/queryDataCategorySelection', data)
}

// 知识文章附加到个案
export function addCaseArticle(data) {
  return axios.post('/KnowledgeArticle/addCaseArticle', data)
}

// 取消附加到个案
export function cancelAttachCase(data) {
  return axios.post('/knowledgeArticleOperate/cancelAttachCase', data)
}

// 通过个案id获取文章
export function querCaseArticle(data) {
  return axios.post('/KnowledgeArticle/querCaseArticle', data)
}

// 推荐文章查询
export function recommendedArticleQuery(data) {
  return axios.post('/knowledgeDesignate/recommendedArticleQuery', data)
}

// 是否允许用户从个案中创建文章
export function judgeAddArticleByCase(data) {
  return axios.post('/knowledgeDesignate/judgeAddArticleByCase', data)
}

// 查找带值
export function getLookupRelatedFieldValue(data) {
  return axios.post('/lookup/getLookupRelatedFieldValue', data)
}

// 获取对象详细信息
export function getDetail(data) {
  return axios.post('/objectdetail/getDetail', data)
}

// 更新视图列表记录
export function updateViewListAjax(data) {
  return axios.post('/view/list/updateViewListAjax', data)
}

// 获取选项列表值
export function getSelectValue(data) {
  return axios.post('/view/getSelectValue', data)
}

//获取对象记录权限
export function getPermissionById(data) {
  return axios.post('/objectdetail/getPermissionById', data)
}

// 获取视图列表页面信息
export function getViewListRecently(data) {
  return axios.post('/view/list/getViewListRecently', data)
}
// 判断是否有知识库用户
export function getKnowledgePermissionById(data) {
  return axios.post('/knowledgeDesignate/getKnowledgePermissionById', data)
}