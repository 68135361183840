var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:_vm.routerName === 'DETAIL' ? 'main' : 'mainOther'},[(_vm.list.length > 0)?_c('el-card',{staticClass:"box-main_top",attrs:{"id":"box-main_top","shadow":"never"}},[(_vm.isSkeleton)?_c('Wang-skeleton',{staticStyle:{"background":"#fff"},attrs:{"type":"custom","childrenOption":_vm.relationListSkeletonOption}}):_c('div',{ref:"relatListWidth",class:[_vm.isZhan ? 'relatList' : 'relatListClosde']},[_c('vuedraggable',_vm._l((_vm.list),function(item,i){return _c('dl',{key:i,ref:"relatWidth",refInFor:true,class:_vm.ischecked == i ? 'checkedClass' : '',staticStyle:{"float":"left"},on:{"click":function($event){return _vm.menuBtn(item, i)}}},[_c('dt',[_c('svg',{staticClass:"icon",staticStyle:{"width":"100%","height":"100%"},attrs:{"aria-hidden":"true"}},[_c('use',{attrs:{"href":item.titImg
                    ? '#icon-' + item.titImg
                    : item.relatedlistId == 'projectActivity'
                    ? '#icon-cloudtab87_norm'
                    : item.relatedlistId == 'projectCccat'
                    ? '#icon-cloudtab63_norm'
                    : '#icon-hometab'}})])]),_c('dd',{staticClass:"tit88 spot_s"},[_c('span',{style:({ maxWidth: _vm.multiScreen ? '120px' : '180px' })},[_vm._v(" "+_vm._s(item.relationName)+" ")]),(
                item.relatedlistType !== 'approvalHistory' &&
                item.relatedlistId !== 'project360' &&
                item.relatedlistId !== 'aee2021B4717F5B0VhWd' &&
                item.relatedlistId !== 'aee202142389BA3ZTat1' &&
                item.relatedlistId !== 'projectActivity' &&
                item.relatedlistId !== 'projectCccat' &&
                item.relatedlistId !== 'conversation'
              )?_c('span'):(item.relatedlistId === 'conversation')?_c('span',[_vm._v("("+_vm._s(_vm.conversationNum)+")")]):_vm._e(),_c('svg',{staticClass:"icon",staticStyle:{"width":"12px","margin-left":"10px"},attrs:{"aria-hidden":"true"}},[_c('use',{attrs:{"href":_vm.ischecked == i ? '#icon-console_chat_open' : '#icon-more'}})])])])}),0),_c('div',{directives:[{name:"show",rawName:"v-show",value:(this.totleWidth > this.relatListAllWidth),expression:"this.totleWidth > this.relatListAllWidth"}],staticClass:"open-icon",on:{"click":_vm.afliodMethod,"mouseover":_vm.mouseoverImg,"mouseout":_vm.mouseoutImg}},[_c('svg',{staticClass:"icon",attrs:{"aria-hidden":"true"}},[_c('use',{attrs:{"href":_vm.icons}})])])],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }