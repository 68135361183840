<!--
 * @Author: 张亚欣
 * @Date: 2021-10-09 17:00:05
 * @LastEditTime: 2022-02-18 17:49:12
 * @LastEditors: Please set LastEditors
 * @Description: 项目详情页相关列表组件
 * @FilePath: \lightning-web\src\views\commonObjects\project\relation-list.vue
-->
<template>
  <div :class="routerName === 'DETAIL' ? 'main' : 'mainOther'">
    <!-- 相关列表菜单"-->
    <el-card
      id="box-main_top"
      class="box-main_top"
      shadow="never"
      v-if="list.length > 0"
    >
      <!-- 骨架屏 -->
      <Wang-skeleton
        v-if="isSkeleton"
        type="custom"
        :childrenOption="relationListSkeletonOption"
        style="background: #fff"
      />
      <div
        :class="[isZhan ? 'relatList' : 'relatListClosde']"
        v-else
        ref="relatListWidth"
      >
        <vuedraggable>
          <dl
            v-for="(item, i) in list"
            :key="i"
            @click="menuBtn(item, i)"
            :class="ischecked == i ? 'checkedClass' : ''"
            ref="relatWidth"
            style="float: left"
          >
            <dt>
              <svg
                class="icon"
                aria-hidden="true"
                style="width: 100%; height: 100%"
              >
                <use
                  :href="
                    item.titImg
                      ? '#icon-' + item.titImg
                      : item.relatedlistId == 'projectActivity'
                      ? '#icon-cloudtab87_norm'
                      : item.relatedlistId == 'projectCccat'
                      ? '#icon-cloudtab63_norm'
                      : '#icon-hometab'
                  "
                ></use>
              </svg>
            </dt>
            <dd class="tit88 spot_s">
              <span :style="{ maxWidth: multiScreen ? '120px' : '180px' }">
                {{ item.relationName }}
              </span>
              <span
                v-if="
                  item.relatedlistType !== 'approvalHistory' &&
                  item.relatedlistId !== 'project360' &&
                  item.relatedlistId !== 'aee2021B4717F5B0VhWd' &&
                  item.relatedlistId !== 'aee202142389BA3ZTat1' &&
                  item.relatedlistId !== 'projectActivity' &&
                  item.relatedlistId !== 'projectCccat' &&
                  item.relatedlistId !== 'conversation'
                "
                >
                <!-- 暂时不展示 -->
                <!-- ({{ item.relationSize }}) -->
                </span
              >
              <span v-else-if="item.relatedlistId === 'conversation'"
                >({{ conversationNum }})</span
              >
              <svg
                class="icon"
                aria-hidden="true"
                style="width: 12px; margin-left: 10px"
              >
                <use
                  :href="
                    ischecked == i ? '#icon-console_chat_open' : '#icon-more'
                  "
                ></use>
              </svg>
            </dd>
          </dl>
        </vuedraggable>
        <!-- 相关列表展开收起 -->
        <div
          class="open-icon"
          @click="afliodMethod"
          v-show="this.totleWidth > this.relatListAllWidth"
          @mouseover="mouseoverImg"
          @mouseout="mouseoutImg"
        >
          <!-- <i :class="icons"></i> -->
          <svg class="icon" aria-hidden="true">
            <use :href="icons"></use>
          </svg>
        </div>
      </div>
    </el-card>
  </div>
</template>

<script>
import Vue from "vue";
import vueWangIview from "vue-wang-iview"; // 骨架屏
import "vue-wang-iview/dist/vue-wang-iview.css";
Vue.use(vueWangIview);
import * as SetViewApi from "./api";
import vuedraggable from "vuedraggable";
export default {
  name: "Activity",
  components: {
    vuedraggable,
  },
  props: {
    dataId: {
      type: String,
      default: "",
    },
    screenWidth: {
      type: [String, Number],
      default: "",
    },
    routerName: {
      type: String,
      default: "",
    },
    // 是否来自服务控制台
    detailType: {
      type: String,
      default: "project",
    },
    // 会话相关列表数据个数
    conversationNum: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      ischecked: "",
      list: [
        {
          relationName: this.$i18n.t("label.project360"), //项目360
          relatedlistId: "project360",
        },
      ], //相关列表数组
      id: this.$route.params.id || this.dataId, // 记录id
      relationListSkeletonOption: [
        // 列表
        {
          options: {
            row: 2,
            column: 4,
            width: "14.5vw",
            height: "20px",
            backgroundColor: "#f3f2f2",
            justifyContent: "center",
            active: false,
            itemRowMargin: "6px",
            itemColumnMargin: "0px",
            firstWidth: "14.5vw",
            alignItems: "center",
          },
        },
      ],
      isSkeleton: true,
      multiScreen: false, //判断是否在分屏页面下
      icons: "#icon-DropdownMenu_def", //默认展示  关闭列表 向下(浅颜色)
      isZhan: false, //相关列表展开关闭
      relatListAllWidth: 0, //相关列表外层box 的宽
      totleWidth: 0, //相关列表 各子项 宽总和
      isoverAndOut: false,
    };
  },
  created() {
    if (this.$route.path && this.$route.path.split("/")[2] === "multi-screen") {
      this.multiScreen = true;
    } else {
      this.multiScreen = false;
    }
    this.userUrl = this.$cookies.get("domainName");
    this.init();
  },
  mounted() {},
  methods: {
    afliodMethod() {
      // 判断展开还是关闭列表   #icon-StowMenu_cli(向上  深颜色)    #icon-DropdownMenu_cli(向下  深颜色)
      // #icon-StowMenu_def(向上  浅颜色) 恢复默认                 #icon-DropdownMenu_def(向下  浅颜色)
      this.isZhan = this.icons === "#icon-DropdownMenu_cli" ? true : false;
      this.icons =
        this.icons === "#icon-DropdownMenu_cli"
          ? "#icon-StowMenu_cli"
          : "#icon-DropdownMenu_def";
      this.isoverAndOut =
        this.icons === "#icon-DropdownMenu_def" ? false : true;
    },
    // 鼠标移入变成深颜色
    mouseoverImg() {
      this.icons =
        this.isoverAndOut === false
          ? "#icon-DropdownMenu_cli"
          : "#icon-StowMenu_cli";
    },
    // 鼠标移出变成深颜色
    mouseoutImg() {
      this.icons =
        this.isoverAndOut === false
          ? "#icon-DropdownMenu_def"
          : "#icon-StowMenu_def";
    },
    getDomWidth() {
      // 获取相关列表外层box 的宽 和 相关列表 各子项 宽总和
      this.relatListAllWidth = this.$refs.relatListWidth.clientWidth;
      let relatListItem = this.$refs.relatWidth;
      relatListItem.forEach((item) => {
        this.totleWidth += item.clientWidth;
      });
      //
    },
    //获取相关列表对象信息
    //toText为false时只刷新相关列表菜单，不刷新每个相关列表对应数据
    GetRelationList() {
      SetViewApi.GetRelationList({ recordId: this.id }).then((res) => {
        if (res.result && res.returnCode === "1") {
          // 相关列表 各子项 宽总和 置0
          this.totleWidth = 0;
          let data = [];
          data = res.data.filter(
            // "备注"
            (item) =>
              item.relatedlistType != "activityHistory" &&
              item.relatedlistType != "openActivities" &&
              item.relationName !=
                this.$i18n.t("label.weixin.group.description") &&
              item.relatedlistType != "campaign"
          );
          data.forEach((val) => {
            this.$set(val, "titImg", val.tabStyle ? val.tabStyle : "");
          });
          if (this.detailType === "controlPanel") {
            this.list = [
              {
                relationName: this.$i18n.t("front-console-conversation"),
                relatedlistId: "conversation",
              },
              {
                relationName: this.$i18n.t("label.activity"),
                relatedlistId: "projectActivity",
              },
              {
                relationName: this.$i18n.t("label.ccchat.2018"),
                relatedlistId: "projectCccat",
              },
            ];
          } else {
            this.list = [
              {
                relationName: this.$i18n.t("label.project360"),
                relatedlistId: "project360",
              },
            ];
          }
          this.list = this.list.concat(data);
          if (this.$route.query.ischeck == 1) {
            this.list.forEach((item, index) => {
              if (item.relatedlistId == "aee2020029648D89WP76") {
                this.ischecked = index;
              }
            });
          }
          this.$nextTick(() => {
            this.$refs.relationListObj &&
              this.$refs.relationListObj.forEach((relationObj) => {
                relationObj.init();
              });
          });
          this.isSkeleton = false;
          // 获取相关列表外层box 的宽 和 相关列表 各子项 宽总和
          this.$nextTick(() => {
            this.getDomWidth();
          });
        } else {
          this.$message.error(res.returnInfo);
        }
      });
    },
    //点击菜单
    menuBtn(item, index) {
      this.$emit("objectSwitch", item);
      this.$bus.$emit("noneKanban", false);
      this.ischecked = index;
    },
    init() {
      this.isSkeleton = true;
      this.GetRelationList();
    },
  },
  watch: {
    screenWidth() {
      this.getDomWidth();
    },
  },
};
</script>

<style scoped lang="scss">
.detail-enter,
.detail-enter-to {
  height: 0;
}

.detail-enter-active,
.detail-leave-active {
  transition: all 0.4s ease;
}

::v-deep .el-tabs__item {
  font-size: 16px;
}

::v-deep .el-card__body {
  padding: 0px;
}

::v-deep .el-input__icon {
  line-height: 30px;
}

.folder ::v-deep .el-dialog {
  width: 30%;
}
.el-dropdown-link {
  cursor: pointer;
  color: #409eff;
}
.el-icon-arrow-down {
  font-size: 12px;
}

.baseForm {
  overflow: auto;
}

.changeDetailBox {
  position: relative;
  overflow: auto;
  margin-top: 10px;
}

// 用户对象的详情相关列表样式
.main {
  width: 100%;
  position: -webkit-sticky;
  position: sticky;
  top: 60px;
  z-index: 999;
  margin-top: 10px;
}
.mainOther {
  width: 100%;
  z-index: 999;
  margin-top: 10px;
}
// 用户对象的详情相关列表样式
.box-main_top {
  position: relative;
  width: 100%;
  height: auto;
  background: rgba(255, 255, 255, 1);
  border-radius: 3px;
  border: 1px solid rgba(221, 219, 218, 1);
  border-top: none;
  padding: 0 32px;
  .relatList {
    display: flex;
    flex-wrap: wrap;
    // padding: 8px 0;
    // margin: auto;
    box-sizing: border-box;
    dl {
      display: flex;
      padding: 0 15px !important;
      // margin-bottom: 6px;
      margin: 0 !important;
      .spot_s {
        display: flex;
        align-items: center;
        font-size: 12px;
        span:first-child {
          border-bottom: 1px solid #006dcc;
          line-height: 20px;
        }

        span:nth-child(2) {
          border-bottom: 1px solid #006dcc;
          line-height: 20px;
        }
      }
      dt {
        width: 20px;
        height: 20px;
        margin: 10px 10px 0 0;
        vertical-align: 3px;
        img {
          width: 100%;
        }
      }
      dd {
        height: 40px;
        // padding-top: 2px;
        line-height: 40px;
        font-size: 12px;
        margin-bottom: 0;
        margin-left: 0;
        cursor: pointer;
        color: #006dcc;
      }
    }

    .open-icon {
      position: absolute;
      top: 10px;
      right: 32px;
      width: 20px;
      height: 21px;
      line-height: 20px;
      text-align: center;
      cursor: pointer;
      // background-color: gainsboro;
      // font-size: 13px;
      .icon {
        display: inline-block;
        width: 20px;
        height: 20px;
      }
    }
  }
  .relatListClosde {
    display: flex;
    flex-wrap: wrap;
    height: 40px;
    padding-right: 10px;
    overflow-y: hidden;
    // padding: 8px 0;
    // margin: auto;
    box-sizing: border-box;

    dl {
      display: flex;
      padding: 0 15px !important;
      // margin-bottom: 6px;
      margin: 0 !important;
      .spot_s {
        display: flex;
        align-items: center;
        font-size: 12px;
        span:first-child {
          border-bottom: 1px solid #006dcc;
          line-height: 20px;
        }
        span:nth-child(2) {
          border-bottom: 1px solid #006dcc;
          line-height: 20px;
        }
      }
      dt {
        width: 20px;
        height: 20px;
        margin: 10px 10px 0 0;
        vertical-align: 3px;
        img {
          width: 100%;
        }
      }
      dd {
        height: 40px;
        // padding-top: 2px;
        line-height: 40px;
        font-size: 12px;
        margin-bottom: 0;
        margin-left: 0;
        cursor: pointer;
        // border-bottom: 1px solid #006dcc;
        color: #006dcc;
      }
    }
    .open-icon {
      position: absolute;
      top: 10px;
      right: 32px;
      width: 20px;
      height: 21px;
      line-height: 20px;
      text-align: center;
      cursor: pointer;
      // background-color: gainsboro;
      // font-size: 13px;
      .icon {
        display: inline-block;
        width: 20px;
        height: 20px;
      }
    }
  }
}

.checkedClass {
  background-color: #dce7ff;
}
::v-deep .diastyle {
  box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.3);
  border-radius: 8px;
  .form {
    flex: 1.2;
    .el-form {
      .el-form-item {
        .el-form-item__label {
          padding: 0 5px 0 20px !important;
          font-size: 14px !important;
          word-break: break-word;
          height: auto !important;
        }
        .el-form-item__content {
          .el-select {
            width: 100%;
          }
          .el-autocomplete {
            width: 100%;
          }
        }
        ::v-deep .el-input__inner {
          height: 28px !important;
        }
      }
    }
  }

  .checkbox_style {
    margin-left: 50px;
    p {
      font-size: 14px;
      line-height: 47px !important;
      height: 63px;
      margin-bottom: 0;
      .el-checkbox {
        width: 90% !important;
        .el-checkbox__label {
          font-size: 14px !important;
          float: left !important;
          padding-left: 0px !important;
        }
        .el-checkbox__input {
          float: right !important;
          margin-right: 30px;
          margin-top: 4px;
        }
        .el-checkbox__input.is-checked .el-checkbox__inner,
        .el-checkbox__input.is-indeterminate .el-checkbox__inner {
          background: #005fb2;
        }
      }
      .checkbox {
        .el-checkbox__label {
          font-size: 14px !important;
          padding-left: 0px !important;
          text-align: left !important;
        }
        .el-checkbox__input {
          float: left !important;
        }
      }
      .checkboxs {
        .el-checkbox {
          width: 90% !important;
        }
      }
    }
    .two_p {
      label {
        display: flex;
        .el-checkbox__input {
          margin: 0 10px;
          line-height: 0px;
        }
      }
      .el-checkbox__label {
        line-height: 33px;
        margin-top: -8px;
      }
    }
  }
}
.allstars {
  color: red;
}

::v-deep .el-checkbox__input.is-disabled.is-checked .el-checkbox__inner {
  background-color: #006dcc !important;
  border-color: #006dcc !important;
}

.standardprice::v-deep .el-input__suffix {
  position: absolute;
  top: -7px;
}

::v-deep .add_diastyle {
  .product_code {
    position: relative;
    .tit_label {
      width: 100px;
      display: inline-block;
    }
    .checkbox {
      position: absolute;
      top: 5px;
      left: 101px;
      .el-checkbox {
        width: 100px !important;
        display: flex;
        .el-checkbox__input {
          margin-right: 10px !important;
        }
      }
    }
  }
}

::v-deep .height_s {
  .el-input__inner {
    height: 28px !important;
    font-size: 12px;
  }
}
</style>
<style>
.suspension_popover {
  width: 99% !important;
}
</style>
