<template>
  <!-- 关闭个案 -->
  <div class="closecase-box" :style="{ width: barWidth, bottom: barBottom }">
    <div class="close-case" @click="caseBtn" v-if="!isSend">
      <span v-show="closeBool">
        {{ $t("label.close") + " " + caseName }}
      </span>
      <span v-show="!closeBool">
        {{ $t("front-console-reopen") }}
      </span>
    </div>
    <div ref="forward" class="forward" v-if="!isSend">
      <div
        class="forward-icons back-blue"
        @click="isShowForwardicons = !isShowForwardicons"
      >
        <span>
          <svg
            aria-hidden="true"
            class="icon"
            style="width: 100%; height: 100%"
          >
            <use href="#icon-console_button_reply"></use>
          </svg>
        </span>
      </div>
      <div
        :class="showReply ? 'forward-content' : 'forward-content-two'"
        v-if="isShowForwardicons"
      >
        <el-tooltip
          class="item"
          effect="dark"
          :content="$t('front-console-conversation-btn1')"
          placement="left"
          v-show="showReply"
        >
          <div
            class="forward-icons"
            :class="forwardIconsData[0].isEnter ? 'bg-blue' : ''"
            @mouseover="enter(0)"
            @mouseout="outer(0)"
            @click="handleClick('callBack')"
          >
            <span>
              <svg
                aria-hidden="true"
                class="icon"
                style="width: 100%; height: 100%"
              >
                <use
                  :href="
                    !forwardIconsData[0].isEnter
                      ? forwardIconsData[0].defIcon
                      : forwardIconsData[0].selectIcon
                  "
                ></use>
              </svg>
            </span>
          </div>
        </el-tooltip>

        <el-tooltip
          class="item"
          effect="dark"
          :content="$t('front-console-conversation-btn2')"
          placement="left"
        >
          <div
            class="forward-icons"
            :class="forwardIconsData[1].isEnter ? 'bg-blue' : ''"
            @mouseover="enter(1)"
            @mouseout="outer(1)"
            @click="handleClick('replyMail')"
          >
            <span>
              <svg
                aria-hidden="true"
                class="icon"
                style="width: 100%; height: 100%"
              >
                <use
                  :href="
                    !forwardIconsData[1].isEnter
                      ? forwardIconsData[1].defIcon
                      : forwardIconsData[1].selectIcon
                  "
                ></use>
              </svg>
            </span>
          </div>
        </el-tooltip>

        <el-tooltip
          class="item"
          effect="dark"
          :content="$t('front-console-conversation-btn3')"
          placement="left"
        >
          <div
            class="forward-icons"
            :class="forwardIconsData[2].isEnter ? 'bg-blue' : ''"
            @mouseover="enter(2)"
            @mouseout="outer(2)"
            @click="handleClick('forwardMail')"
          >
            <span>
              <svg
                aria-hidden="true"
                class="icon"
                style="width: 100%; height: 100%"
              >
                <use
                  :href="
                    !forwardIconsData[2].isEnter
                      ? forwardIconsData[2].defIcon
                      : forwardIconsData[2].selectIcon
                  "
                ></use>
              </svg>
            </span>
          </div>
        </el-tooltip>
        <!-- <div
          v-for="item in forwardIconsData"
          :key="item.index"
          class="forward-icons"
          :class="item.isEnter ? 'bg-blue' : ''"
          @mouseover="enter(item.index)"
          @mouseout="outer(item.index)"
          @click="handleClick(item.type)"
        >
          <el-tooltip
            class="item"
            effect="dark"
            content="Top Left 提示文字"
            placement="left"
          >
            <span>
              <svg
                aria-hidden="true"
                class="icon"
                style="width: 100%; height: 100%"
              >
                <use
                  :href="!item.isEnter ? item.defIcon : item.selectIcon"
                ></use>
              </svg>
            </span>
          </el-tooltip>
        </div> -->
      </div>
    </div>
    <div class="mail-box" v-if="isSend">
      <div class="sendButton" @click="sendEmail('1')">
        {{ $t("label.send") }}
      </div>
      <el-dropdown trigger="click" @visible-change="selectFunction">
        <span class="el-dropdown-link">
          <div class="sendSelect" v-if="!selectIconStatus">
            <svg aria-hidden="true" class="icon">
              <use href="#icon-console_chat_open"></use>
            </svg>
          </div>
          <div class="sendSelect" v-if="selectIconStatus">
            <svg aria-hidden="true" class="icon">
              <use href="#icon-console_chat_putAway"></use>
            </svg>
          </div>
        </span>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item @click.native="sendEmail('1')">
            {{ $t("label.send") }}
          </el-dropdown-item>
          <el-dropdown-item @click.native="sendEmail('2')">
            {{ $t("label.emailobject.emailsend.sendindividually") }}
          </el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
      <div class="canle" @click="CancelSending">
        {{ $t("label.cancel") }}
      </div>
    </div>
  </div>
</template>

<script>
import * as CommonObjApi from "../api";

export default {
  props: {
    cId: {
      type: String,
      default: "",
    },
    isSend: {
      type: Boolean,
      default: false,
    },
    // contactEmail: {
    //   type: String,
    //   default: "",
    // },
  },
  data() {
    return {
      barWidth: "calc(100% - 660.3px)",
      barBottom: localStorage.getItem("footerToolBottom"),
      closeBool: true, // 关闭按钮显示
      isShowForwardicons: false, // 是否显示转发的图标
      selectIconStatus: false,
      caseStatus: "",
      forwardIconsData: [
        {
          index: 0,
          isEnter: false,
          defIcon: "#icon-full_callBack_cli",
          selectIcon: "#icon-button_callBack_def",
          type: "callBack",
        },
        {
          index: 1,
          isEnter: false,
          defIcon: "#icon-button_replyMail_cli",
          selectIcon: "#icon-button_replyMail_def",
          type: "replyMail",
        },
        {
          index: 2,
          isEnter: false,
          defIcon: "#icon-button_forwardMail_cli",
          selectIcon: "#icon-button_forwardMail_def",
          type: "forwardMail",
        },
      ], // 转发icon的集合
      viewId: this.$route.query.viewId,
      caseName: "",
      showReply: true,
      // countryCode: $cookies.get("countryCode"),
      countryCodeCall:
        this.$store.state.userInfoObj.language == "zh" ? "CN" : "",
    };
  },
  watch: {
    cId: function () {
      this.init();
      this.judgeShow();
    },
  },
  mounted() {
    // 接受状态
    this.$bus.$on("serviceCaseStatusIn", this.serviceCaseStatusInFn);
    this.$bus.$on("changeCloseBae", this.changeCloseBaeFn);
    document.addEventListener("click", this.closeFn);
  },
  beforeDestroy() {
    this.$bus.$off("serviceCaseStatusIn", this.serviceCaseStatusInFn);
    this.$bus.$off("changeCloseBae", this.changeCloseBaeFn);
    document.removeEventListener("click", this.closeFn);
  },
  methods: {
    serviceCaseStatusInFn(newVal) {
      this.getStatus(newVal);
    },
    changeCloseBaeFn(width) {
      this.barWidth = width;
    },
    closeFn(e) {
      let box = this.$refs.forward;
      // 重点来了：Box里是否包含点击的元素，不包含点击的元素就隐藏面板
      if (box) {
        if (!box.contains(e.target)) {
          if (this.isShowForwardicons) {
            this.isShowForwardicons = false;
          }
        }
      }
    },
    // cId 监听器中使用
    init() {
      CommonObjApi.getPermissionById({ id: this.cId }).then((res) => {
        if (res.result) {
          CommonObjApi.getViewListRecently({
            obj: res.data.prefix,
            viewId: this.viewId,
          }).then((resResult) => {
            this.caseName = resResult.data.label;
          });
        }
      });
    },

    /**
     * 判断显示:cId监听器中使用
     */
    judgeShow() {
      let paramsInfo = {};
      if (this.countryCodeCall == "CN") {
        this.$set(paramsInfo, "intergrateType", "tianrun");
      } else {
        this.$set(paramsInfo, "intergrateType", "aws");
      }
      // 获取呼叫中心设置
      CommonObjApi.getCallCenterInfo(paramsInfo).then((resInfo) => {
        if (resInfo.result && resInfo.data) {
          if (resInfo.data.isEnable == "0") {
            this.showReply = false;
          } else if (resInfo.data.isEnable == "1") {
            this.showReply = true;
          }
        }
      });
    },
    //取消
    CancelSending() {
      this.$emit("close", false);
    },
    //发送邮件
    sendEmail(params) {
      this.$Bus.$emit("send-Email", params);
    },
    // 鼠标移入
    enter(index) {
      this.forwardIconsData[index].isEnter = true;
    },
    // 鼠标移出
    outer(index) {
      this.forwardIconsData[index].isEnter = false;
    },
    // 转发icon的点击事件
    handleClick(type) {
      this.$bus.$emit("servephoneCallback", type);
    },
    selectFunction(item) {
      if (item) {
        this.selectIconStatus = true;
      } else {
        this.selectIconStatus = false;
      }
    },

    // 获取当前状态
    getStatus(val) {
      if (
        val == this.$i18n.t("label.tabpage.closez") ||
        val == this.$i18n.t("front-kefu-moudle-return-visit")
      ) {
        this.closeBool = false;
        this.caseStatus = this.$i18n.t("label.import.page1.newcreate");
      } else {
        this.closeBool = true;
        this.caseStatus = this.$i18n.t("label.tabpage.closez");
      }
    },

    // 点击个案状态按钮
    caseBtn() {
      CommonObjApi.getPermissionById({ id: this.cId }).then((res) => {
        if (res.data.isEdit) {
          this.$bus.$emit("serviceCaseStatusOut", this.caseStatus);
        } else {
          this.$message.warning(this.$i18n.t("label.weixin.donot.permission"));
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.closecase-box {
  width: calc(100% - 660px);
  height: 44px;
  background: #ffffff;
  box-shadow: 0px -2px 4px 0px rgba(0, 0, 0, 0.11);
  position: fixed;
  right: 0;
  bottom: 44px;
  z-index: 8;
  display: flex;
  justify-content: flex-end;
  .close-case {
    width: 104px;
    height: 44px;
    background: #ebf1ff;
    color: #2d6cfc;
    line-height: 44px;
    text-align: center;
    cursor: pointer;
  }
  .forward {
    margin: 0 32px 0 12px;
    .forward-icons {
      width: 44px;
      height: 44px;
      cursor: pointer;
      > span {
        display: inline-block;
        width: 22px;
        height: 20px;
        position: relative;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }
    .bg-blue {
      background: #2d6cfc;
    }
    .forward-content {
      width: 44px;
      height: 132px;
      background: #ffffff;
      box-shadow: 0px 0px 8px 0px rgba(45, 108, 252, 0.45);
      border-radius: 22px;
      border: 1px solid #2d6cfc;
      position: relative;
      bottom: 190px;
      overflow: hidden;
    }
    .forward-content-two {
      width: 44px;
      background: #ffffff;
      box-shadow: 0px 0px 8px 0px rgba(45, 108, 252, 0.45);
      border-radius: 22px;
      border: 1px solid #2d6cfc;
      position: relative;
      bottom: 140px;
      overflow: hidden;
    }
    .back-blue {
      background: #2d6cfc;
      box-shadow: 0px 0px 4px 0px #2d6cfc;
      border-radius: 50%;
    }
  }
  .mail-box {
    display: flex;
    align-items: center;
    margin-right: 100px;
    .sendButton {
      width: 82px;
      line-height: 44px;
      background-color: #ebf1ff;
      color: #2d6cfc;
      text-align: center;
      cursor: pointer;
    }
    .sendSelect {
      width: 20px;
      height: 44px;
      line-height: 44px;
      background-color: #ebf1ff;
      cursor: pointer;
    }
    .canle {
      margin-left: 20px;
      cursor: pointer;
    }
  }
}
</style>